import { match } from 'ts-pattern';

import { ModeratedModerationFields } from 'types/Moderation';
import { TokenCategory } from 'types/Token';

import { CollectionModeration } from './CollectionModeration';
import { TokenModeration } from './TokenModeration';

export function CollectionModerationBlock(props: ModeratedModerationFields) {
  return match(props)
    .with({ moderationStatus: 'SUSPENDED' }, () => (
      <CollectionModeration.Suspended />
    ))
    .with({ moderationStatus: 'TAKEDOWN_REQUESTED' }, () => (
      <CollectionModeration.TakedownRequested
        moderationFrom={props.moderationFrom}
      />
    ))
    .with({ moderationStatus: 'UNDER_REVIEW' }, () => (
      <CollectionModeration.UnderReview />
    ))
    .exhaustive();
}

export function TokenModerationBlock(
  props: ModeratedModerationFields & { category: TokenCategory }
) {
  return match(props)
    .with({ moderationStatus: 'SUSPENDED' }, () => (
      <TokenModeration.Suspended category={props.category} />
    ))
    .with({ moderationStatus: 'TAKEDOWN_REQUESTED' }, () => (
      <TokenModeration.TakedownRequested
        category={props.category}
        moderationFrom={props.moderationFrom}
      />
    ))
    .with({ moderationStatus: 'UNDER_REVIEW' }, () => (
      <TokenModeration.UnderReview category={props.category} />
    ))
    .exhaustive();
}
