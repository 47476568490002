import { ExternalIcon } from '@f8n/icons';
import { styled } from '@f8n-frontend/stitches';
import NextLink from 'next/link';

import { provenanceDateFormat } from 'utils/dates/dates';
import { formatETHWithSuffix } from 'utils/formatters';
import { getPath } from 'utils/router';

import { UserLight } from 'types/Account';
import { ProvenanceEvent } from 'types/Provenance';

import Pin from './Pin';
import Box from './base/Box';
import Link from './base/Link';
import Text from './base/Text';
import UserAvatar from './base/UserAvatar';
import ProfileHoverCard from './profiles/ProfileHoverCard';
import UserTag from './users/UserTag';

type BaseProvenanceEventProps = {
  user: ProvenanceEvent['user'];
  txHash: ProvenanceEvent['txHash'];
  txLink: string;
  createdAt: ProvenanceEvent['createdAt'];
  type: ProvenanceEvent['type'];
};

const actionTextMap: Record<ProvenanceEvent['type'], string> = {
  ADDED_TO_WORLD: 'Added to world',

  AUCTION_LISTED: 'Reserve set by',
  AUCTION_LISTED_IN_WORLD: 'Reserve set by',
  AUCTION_UNLISTED: 'Reserve removed by',
  AUCTION_BID_PLACED: 'Bid placed by',
  AUCTION_CHANGED: 'Reserve changed by',
  AUCTION_ENDED: 'Auction won by',
  AUCTION_FINALIZED: 'Auction won by',

  TRANSFER: 'Transferred by',

  MINT_BY_CREATOR: 'Minted by',
  MINT_BY_COLLECTOR: 'Minted by',

  BUY_NOW_ACCEPTED: 'Bought by',
  BUY_NOW_CANCELED: 'Buy now removed by',
  BUY_NOW_CHANGED: 'Buy now changed by',
  BUY_NOW_SET: 'Buy now set by',

  OFFER_ACCEPTED: 'Offer accepted by',
  OFFER_CHANGED: 'Offer changed by',
  OFFER_MADE: 'Offer made by',
  OFFER_OUTBID: 'Offer outbid by',

  PRIVATE_SALE: 'Sold by',
};

function TransferEvent(
  props: BaseProvenanceEventProps &
    Extract<ProvenanceEvent, { type: 'TRANSFER' }>
) {
  return (
    <ProvenanceRow>
      <Box
        css={{
          width: '$avatar3',
          height: '$avatar3',
          position: 'relative',
          flexShrink: 0,
        }}
      >
        <Pin.TopLeft>
          <EventUser user={props.fromUser} size={1} variant="white" />
        </Pin.TopLeft>
        <Pin.BottomRight>
          <EventUser user={props.toUser} size={1} variant="white" />
        </Pin.BottomRight>
      </Box>

      <Text ellipsis>
        <ProvenanceAction>
          Transferred from
          <span>&nbsp;</span>
          <UserTag
            nameVariant="prefer-display-name"
            size={1}
            type="text"
            user={props.fromUser}
            weight="medium"
            color="strong"
          />
          <span>&nbsp;</span>
          to
          <span>&nbsp;</span>
          <UserTag
            nameVariant="prefer-display-name"
            size={1}
            type="text"
            user={props.toUser}
            weight="medium"
            color="strong"
          />
        </ProvenanceAction>
        <ProvenanceBlockExplorerLink {...props} href={props.txLink} />
      </Text>
    </ProvenanceRow>
  );
}

function SettledEvent(
  props: BaseProvenanceEventProps &
    Extract<ProvenanceEvent, { type: 'AUCTION_FINALIZED' }>
) {
  return (
    <ProvenanceRow>
      <EventUser user={props.user} size={3} variant="base" />

      <Text ellipsis>
        <ProvenanceAction>
          {actionTextMap[props.type]}
          <span>&nbsp;</span>
          <UserTag
            nameVariant="prefer-display-name"
            size={1}
            type="text"
            user={props.user}
            weight="medium"
            color="strong"
          />
        </ProvenanceAction>

        <Text size={0} color="dim" css={{ paddingBottom: '2px' }}>
          <Box css={{ display: 'flex' }}>
            Settled by<span>&nbsp;</span>
            <UserTag
              nameVariant="prefer-display-name"
              size={0}
              type="text"
              user={props.settledBy}
              weight="medium"
              color="strong"
            />
          </Box>
        </Text>
        <ProvenanceBlockExplorerLink {...props} href={props.txLink} />
      </Text>
      <Text
        size={1}
        color="strong"
        weight="medium"
        css={{ marginLeft: 'auto', whiteSpace: 'nowrap' }}
      >
        {formatETHWithSuffix(props.amountInEth)}
      </Text>
    </ProvenanceRow>
  );
}

function GenericEventWithValue(
  props: BaseProvenanceEventProps & { amountInEth: number }
) {
  return (
    <ProvenanceRow>
      <EventUser user={props.user} size={3} variant="base" />

      <Text ellipsis>
        <ProvenanceAction>
          {actionTextMap[props.type]}
          <span>&nbsp;</span>
          <UserTag
            nameVariant="prefer-display-name"
            size={1}
            type="text"
            user={props.user}
            weight="medium"
            color="strong"
          />
        </ProvenanceAction>
        <ProvenanceBlockExplorerLink {...props} href={props.txLink} />
      </Text>

      <Text
        size={1}
        color="strong"
        weight="medium"
        css={{ marginLeft: 'auto', whiteSpace: 'nowrap' }}
      >
        {formatETHWithSuffix(props.amountInEth)}
      </Text>
    </ProvenanceRow>
  );
}

function GenericEvent(props: BaseProvenanceEventProps) {
  return (
    <ProvenanceRow>
      <EventUser user={props.user} size={3} variant="base" />

      <Text ellipsis>
        <ProvenanceAction>
          {actionTextMap[props.type]}
          <span>&nbsp;</span>
          <UserTag
            nameVariant="prefer-display-name"
            size={1}
            type="text"
            user={props.user}
            weight="medium"
            color="strong"
          />
        </ProvenanceAction>
        <ProvenanceBlockExplorerLink {...props} href={props.txLink} />
      </Text>
    </ProvenanceRow>
  );
}

function EventUser(props: {
  user: UserLight;
  size: 1 | 2 | 3;
  variant: 'base' | 'white';
}) {
  const { user } = props;
  return (
    <ProfileHoverCard publicKey={user.publicKey}>
      <NextLink
        href={getPath.profile.page(user.username || user.publicKey)}
        passHref
        prefetch={false}
      >
        <UserAvatar
          imageUrl={user.profileImageUrl}
          size={props.size}
          variant={props.variant}
        />
      </NextLink>
    </ProfileHoverCard>
  );
}

function ProvenanceBlockExplorerLink(
  props: Pick<ProvenanceEvent, 'createdAt' | 'txHash'> & { href: string }
) {
  return (
    <Link
      size={0}
      variant="primary"
      css={{
        display: 'flex',
        alignItems: 'center',
      }}
      href={props.href}
      target="_blank"
      rel="noreferrer"
    >
      {provenanceDateFormat(props.createdAt)}
      <span>&nbsp;</span>
      <ExternalIcon size={0} />
    </Link>
  );
}

const ProvenanceAction = styled(Text, {
  display: 'flex',
});

ProvenanceAction.defaultProps = {
  size: 1,
  weight: 'regular',
  color: 'dim',
};

const ProvenanceRow = styled('div', {
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  borderBottom: '1px solid $black5',
  paddingY: '$4',
  gap: '$3',
});

export const ActivityTable = {
  GenericEvent,
  GenericEventWithValue,
  SettledEvent,
  TransferEvent,
};
