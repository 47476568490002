import { clamp } from 'ramda';

import { AuctionMarket, Market } from 'utils/markets/markets';
import { areKeysEqual } from 'utils/users';

import { Bid } from 'types/Nft';

interface ActorInMarketOptions {
  market: Market | undefined;
  currentUserPublicKey: string | null | undefined;
  artworkOwnerPublicKey: string | null | undefined;
}

export const getAuctionStatus = (endsAtUnix: number) => {
  if (endsAtUnix < Date.now() / 1000) {
    return 'ENDED';
  } else {
    return 'LIVE';
  }
};

export function isActorInMarket(options: ActorInMarketOptions) {
  const { artworkOwnerPublicKey, currentUserPublicKey, market } = options;

  const marketActors = market ? market?.marketActors : [];

  const actors = [...marketActors, artworkOwnerPublicKey];

  return actors.includes(currentUserPublicKey);
}

interface IsWinningAuctionOptions {
  market: AuctionMarket | undefined;
  currentUserPublicKey: string | null | undefined;
}

export function isWinningAuction(options: IsWinningAuctionOptions) {
  const { currentUserPublicKey, market } = options;

  if (!market) return false;

  const isInMarket = isActorInMarket({
    artworkOwnerPublicKey: null,
    currentUserPublicKey,
    market,
  });

  if (!isInMarket) return false;

  return areKeysEqual([market.buyer, currentUserPublicKey]);
}

type BidTableExpandableSectionPreview = {
  hiddenItemsCount: number;
  bidderPreview: Bid[];
};

type BidsTableBreakdown = {
  expandableSection: BidTableExpandableSectionPreview | null;
  firstBid: Bid;
  latestBids: Bid[];
};

/**
 * Controls the maximum number of rows that can be
 */
const BIDDER_TABLE_MAX_ROWS = 6;

/**
 * Number of bidders that appear below the collapsible section.
 * This space is reserved for the first bid in the auction.
 */
const MAX_BIDDERS_AFTER_EXPANDABLE_SECTION_COUNT = 1;

/**
 * Controls max number of latest bidders, when there is no collapsible section
 */
const MAX_BIDDERS_BEFORE_EXPANDABLE_SECTION_COUNT = 4;

/**
 * Controls max number of latest bidders, when there is no collapsible section
 */
const MAX_LATEST_BIDDERS_ITEMS_WITHOUT_COLLAPSIBLE_SECTION = 5;

/**
 * Controls maximum number of avatars that can be shown in expandable row
 */
const MAX_AVATARS_IN_EXPANDABLE_SECTION = 3;

export function createBidsTableBreakdown(options: {
  firstBid: Bid;
  bids: Bid[];
  totalBidsCount: number;
}): BidsTableBreakdown {
  const { bids, firstBid, totalBidsCount } = options;

  const collapsedItemsCount = deriveCollapsedItemsCount({ totalBidsCount });
  const isCollapsible = collapsedItemsCount > 0;

  const latestBidsEndIndex = deriveLatestBidsEndIndex({ bids, isCollapsible });

  const latestBids = bids.slice(0, latestBidsEndIndex);

  const expandableSection = isCollapsible
    ? getBidTableExpandableSection({
        bids,
        collapsedItemsCount,
        startIndex: latestBidsEndIndex,
      })
    : null;

  return {
    expandableSection,
    firstBid,
    latestBids,
  };
}

function deriveLatestBidsEndIndex(options: {
  bids: Bid[];
  isCollapsible: boolean;
}): number {
  const { bids, isCollapsible } = options;

  if (isCollapsible) {
    return MAX_BIDDERS_BEFORE_EXPANDABLE_SECTION_COUNT;
  }

  return Math.min(
    bids.length - 1,
    MAX_LATEST_BIDDERS_ITEMS_WITHOUT_COLLAPSIBLE_SECTION
  );
}

function deriveCollapsedItemsCount(options: { totalBidsCount: number }) {
  const { totalBidsCount } = options;

  return clamp(
    0,
    Infinity,
    totalBidsCount <= BIDDER_TABLE_MAX_ROWS
      ? 0
      : totalBidsCount -
          MAX_BIDDERS_BEFORE_EXPANDABLE_SECTION_COUNT -
          MAX_BIDDERS_AFTER_EXPANDABLE_SECTION_COUNT
  );
}

function getBidTableExpandableSection(options: {
  bids: Bid[];
  collapsedItemsCount: number;
  startIndex: number;
}): BidTableExpandableSectionPreview {
  const { bids, collapsedItemsCount, startIndex } = options;

  return {
    bidderPreview: bids.slice(
      startIndex,
      collapsedItemsCount >= MAX_AVATARS_IN_EXPANDABLE_SECTION
        ? startIndex + 3
        : startIndex + 2
    ),
    hiddenItemsCount: collapsedItemsCount,
  };
}
