import { styled } from '@f8n-frontend/stitches';
import { motion } from 'framer-motion';

import Box from 'components/base/Box';
import Flex from 'components/base/Flex';
import Grid from 'components/base/Grid';
import Text from 'components/base/Text';
import UserTag from 'components/users/UserTag';

import { ApiSplitFragment } from 'gql/api/api-fragments.generated';

const ANIMATION_DURATION = 0.15;

type SplitsBreakdownRowProps = {
  split: ApiSplitFragment;
  index: number;
};

export default function SplitsBreakdownRow(props: SplitsBreakdownRowProps) {
  const { split, index } = props;

  const sharePercentage = `${split.sharePercent}%`;

  return (
    <Grid css={{ gap: '$6', '@bp1': { gap: '$7' } }}>
      <Flex css={{ justifyContent: 'space-between', alignItems: 'center' }}>
        <UserTag nameVariant="prefer-username" user={split.user} size={1} />
        <Text size={4} weight="semibold">
          {sharePercentage}
        </Text>
      </Flex>
      <ProgressBarContainer>
        <ProgressBar
          as={motion.div}
          initial={{ width: 0 }}
          animate={{ width: sharePercentage }}
          transition={{
            delay: (index + 1) * ANIMATION_DURATION + 0.4,
            duration: 0.6,
            type: 'spring',
          }}
        />
      </ProgressBarContainer>
    </Grid>
  );
}

const ProgressBar = styled(Box, {
  height: 4,
  background: '$black100',
  borderRadius: '$round',
});

const ProgressBarContainer = styled(Box, {
  height: 4,
  display: 'grid',
  gridTemplateRows: '1fr',
  backgroundColor: '$black10',
  borderRadius: '$round',
});
