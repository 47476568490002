import { NFTMarket } from '@f8n/f8n-contracts/dist/abis/NFTMarket.generated';
import { useQuery } from '@tanstack/react-query';
import { getPublicClient } from '@wagmi/core';
import { parseEventLogs } from 'viem';

import { ApiBidFragment } from 'gql/api/api-fragments.generated';
import { getContractAddress } from 'lib/addresses';
import { ChainId } from 'lib/chains';
import { wagmiConfig } from 'lib/wagmi';
import { ethToNumber } from 'utils/bigint';

async function getAuctionPlacedBids(variables: {
  chainId: ChainId;
  auctionId: bigint;
}): Promise<ApiBidFragment[]> {
  const chainId = variables.chainId;
  const auctionId = variables.auctionId;

  const publicClient = getPublicClient(wagmiConfig, { chainId });

  if (!publicClient) {
    throw new Error('Public client not found');
  }

  const filter = await publicClient.createContractEventFilter({
    abi: NFTMarket,
    address: getContractAddress({
      chainId,
      contractName: 'nftMarket',
    }),
    eventName: 'ReserveAuctionBidPlaced',
    fromBlock: 'earliest',
    toBlock: 'latest',
    args: {
      auctionId,
    },
  });

  const parsedEventLogs = parseEventLogs({
    abi: filter.abi,
    eventName: filter.eventName,
    logs: await publicClient.getFilterLogs({ filter }),
  });

  const reversedLogs = [...parsedEventLogs].reverse();

  return reversedLogs.map((log) => ({
    amount: ethToNumber(log.args.amount),
    bidder: {
      publicKey: log.args.bidder,
      profileImageUrl: null,
      username: null,
      name: null,
    },
  }));
}

export function useAuctionPlacedBids(
  variables: {
    chainId: ChainId;
    auctionId: bigint;
    currentBidAmount: number;
    minimumBidAmount: number;
  },
  options: {
    enabled: boolean;
  }
) {
  return useQuery({
    queryKey: ['auction', 'bids', variables],
    queryFn: async () => {
      return getAuctionPlacedBids({
        chainId: variables.chainId,
        auctionId: BigInt(variables.auctionId),
      });
    },
    enabled: options.enabled ?? true,
  });
}
