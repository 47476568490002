import { styled } from '@f8n-frontend/stitches';

import Text from './base/Text';

export const EthLabel = styled('span', Text, {});
EthLabel.defaultProps = {
  children: 'ETH',
  color: 'dim',
  size: 0,
  weight: 'semibold',
};
