export const MARKET_WIDGET_CTA = {
  ACCEPT_OFFER: 'Accept offer',
  BID: 'Place bid',
  BUY: 'Buy now',
  EDIT_LISTING: 'Edit listing',
  INCREASE_BID: 'Increase your bid',
  OFFER: 'Make offer',
  REMOVE_LISTING: 'Remove listing',
  SELL: 'Sell',
  SETTLE_AUCTION: 'Settle auction',
  START_AUCTION: 'Place starting bid',
  AUCTION_WON_BY: 'Auction won by',
} as const;

export const MARKET_WIDGET_LOADING_MESSAGE = {
  BIDDING: 'Placing bid',
} as const;

export const MARKET_WIDGET_LABEL = {
  ACTIVE_OFFER: 'Active offer',
  ALL_BIDS: 'All bids',
  CURRENT_BID: 'Current bid',
  ENDS_IN: 'Ends in',
  LAST_SOLD: 'Last sold',
  PRICE: 'Price',
  RESERVE: 'Auction starting bid',
} as const;

export const MARKET_WIDGET_BIDDER_MESSAGE = {
  YOU_ARE_WINNING: 'You’re winning',
  YOU_LOST_LEAD: 'You’ve been outbid',
  NEW_HIGHEST_BID: 'New highest bid',
} as const;

/** The name of the 1155 token to be used as currency. Temporarily hard-coded. */
const TOKEN_CURRENCY_NAME = 'Currency';

export const SECONDARY_MARKET_WIDGET = {
  HEADING: 'Edition auction',
  DESCRIPTION: `You must bid on this auction using editions of ${TOKEN_CURRENCY_NAME}. All non-winning bidders will have their editions returned.`,
  EXTENSIONS_INFO: 'Extensions enabled for bids in the last 5 mins',
  BUY_CURRENCY: `Buy ${TOKEN_CURRENCY_NAME}`,
} as const;
