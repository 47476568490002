import { formatEther } from 'viem';
import { useWriteContract, useSimulateContract } from 'wagmi';

import SmallArtworkCard from 'components/SmallArtworkCard';
import TransactionModal from 'components/TransactionModal';
import InputEth from 'components/base/InputEth';
import Modal from 'components/base/Modal';
import AmountBreakDown from 'components/transactions/generic/AmountBreakdown';
import { hasPublicKey } from 'contexts/auth/helpers';
import useAuth from 'contexts/auth/useAuth';
import useTransactionStore from 'state/stores/transactions';

import useWorldById from 'hooks/queries/api/use-world-by-id';
import useWorldIdByNft from 'hooks/queries/api/use-world-id-by-nft';
import useModalVisibility from 'hooks/use-modal-visibility';
import { useExhibitionIdForNft } from 'hooks/web3/use-exhibition-id-for-nft';
import { useGetOffer } from 'hooks/web3/use-get-offer';
import { NFTMarket } from 'lib/abis/NFTMarket';
import { getContractAddress } from 'lib/addresses';
import { ZERO_ADDRESS } from 'lib/constants';
import { getTokenFilter } from 'utils/inputs';
import { extractPrepareContractWriteRevertReason } from 'utils/revert-reasons';

import { ModalOptions } from 'types/modal';

const MODAL_KEY = 'ACCEPT_OFFER';
const ACTION = 'accept-offer';

type AcceptOfferModalOptions = ModalOptions<typeof MODAL_KEY>;

export default function AcceptOfferModal() {
  const modal = useModalVisibility(MODAL_KEY);

  return (
    <Modal.Root open={modal.open} onOpenChange={modal.onOpenChange}>
      <Modal.Portal>
        <Modal.BlurOverlay />
        <Modal.PositionOverlay>
          <Modal.UnmountListener onUnmount={modal.onUnmount} />
          {modal.config && <AcceptOfferModalWindow {...modal.config} />}
        </Modal.PositionOverlay>
      </Modal.Portal>
    </Modal.Root>
  );
}

function AcceptOfferModalWindow(props: AcceptOfferModalOptions) {
  const { nft } = props;
  const { chainId } = nft;

  const auth = useAuth();

  const nftFilter = getTokenFilter(nft);

  const getOfferQuery = useGetOffer(nftFilter);

  const txStore = useTransactionStore();

  const simulateAcceptOffer = useSimulateContract({
    abi: NFTMarket,
    functionName: 'acceptOffer',
    address: getContractAddress({
      chainId,
      contractName: 'nftMarket',
    }),
    chainId,
    query: {
      enabled: getOfferQuery.isSuccess,
      retry: false,
    },
    args: getOfferQuery.isSuccess
      ? [
          nft.contractAddress,
          BigInt(nft.tokenId),
          getOfferQuery.data.buyer,
          getOfferQuery.data.amount,
        ]
      : undefined,
  });

  const exhibitionByNft = useExhibitionIdForNft(
    {
      nftFilter,
      sellerPublicKey: hasPublicKey(auth) ? auth.publicKey : ZERO_ADDRESS,
    },
    { enabled: hasPublicKey(auth) }
  );

  const contractWrite = useWriteContract({
    mutation: {
      onSuccess: (txHash) => {
        txStore.startTracking({
          ui: 'toast',
          action: {
            name: ACTION,
            worldId: exhibitionByNft.data
              ? Number(exhibitionByNft.data.worldId)
              : null,
          },
          chainId,
          txHash,
          title: {
            PENDING: 'Accepting the Offer…',
            SUCCESS: 'You sold it!',
          },
          description: {
            PENDING:
              'The Offer is being accepted on the blockchain. You can close this if you like.',
            SUCCESS:
              'The NFT has been sold and transferred, and the funds for the sale have been sent to your wallet.',
          },
        });
      },
    },
  });

  const worldIdQuery = useWorldIdByNft({
    tokenId: nftFilter.tokenId,
    contractAddress: nftFilter.contractAddress,
  });

  const worldByIdQuery = useWorldById(
    { id: worldIdQuery.data ? worldIdQuery.data : 0 },
    { enabled: Boolean(worldIdQuery.data) }
  );

  return (
    <TransactionModal
      chainId={chainId}
      preSignPrompt={{
        type: 'skip',
      }}
    >
      <TransactionModal.Content
        action={ACTION}
        chainId={chainId}
        txHash={contractWrite.data || null}
        footer={
          <Modal.Footer>
            <TransactionModal.TransactionButtonWithContractApproval
              chainId={chainId}
              onApprovalSuccess={simulateAcceptOffer.refetch}
              contractAddress={nft.contractAddress}
              isLoading={contractWrite.isPending || Boolean(contractWrite.data)}
              isDisabled={!simulateAcceptOffer.isSuccess}
              write={() => {
                if (simulateAcceptOffer.isSuccess) {
                  contractWrite.writeContract(simulateAcceptOffer.data.request);
                }
              }}
              error={extractPrepareContractWriteRevertReason(
                simulateAcceptOffer
              )}
              label="Accept Offer"
            />
          </Modal.Footer>
        }
      >
        <TransactionModal.Body>
          <Modal.BodyTitle
            align="left"
            title="Accept Offer"
            description="Once the Offer is accepted, you will receive ETH and the NFT will be sent to the buyer. If you do not want to accept the Offer, it will expire after 24 hours."
          />
          <SmallArtworkCard.MarketNft nft={nft} />
          <TransactionModal.InputWrapper>
            <InputEth
              id="price"
              value={
                getOfferQuery.isSuccess
                  ? formatEther(getOfferQuery.data.amount)
                  : ''
              }
              disabled
            />
            {getOfferQuery.isSuccess ? (
              <AmountBreakDown.FeesConnected
                chainId={nftFilter.chainId}
                currentUserPublicKey={
                  hasPublicKey(auth) ? auth.publicKey : null
                }
                contractAddress={nftFilter.contractAddress}
                tokenId={nftFilter.tokenId}
                price={Number(formatEther(getOfferQuery.data.amount))}
                worldId={
                  worldByIdQuery.isSuccess ? worldByIdQuery.data.id : null
                }
                takeRateInBasisPoints={
                  worldByIdQuery.isSuccess
                    ? worldByIdQuery.data.takeRateInBasisPoints
                    : null
                }
              />
            ) : (
              <AmountBreakDown.FeesZero />
            )}
          </TransactionModal.InputWrapper>
        </TransactionModal.Body>
      </TransactionModal.Content>
    </TransactionModal>
  );
}
