import { CSSProperties } from '@stitches/react';

import useTransactionStore from 'state/stores/transactions';

import { ChainId } from 'lib/chains';
import { isNumberType } from 'utils/helpers';
import { selectTransactionByAction } from 'utils/transaction-tracking';

import { TxHash } from 'types/Transactions';
import { Web3ActionConfigByActionName, Web3ActionName } from 'types/Web3';

import TransactionTrackerModalBody from './TransactionTrackerModalBody';
import TransactionTrackerStatus from './TransactionTrackerStatus';
import Modal, { ContentProps, ModalWindowBaseProps } from './base/Modal';

type SuccessConfig = {
  body: React.ReactNode;
  footer: React.ReactNode;
  maxWidth?: CSSProperties['maxWidth'];
  maxHeight?: CSSProperties['maxHeight'];
  size?: ModalWindowBaseProps['size'];
  height?: ModalWindowBaseProps['height'];
};

type TransactionModalContentProps<Action extends Web3ActionName> =
  ContentProps & {
    action: Action;
    chainId: ChainId;
    txHash: TxHash | null;
    renderOnSuccess?: (
      trackedTx: Web3ActionConfigByActionName<Action>
    ) => SuccessConfig;
  };

export default function TransactionModalContent<Action extends Web3ActionName>(
  props: TransactionModalContentProps<Action>
) {
  const { action, chainId, txHash, renderOnSuccess, ...modalProps } = props;

  const trackedTx = useTransactionStore(
    selectTransactionByAction({
      action,
      txHash,
    })
  );

  const hasSuccessState = trackedTx && trackedTx.status === 'SUCCESS';

  const successConfig =
    hasSuccessState && renderOnSuccess
      ? renderOnSuccess(trackedTx.action)
      : null;

  const getFooter = () => {
    if (hasSuccessState && successConfig) {
      return successConfig.footer;
    } else if (trackedTx) {
      return (
        <Modal.Footer>
          <TransactionTrackerStatus
            chainId={chainId}
            status={trackedTx.status}
            txHash={trackedTx.txHash}
          />
        </Modal.Footer>
      );
    } else {
      return modalProps.footer;
    }
  };

  const getBody = () => {
    if (hasSuccessState && successConfig) {
      return successConfig.body;
    } else if (trackedTx) {
      return <TransactionTrackerModalBody tx={trackedTx} />;
    } else {
      return props.children;
    }
  };

  const getMaxWidth = () => {
    if (!trackedTx) {
      return modalProps.maxWidth;
    } else if (
      trackedTx.status === 'SUCCESS' &&
      successConfig &&
      isNumberType(successConfig.maxWidth)
    ) {
      return successConfig.maxWidth;
    } else {
      return 380;
    }
  };

  const getHeight = () => {
    if (!trackedTx) {
      return modalProps.height;
    } else if (
      trackedTx.status === 'SUCCESS' &&
      successConfig &&
      isNumberType(successConfig.height)
    ) {
      return successConfig.height;
    } else {
      return undefined;
    }
  };

  const getSize = () => {
    if (!trackedTx) {
      return modalProps.size;
    } else if (
      trackedTx.status === 'SUCCESS' &&
      successConfig &&
      isNumberType(successConfig.size)
    ) {
      return successConfig.size;
    } else {
      return 1;
    }
  };

  return (
    <Modal.Window
      {...modalProps}
      header={trackedTx ? null : modalProps.header}
      footer={getFooter()}
      maxWidth={getMaxWidth()}
      height={getHeight()}
      size={getSize()}
    >
      {getBody()}
    </Modal.Window>
  );
}
