import { ExternalIcon } from '@f8n/icons';

import DropdownMenu from 'components/base/DropdownMenu';

export function TransferNftMenuItem(props: { onClick: () => void }) {
  return (
    <DropdownMenu.Item type="button" onClick={props.onClick}>
      <ExternalIcon />
      Transfer
    </DropdownMenu.Item>
  );
}
