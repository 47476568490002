import { CheckIcon, CopyIcon } from '@f8n/icons';
import { styled } from '@f8n-frontend/stitches';
import { AnimatePresence, motion } from 'framer-motion';
import { useLocation } from 'react-use';
import { match } from 'ts-pattern';

import FauxInput from 'components/FauxInput';
import Box from 'components/base/Box';
import Button from 'components/base/Button';
import Modal from 'components/base/Modal';
import Text from 'components/base/Text';

import TwitterIcon from 'assets/icons/twitter.svg';
import { useSocialVerificationByService } from 'hooks/queries/hasura/social-verification/use-social-verification';
import useCopyText from 'hooks/use-copy-text';
import { maybeAddEllipsis } from 'utils/helpers';
import { getUserNameOrAddressAndStripAtSymbol } from 'utils/strings';
import {
  appendHandle,
  buildArtworkTweet,
  buildCollectionTweet,
} from 'utils/twitter-templates';

import { UserLight } from 'types/Account';
import { OnlyChildrenProps } from 'types/components';

type ShareModalProps = {
  creator: UserLight;
  onCopyUrl: () => void;
  onShareTwitterClicked: () => void;
  type: 'collection' | 'nft';
};

export function ShareModalWindow(props: ShareModalProps) {
  const { creator } = props;

  const location = useLocation();
  const { hasCopied, handleCopy } = useCopyText();

  const twitterVerificationQuery = useSocialVerificationByService({
    publicKey: creator.publicKey,
    service: 'TWITTER',
  });

  const twitterUsername = twitterVerificationQuery.data
    ? appendHandle(twitterVerificationQuery.data.username)
    : null;

  const creatorName = getUserNameOrAddressAndStripAtSymbol(creator);

  // this intentionally removes existing query params from the url
  const pageUrl = `${location.origin}${location.pathname}`;
  const truncatedUrl = maybeAddEllipsis(46, pageUrl);

  const onCopy = () => {
    handleCopy(pageUrl);
    props.onCopyUrl();
  };

  const openTweetLink = () => {
    const tweetText = match(props)
      .with({ type: 'nft' }, () => {
        return buildArtworkTweet({
          creatorName,
          artworkPath: pageUrl,
          twitterUsername,
        });
      })
      .with({ type: 'collection' }, () => {
        return buildCollectionTweet({
          creatorName: twitterUsername || creatorName,
          collectionPath: pageUrl,
        });
      })
      .exhaustive();

    const tweetShareText = `https://twitter.com/intent/tweet?text=${encodeURI(
      tweetText
    )}`;

    window.open(tweetShareText);
    props.onShareTwitterClicked();
  };

  return (
    <Modal.Window
      footer={
        <Modal.Footer>
          <Button variant="primary" icon onClick={openTweetLink}>
            <TwitterIcon />
            Share
          </Button>
        </Modal.Footer>
      }
    >
      <Box css={{ marginBottom: '$6' }}>
        <Modal.BodyTitle title="Share" />
      </Box>
      <CopyField onClick={onCopy} hasCopied={hasCopied}>
        <CopyFieldText>{truncatedUrl}</CopyFieldText>
        <AnimatePresence exitBeforeEnter initial={false}>
          {hasCopied ? (
            <FadeInOut key="check">
              <CheckIcon />
            </FadeInOut>
          ) : (
            <FadeInOut key="copy">
              <CopyIcon />
            </FadeInOut>
          )}
        </AnimatePresence>
      </CopyField>
    </Modal.Window>
  );
}

const CopyField = styled(FauxInput, {
  cursor: 'pointer',
  backgroundColor: '$black2',
  borderRadius: '$3',
  paddingY: '$5',
  paddingX: '$4',
  alignItems: 'center',
  userSelect: 'none',
  transition: 'background-color $1 $ease',

  '&:hover': {
    backgroundColor: '$black5',
  },

  svg: {
    width: '$icon2',
    height: '$icon2',
  },

  variants: {
    hasCopied: {
      true: {
        '& svg': {
          color: '$green4',
        },
      },
      false: {
        color: '$black80',

        '&:hover': {
          '& svg': {
            color: '$black100',
          },
        },
      },
    },
  },
});

const CopyFieldText = styled(Text, {
  fontSize: '$1',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
});

function FadeInOut(props: OnlyChildrenProps) {
  return (
    <motion.div
      initial={{ opacity: 0, scale: 0.8 }}
      animate={{ opacity: 1, scale: 1 }}
      exit={{ opacity: 0, scale: 0.8 }}
      transition={{
        duration: '0.2',
      }}
    >
      {props.children}
    </motion.div>
  );
}
