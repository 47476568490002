import { styled } from '@f8n-frontend/stitches';
import { VariantProps } from '@stitches/react';
import { LayoutGroup, Variant } from 'framer-motion';
import { motion } from 'framer-motion';
import React, { useState } from 'react';
import { useTimeoutFn } from 'react-use';

import Badge, { BadgeProps } from 'components/base/Badge';
import Box from 'components/base/Box';
import Text from 'components/base/Text';
import { Checkout } from 'components/checkout-widget/Checkout';
import UserTag from 'components/users/UserTag';

import { formatNumber } from 'utils/formatters';

import { UserMicro } from 'types/Account';

const createMotionVariant = <T extends Variant>(config: T): T => config;

const ROW_ITEM_ANIMATION_VARIANTS = {
  hidden: createMotionVariant({
    opacity: 0,
    y: '80%',
  }),
  final: createMotionVariant({
    opacity: 1,
    y: 0,
  }),
};

const ROW_ANIMATION_VARIANTS = {
  hidden: createMotionVariant({}),
  final: createMotionVariant({
    opacity: 1,
    y: 0,
    transition: {
      delayChildren: 0.1,
      staggerChildren: 0.08,
      ease: [0.23, 1, 0.32, 1],
    },
  }),
};

type Size = 'large' | 'small';

/**
 * Root
 */
function Root(props: React.PropsWithChildren) {
  const { children } = props;
  return (
    <LayoutGroup>
      <Checkout.Stack>{children}</Checkout.Stack>
    </LayoutGroup>
  );
}

/**
 * Header
 */
function Header(props: React.PropsWithChildren) {
  const { children } = props;
  return (
    <Box
      css={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
      }}
    >
      {children}
    </Box>
  );
}

/**
 * Header title count component
 */
interface HeaderTitleCountProps {
  title: string;
  count: number;
}

function HeaderTitleCount(props: HeaderTitleCountProps) {
  const { title, count } = props;
  return (
    <Box css={{ display: 'flex', gap: '$1', alignItems: 'center' }}>
      <Text size={1}>{title}</Text>
      {count > 0 && (
        <Badge
          variant="light"
          radius="sharp"
          size={0}
          css={{ paddingX: '$1', paddingY: 1 }}
        >
          {formatNumber(count)}
        </Badge>
      )}
    </Box>
  );
}

/**
 * Body
 */
function Body(props: { children: React.ReactNode; id: string }) {
  const { children, id } = props;
  return (
    <LayoutGroup id={id}>
      <BodyRoot>{children}</BodyRoot>
    </LayoutGroup>
  );
}

/**
 * Collector
 */
type CollectorProps = {
  badge: React.ReactNode | null;
  collector: UserMicro;
  size: Size;
  subline: React.ReactNode | null;
};

function Collector(props: CollectorProps) {
  const { collector, size, subline, badge } = props;
  return (
    <Box
      css={{
        gap: '$3',
        minWidth: 0,
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <motion.div variants={ROW_ITEM_ANIMATION_VARIANTS}>
        <UserTag
          user={collector}
          size={size === 'large' ? 4 : 0}
          type="avatar"
          nameVariant="prefer-display-name"
        />
      </motion.div>
      <RowDetails variants={ROW_ITEM_ANIMATION_VARIANTS}>
        <Box
          css={{
            gap: '$1',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <UserTag
            nameVariant="prefer-display-name"
            user={collector}
            color="strong"
            weight="regular"
            size={size === 'large' ? 1 : 0}
            type="text"
          />
          {badge}
        </Box>
        {subline}
      </RowDetails>
    </Box>
  );
}

/**
 * Details text
 */
type CollectorTextProps = {
  size: Size;
  children: React.ReactNode;
};

function CollectorText(props: CollectorTextProps) {
  const { size, children } = props;
  return (
    <Text
      size={size === 'large' ? 2 : 0}
      weight={size === 'large' ? 'medium' : 'regular'}
      color={size === 'large' ? 'strong' : 'dim'}
    >
      {children}
    </Text>
  );
}

/**
 * Row
 */
type RowProps = VariantProps<typeof RowInner> & {
  collector: React.ReactNode;
  collectorText: React.ReactNode;
  isHydrated: boolean;
  position: number | null;
};
function Row(props: RowProps) {
  const { border, collector, collectorText, isHydrated, position } = props;
  return (
    <RowOuter
      css={{
        '&:first-of-type': {
          [`${RowInner}`]: {
            paddingY: '$4',
          },
        },
      }}
      variants={ROW_ANIMATION_VARIANTS}
      layout="position"
      initial={isHydrated ? 'hidden' : false}
      animate="final"
    >
      <RowInner border={border}>
        {position && (
          <Box as={motion.div} css={{ width: '20px', textAlign: 'center' }}>
            <Text color="strong" size={0}>
              {position}
            </Text>
          </Box>
        )}
        <Box as={motion.div} css={{ flex: 1 }}>
          {collector}
        </Box>
        <motion.div variants={ROW_ITEM_ANIMATION_VARIANTS}>
          {collectorText}
        </motion.div>
      </RowInner>
    </RowOuter>
  );
}

function MicroBadge(props: Pick<BadgeProps, 'children' | 'variant'>) {
  const { children, variant = 'orange' } = props;
  return (
    <Badge
      css={{ width: 'max-content' }}
      size="micro"
      variant={variant}
      radius="sharp"
    >
      {children}
    </Badge>
  );
}

function NewBadge() {
  const [isVisble, setIsVisible] = useState(true);

  useTimeoutFn(() => {
    setIsVisible(false);
  }, 8_000);

  return (
    <Badge
      as={motion.span}
      radius="sharp"
      variant="blue"
      size="micro"
      initial={{ opacity: 1 }}
      animate={{ opacity: isVisble ? 0.6 : 0 }}
      transition={{
        duration: 0.8,
        repeat: isVisble ? Infinity : undefined,
        repeatType: 'reverse',
      }}
    >
      New
    </Badge>
  );
}

const RowOuter = styled(motion.div, {
  overflow: 'hidden',
});

const RowInner = styled(motion.div, {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  width: '100%',
  paddingY: '$3',
  background: '$white100',
  gap: '$2',
  variants: {
    border: {
      dashed: {
        borderTop: '1px dashed $black5',
      },
      solid: {
        borderTop: '1px solid $black5',
      },
      none: {},
    },
  },
  defaultVariants: {
    border: 'solid',
  },
});

const BodyRoot = styled(motion.div, {
  display: 'flex',
  flexDirection: 'column',
});

const RowDetails = styled(motion.div, {
  gap: 2,
  minWidth: 0,
  display: 'flex',
  flexDirection: 'column',
});

const CollectorsTable = {
  Body,
  Collector,
  CollectorText,
  Header,
  HeaderTitleCount,
  MicroBadge,
  NewBadge,
  Root,
  Row,
  RowInner,
  RowOuter,
};

export { CollectorsTable };
