import { useWriteContract, useSimulateContract } from 'wagmi';

import TransactionModal from 'components/TransactionModal';
import Modal from 'components/base/Modal';
import { useAssumeConnectedAuth } from 'contexts/auth/useAuth';
import useTransactionStore from 'state/stores/transactions';

import useModalVisibility from 'hooks/use-modal-visibility';
import { useExhibitionIdForNft } from 'hooks/web3/use-exhibition-id-for-nft';
import { useGetReserveAuction } from 'hooks/web3/use-get-reserve-auction';
import { NFTMarket } from 'lib/abis/NFTMarket';
import { getContractAddress } from 'lib/addresses';
import { ZERO_ADDRESS } from 'lib/constants';
import { extractPrepareContractWriteRevertReason } from 'utils/revert-reasons';

import { ModalOptions } from 'types/modal';

const MODAL_KEY = 'SETTLE_AUCTION';
const ACTION = 'settle-auction';

type SettleAuctionModalOptions = ModalOptions<typeof MODAL_KEY>;

export default function SettleAuctionModal() {
  const modal = useModalVisibility(MODAL_KEY);

  return (
    <Modal.Root open={modal.open} onOpenChange={modal.onOpenChange}>
      <Modal.Portal>
        <Modal.BlurOverlay />
        <Modal.PositionOverlay>
          <Modal.UnmountListener onUnmount={modal.onUnmount} />
          {modal.config && <SettleAuctionModalWindow {...modal.config} />}
        </Modal.PositionOverlay>
      </Modal.Portal>
    </Modal.Root>
  );
}

function SettleAuctionModalWindow(props: SettleAuctionModalOptions) {
  const { auctionId, contractAddress, tokenId, chainId } = props;

  const txStore = useTransactionStore();
  const auth = useAssumeConnectedAuth();

  const getReserveAuctionQuery = useGetReserveAuction({
    chainId,
    auctionId,
  });

  const isAuctionCollector = getReserveAuctionQuery.isSuccess
    ? getReserveAuctionQuery.data.bidder === auth.publicKey
    : false;

  const simulateSettleAuction = useSimulateContract({
    abi: NFTMarket,
    functionName: 'finalizeReserveAuction',
    address: getContractAddress({
      chainId,
      contractName: 'nftMarket',
    }),
    chainId,
    args: [BigInt(auctionId)],
    query: {
      retry: false,
    },
  });

  const exhibitionByNft = useExhibitionIdForNft({
    nftFilter: { chainId, contractAddress, tokenId },
    sellerPublicKey: getReserveAuctionQuery.isSuccess
      ? getReserveAuctionQuery.data.seller
      : ZERO_ADDRESS,
  });

  const contractWrite = useWriteContract({
    mutation: {
      onSuccess: (txHash) => {
        txStore.startTracking({
          chainId,
          ui: 'toast',
          action: {
            name: ACTION,
            worldId: exhibitionByNft.data
              ? Number(exhibitionByNft.data.worldId)
              : null,
          },
          txHash,
          title: {
            PENDING: 'Settling auction…',
            SUCCESS: isAuctionCollector ? 'You got it!' : 'Auction settled',
          },
          description: {
            PENDING:
              'This auction is being settled. You can close this if you like.',
            SUCCESS: isAuctionCollector
              ? 'This auction has been settled, and the NFT is now in your collection.'
              : 'This auction has been settled, and the ETH is now in the seller’s wallet.',
          },
        });
      },
    },
  });

  const parsedError = extractPrepareContractWriteRevertReason(
    simulateSettleAuction
  );

  return (
    <TransactionModal
      chainId={chainId}
      preSignPrompt={{
        type: 'skip',
      }}
    >
      <TransactionModal.Content
        action={ACTION}
        chainId={chainId}
        txHash={contractWrite.data || null}
        footer={
          <Modal.Footer>
            <TransactionModal.TransactionButton
              chainId={chainId}
              isLoading={contractWrite.isPending || Boolean(contractWrite.data)}
              isDisabled={!simulateSettleAuction.isSuccess}
              write={() => {
                if (simulateSettleAuction.isSuccess) {
                  contractWrite.writeContract(
                    simulateSettleAuction.data.request
                  );
                }
              }}
              error={parsedError}
              label="Settle auction"
            />
          </Modal.Footer>
        }
      >
        <TransactionModal.Body>
          <Modal.BodyTitle
            align="left"
            title="Settle auction"
            description={
              isAuctionCollector
                ? 'Congratulations, you won the auction. Settle the auction to add it to your collection, and to release funds from escrow to the seller.'
                : 'The auction has ended and ETH can now be claimed from the sale. Settle the auction to release funds from escrow to the seller.'
            }
          />
        </TransactionModal.Body>
      </TransactionModal.Content>
    </TransactionModal>
  );
}
