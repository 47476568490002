import { styled } from '@f8n-frontend/stitches';

import Button from 'components/base/Button';
import Modal from 'components/base/Modal';
import { PLEASE_SIGN } from 'copy/auth';

import useModal from 'hooks/use-modal';
import useDisconnectWallet from 'hooks/web3/wallet/use-disconnect-wallet';

import SignButton from './SignButton';

type PreSignPromptProps = {
  onSign?: () => void;
  secondaryAction?: 'disconnect' | 'none';
  title?: string;
  description?: string;
};

export default function PreSignPrompt(props: PreSignPromptProps) {
  const {
    onSign,
    secondaryAction = 'disconnect',
    title = PLEASE_SIGN.heading,
    description = PLEASE_SIGN.subheading,
  } = props;
  const { setModal } = useModal();
  const { mutateAsync: disconnectWallet } = useDisconnectWallet();

  const handleDisconnect = async () => {
    setModal(null);
    await disconnectWallet();
  };

  return (
    <Container>
      <Modal.BodyTitle align="center" description={description} title={title} />
      <Modal.ContentStack>
        <SignButton onSign={onSign} />
        {secondaryAction === 'disconnect' && (
          <Button
            css={{ marginX: 'auto' }}
            onClick={() => handleDisconnect()}
            variant="ghost"
          >
            Disconnect
          </Button>
        )}
      </Modal.ContentStack>
    </Container>
  );
}

const Container = styled('div', {
  gap: '$6',
  display: 'flex',
  flexDirection: 'column',
  '@bp2-max': {
    paddingY: '$3',
  },
  '@bp2': {
    gap: '$7',
  },
});
