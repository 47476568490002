import { FrameMetadata, FrameMetadataReact } from '@coinbase/onchainkit';
import Head from 'next/head';
import { P, match } from 'ts-pattern';
import type { Address } from 'viem';

import { ApiMomentFragment } from 'gql/api/api-fragments.generated';
import { isUpcoming } from 'utils/dates/dates';
import { buildFarcasterUrl } from 'utils/farcaster';
import { nftButtons, sharedButtons } from 'utils/frames';
import { isNumberType } from 'utils/helpers';
import { getTokenFilter } from 'utils/inputs';
import { getPath } from 'utils/router';

import { MarketNft, NftFilter } from 'types/Nft';
import { WorldOverview } from 'types/World';

type FarcasterHeadProps = {
  collectionName: string;
  contractAddress: Address;
  creatorAddress: string;
  mintStatus?: 'live' | 'closed' | undefined;
  mintCount?: number;
};

/* @see https://warpcast.notion.site/NFT-extended-Open-Graph-Spec-4e350bd8e4c34e3b86e77d58bf1f5575 */
export default function FarcasterHead(props: FarcasterHeadProps) {
  const {
    collectionName,
    contractAddress,
    creatorAddress,
    mintStatus,
    mintCount,
  } = props;

  return (
    <Head>
      <meta property="eth:nft:collection" content={collectionName} />
      <meta property="eth:nft:contract_address" content={contractAddress} />
      <meta property="eth:nft:creator_address" content={creatorAddress} />
      <meta property="eth:nft:schema" content="erc721" />
      {mintStatus && (
        <meta property="eth:nft:mint_status" content={mintStatus} />
      )}
      {isNumberType(mintCount) && (
        <meta property="eth:nft:mint_count" content={mintCount.toString()} />
      )}
    </Head>
  );
}

type FarcasterHeadMomentProps = {
  moment: Pick<ApiMomentFragment, 'startsAt' | 'name' | 'id' | 'posterUrl'>;
  world: Pick<WorldOverview, 'slug'>;
};

FarcasterHead.Moment = FarcasterHeadMoment;

function FarcasterHeadMoment(props: FarcasterHeadMomentProps) {
  const moment = props.moment;
  const world = props.world;

  const exhibitionUrl = buildFarcasterUrl(
    getPath.moment.page({
      worldSlug: world.slug,
      momentId: moment.id,
    })
  );

  const ogImageUrl = buildFarcasterUrl(
    getPath.api.openGraph.moment({
      momentId: moment.id,
      cacheKey: Date.now(),
    })
  );

  const postUrl = buildFarcasterUrl(
    getPath.api.farcasterWebhook.moment(moment.id)
  );

  return (
    <Head>
      <FrameMetadata
        image={{
          src: ogImageUrl,
          aspectRatio: '1:1',
        }}
        postUrl={postUrl}
        buttons={[
          {
            label: 'Refresh exhibition',
            action: 'post',
          },
          {
            label: 'View exhibition',
            action: 'link',
            target: exhibitionUrl,
          },
          {
            label: 'Save exhibition',
            action: 'post',
          },
        ]}
      />
    </Head>
  );
}

type FarcasterHeadNftProps = {
  nft: NftFilter &
    Pick<MarketNft, 'activeAuction' | 'activeBuyNow' | 'saleStartsAt'>;
};

export function FarcasterHeadNft(props: FarcasterHeadNftProps) {
  const nftFilter = getTokenFilter(props.nft);

  const nftPageUrl = buildFarcasterUrl(getPath.token.page(nftFilter));
  const ogImageUrl = buildFarcasterUrl(getPath.api.openGraph.nft(nftFilter));
  const postUrl = buildFarcasterUrl(
    getPath.api.farcasterWebhook.nft(nftFilter)
  );

  /**
   * Buttons are added according to the following rules:
   *
   * 1. If an auction is LIVE, show the 'Place bid' and 'Refresh' bid buttons. Else,
   * 2. If an auction is OPEN and scheduled, show the 'View artwork' button. Else,
   * 3. If an auction is OPEN and Buy Now exists, show the 'Place bid' and 'Buy now' button(s). Else,
   * 4. If an auction is OPEN, show the 'Place bid' button. Else,
   * 5. If scheduled for Buy Now, show the 'View artwork' button. Else,
   * 6. If available for Buy Now, show the 'Buy now' button. Else,
   * 7. For all other states, show the 'View artwork' button.
   */
  const buttons = match(props.nft)
    .with({ activeAuction: { state: 'LIVE' } }, () => [
      nftButtons.bid(nftPageUrl),
      sharedButtons.refresh,
    ])
    .when(
      (nft) =>
        nft.activeAuction?.state === 'OPEN' && isUpcoming(nft.saleStartsAt),
      () => [nftButtons.view(nftPageUrl)]
    )
    .with(
      { activeAuction: { state: 'OPEN' }, activeBuyNow: P.not(null) },
      () => [nftButtons.bid(nftPageUrl), nftButtons.buy(nftPageUrl)]
    )
    .with({ activeAuction: { state: 'OPEN' } }, () => [
      nftButtons.bid(nftPageUrl),
    ])
    .when(
      (nft) => nft.activeBuyNow && isUpcoming(nft.saleStartsAt),
      () => [nftButtons.view(nftPageUrl)]
    )
    .with({ activeBuyNow: P.not(null) }, () => [nftButtons.buy(nftPageUrl)])
    .otherwise(() => [nftButtons.view(nftPageUrl)]);

  return (
    <FrameMetadata
      image={{
        src: ogImageUrl,
        aspectRatio: '1:1',
      }}
      postUrl={postUrl}
      buttons={buttons as FrameMetadataReact['buttons']} // TODO: Upgrade to ts-pattern@^5 to use .returnType()
      wrapper={Head}
    />
  );
}
