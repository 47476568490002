import { useSwitchChain } from 'wagmi';

import useAuth from 'contexts/auth/useAuth';

import useModal from 'hooks/use-modal';
import { ChainId } from 'lib/chains';
import { getChainConfigById, isBaseChainConfig } from 'utils/network';
import { getOnPageWeb3ActionCtaConfig } from 'utils/web3-cta';

import { Web3ActionCtaConfig, Web3PromptLifecycle } from 'types/Web3ActionCta';

export const useWeb3ActionCta = (
  options: Web3PromptLifecycle & {
    chainId: ChainId;
    context: 'on-page' | 'tx-modal';
  }
): Web3ActionCtaConfig => {
  const { chainId, config, context, trackedTx } = options;

  const auth = useAuth();
  const modal = useModal();
  const { switchChain, status: switchChainStatus } = useSwitchChain();

  const chainConfig = getChainConfigById(chainId);

  /**
   * Bridging is currently only enabled on Base chains.
   *
   * Note that it's not supported via a CTA when in a transaction modal context.
   * This is because we don't support calling modal.setModal from within another modal.
   * Doing so causes the first modal to crash.
   *
   * Transaction modals also handle bridging internally, meaning there's no benefit to opening a new modal.
   */
  const bridgeCta =
    isBaseChainConfig(chainConfig) && context !== 'tx-modal'
      ? {
          prompt: () => {
            modal.setModal({
              type: 'BRIDGE',
              chainConfig,
            });
          },
        }
      : null;

  return getOnPageWeb3ActionCtaConfig({
    action: {
      config,
      trackedTx,
    },
    auth,
    bridgeCta,
    connectCta: {
      prompt: () => {
        if (auth.state === 'disconnected' || auth.state === 'unknown') {
          auth.startLogin();
        }
      },
    },
    switchChainCta: {
      status: switchChainStatus,
      prompt: () => {
        switchChain({ chainId });
      },
    },
    chainId,
  });
};
