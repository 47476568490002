import { useQueryClient } from '@tanstack/react-query';
import { UseReadContractReturnType, useWatchBlockNumber } from 'wagmi';

/**
 * @see https://wagmi.sh/react/guides/migrate-from-v1-to-v2#removed-watch-property
 * TODO: rename to emphasize that this only works with wagmi hooks
 */
export function useRefetchOnBlock<
  Query extends Pick<UseReadContractReturnType, 'queryKey'>,
>(query: Query, options: { enabled?: boolean } = {}) {
  const queryClient = useQueryClient();

  useWatchBlockNumber({
    enabled: options?.enabled ?? true,
    onBlockNumber: () => {
      queryClient.invalidateQueries({
        queryKey: query.queryKey,
      });
    },
  });
}
