import { HighlightIcon } from '@f8n/icons';
import { styled } from '@f8n-frontend/stitches';
import NextLink from 'next/link';
import { always } from 'ramda';
import React from 'react';
import { P, match } from 'ts-pattern';
import { Address } from 'viem';

import { ActivityTable } from 'components/ActivityTable';
import ChainIcon from 'components/ChainIcon';
import { DetailsTable } from 'components/DetailsTable';
import AvatarText from 'components/base/AvatarText';
import Box from 'components/base/Box';
import Button from 'components/base/Button';
import CopyToClipboard from 'components/base/CopyToClipboard';
import Link from 'components/base/Link';
import Mono from 'components/base/Mono';
import CollectionHoverCard from 'components/collections/CollectionHoverCard';
import UserStack from 'components/follows/UserStack';
import UserTag from 'components/users/UserTag';

import Logo from 'assets/images/fnd-logo.svg';
import { ChainConfig, ChainId } from 'lib/chains';
import { getBlockExplorerByChainId } from 'utils/block-explorer';
import { getCollectionLabel } from 'utils/collections';
import { truncateAddress } from 'utils/helpers';
import { getCollectionFilter } from 'utils/inputs';
import { isBaseChainConfig } from 'utils/network';
import { getCollectionPath } from 'utils/router';

import { UserLight } from 'types/Account';
import { CollectionLight, ContractType } from 'types/Collection';
import { NftAttribute } from 'types/Nft';
import { ProvenanceEvent } from 'types/Provenance';
import { TokenCategory } from 'types/Token';

type MintLayoutDetailsTableProps = {
  blockExplorerHref: string;
  chainConfig: ChainConfig;
  collection: CollectionLight | null;
  contractAddress: Address;
  contractType: ContractType;
  medium: string | null;
  metadataUrl: string | null;
  onSplitsClick: () => void;
  owner: UserLight | null;
  sourceUrl: string | null;
  splits: UserLight[] | null;
  tokenId: number | null;
  tokenCategory: TokenCategory;
};

function MintLayoutDetailsTable(props: MintLayoutDetailsTableProps) {
  const {
    blockExplorerHref,
    collection,
    chainConfig,
    contractAddress,
    contractType,
    medium,
    metadataUrl,
    onSplitsClick,
    owner,
    sourceUrl,
    splits,
    tokenId,
    tokenCategory,
  } = props;

  const createdWithLine = getCreatedWithLine(contractType);

  return (
    <DetailsTable.Root size="0">
      {collection && (
        <DetailsTable.Row>
          <DetailsTable.Label>Collection</DetailsTable.Label>
          <DetailsTable.Item>
            <CollectionLine collection={collection} />
          </DetailsTable.Item>
        </DetailsTable.Row>
      )}
      {owner && (
        <DetailsTable.Row>
          <DetailsTable.Label>Owned by</DetailsTable.Label>
          <DetailsTable.Item>
            <OwnerLine owner={owner} />
          </DetailsTable.Item>
        </DetailsTable.Row>
      )}
      <DetailsTable.Row>
        <DetailsTable.Label>Blockchain</DetailsTable.Label>
        <DetailsTable.Item>
          <ChainLine chainConfig={chainConfig} />
        </DetailsTable.Item>
      </DetailsTable.Row>
      {tokenId && (
        <DetailsTable.Row>
          <DetailsTable.Label>Token ID</DetailsTable.Label>
          <DetailsTable.Item>{tokenId}</DetailsTable.Item>
        </DetailsTable.Row>
      )}
      {splits && splits.length > 0 && (
        <DetailsTable.Row>
          <DetailsTable.Label>Splits</DetailsTable.Label>
          <DetailsTable.Item>
            <SplitsLine onClick={onSplitsClick} users={splits} />
          </DetailsTable.Item>
        </DetailsTable.Row>
      )}
      <DetailsTable.Row>
        <DetailsTable.Label>Contract</DetailsTable.Label>
        <DetailsTable.Item>
          <ContractLine address={contractAddress} href={blockExplorerHref} />
        </DetailsTable.Item>
      </DetailsTable.Row>
      {createdWithLine && (
        <DetailsTable.Row>
          <DetailsTable.Label>Created with</DetailsTable.Label>
          <DetailsTable.Item>{createdWithLine}</DetailsTable.Item>
        </DetailsTable.Row>
      )}
      <DetailsTable.Row>
        <DetailsTable.Label>Token standard</DetailsTable.Label>
        <DetailsTable.Item>
          {mapTokenCategoryToCopy(tokenCategory)}
        </DetailsTable.Item>
      </DetailsTable.Row>
      {metadataUrl && (
        <DetailsTable.Row>
          <DetailsTable.Label>Metadata</DetailsTable.Label>
          <DetailsTable.Item>
            <Link
              variant="strong"
              hasUnderline
              href={metadataUrl}
              target="_blank"
            >
              IPFS
            </Link>
          </DetailsTable.Item>
        </DetailsTable.Row>
      )}
      {medium && (
        <DetailsTable.Row>
          <DetailsTable.Label>Medium</DetailsTable.Label>
          {sourceUrl ? (
            <Link
              href={sourceUrl}
              variant="strong"
              hasUnderline
              size={0}
              target="_blank"
              rel="noreferrer"
            >
              <DetailsTable.Item>{medium}</DetailsTable.Item>
            </Link>
          ) : (
            <DetailsTable.Item>{medium}</DetailsTable.Item>
          )}
        </DetailsTable.Row>
      )}
    </DetailsTable.Root>
  );
}

const mapTokenCategoryToCopy = (tokenCategory: TokenCategory) => {
  return match(tokenCategory)
    .with('nft', () => 'ERC-721')
    .with('editionToken', () => 'ERC-1155')
    .otherwise(() => tokenCategory);
};

function MintLayoutAttributesTable(props: { attributes: NftAttribute[] }) {
  const { attributes } = props;
  return (
    <DetailsTable.Root size="0">
      {attributes.map((attribute) => (
        <DetailsTable.Row key={attribute.label}>
          <DetailsTable.Label>{attribute.category}</DetailsTable.Label>
          <DetailsTable.Item
            css={{ display: 'flex', justifyContent: 'space-between' }}
          >
            <NextLink href={attribute.deepLinkUrl} passHref>
              <Link hasUnderline variant="strong">
                {attribute.label}
              </Link>
            </NextLink>
            <Mono size={0} color="dim">
              {attribute.rarity}
            </Mono>
          </DetailsTable.Item>
        </DetailsTable.Row>
      ))}
    </DetailsTable.Root>
  );
}

function MintLayoutActivityTable(props: {
  provenanceEvents: ProvenanceEvent[];
  chainId: ChainId;
}) {
  const blockExplorers = getBlockExplorerByChainId(props.chainId);

  const getEventType = (event: ProvenanceEvent) => {
    return match(event)
      .with({ type: 'TRANSFER' }, (event) => (
        <ActivityTable.TransferEvent
          {...event}
          txLink={blockExplorers.tx.getUrl(event)}
        />
      ))
      .with({ type: 'AUCTION_FINALIZED' }, (event) => (
        <ActivityTable.SettledEvent
          {...event}
          txLink={blockExplorers.tx.getUrl(event)}
        />
      ))
      .with({ amountInEth: P.number }, (event) => (
        <ActivityTable.GenericEventWithValue
          {...event}
          txLink={blockExplorers.tx.getUrl(event)}
        />
      ))
      .otherwise((event) => (
        <ActivityTable.GenericEvent
          {...event}
          txLink={blockExplorers.tx.getUrl(event)}
        />
      ));
  };
  return (
    <DetailsTable.Root size="0">
      {props.provenanceEvents.map(getEventType)}
    </DetailsTable.Root>
  );
}

const MintLayoutTableAvatarContainer = styled('div', {
  display: 'flex',
  alignItems: 'center',

  [`${Link} span`]: {
    color: '$black100',
    fontSize: '$0',
    fontWeight: '$regular',
    lineHeight: '16px',
    underline: true,

    '&:hover': {
      color: '$black60',
    },
  },
});

/**
 * Splits line
 */
function SplitsLine(props: { users: UserLight[]; onClick: () => void }) {
  const { users, onClick } = props;

  if (users.length === 0) {
    return null;
  }

  return (
    <Button
      variant="base"
      css={{ display: 'flex', alignItems: 'center', gap: '$1' }}
      onClick={onClick}
    >
      <UserStack size="0" variant="white" users={users} />
      <Link as="span" variant="strong" hasUnderline size={0}>
        {users.length} addresses
      </Link>
    </Button>
  );
}

/**
 * Collection line
 */
function CollectionLine(props: { collection: CollectionLight }) {
  const { collection } = props;
  return (
    <MintLayoutTableAvatarContainer>
      <CollectionHoverCard filter={getCollectionFilter(collection)} type="lazy">
        <NextLink href={getCollectionPath(collection)} passHref>
          <Link>
            <AvatarText
              imageUrl={collection.collectionImageUrl}
              mono={!collection.name}
              shape="square"
              size={0}
              text={getCollectionLabel(collection)}
            />
          </Link>
        </NextLink>
      </CollectionHoverCard>
    </MintLayoutTableAvatarContainer>
  );
}

/**
 * Owner line
 */
function OwnerLine(props: { owner: UserLight }) {
  const { owner } = props;
  return (
    <MintLayoutTableAvatarContainer>
      <UserTag
        nameVariant="prefer-display-name"
        size="0"
        type="avatar-text"
        user={owner}
      />
    </MintLayoutTableAvatarContainer>
  );
}

/**
 * Contract line
 */
function ContractLine(props: { address: Address; href: string }) {
  const { address, href } = props;
  return (
    <Box
      css={{
        display: 'flex',
        alignItems: 'center',
        div: {
          // TODO: investigate why minHeight was needed in the <CopyToClipboard />
          // in the first place
          minHeight: 0,
        },
        svg: {
          width: '$icon0',
          height: '$icon0',
        },
      }}
    >
      <Link
        href={href}
        variant="strong"
        hasUnderline
        size={0}
        target="_blank"
        rel="noreferrer"
      >
        {truncateAddress(address)}
      </Link>
      <CopyToClipboard textToCopy={address} />
    </Box>
  );
}

/**
 * Chain line
 */
function ChainLine(props: { chainConfig: ChainConfig }) {
  const { chainConfig } = props;

  return (
    <DetailsTable.IconTag
      color={match(chainConfig)
        .when(isBaseChainConfig, always('base' as const))
        .otherwise(always(undefined))}
      content={chainConfig.copy.chainName}
      icon={<ChainIcon config={chainConfig} />}
    />
  );
}

const getCreatedWithLine = (contractType: ContractType) => {
  return match(contractType)
    .with('HIGHLIGHT_GENERATIVE_SERIES', () => (
      <DetailsTable.IconTag content="Highlight" icon={<HighlightIcon />} />
    ))
    .with(
      P.union(
        'FND',
        'FND_BATCH_MINT_REVEAL',
        'FND_COLLECTION',
        'LIMITED_EDITION',
        'TIMED_EDITION'
      ),
      () => <DetailsTable.IconTag content="Foundation" icon={<Logo />} />
    )
    .otherwise(() => null);
};

const MintLayoutTable = {
  Attributes: MintLayoutAttributesTable,
  Details: MintLayoutDetailsTable,
  Activity: MintLayoutActivityTable,
};

export { MintLayoutTable };
