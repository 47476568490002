import { getAssociationByNft } from '@f8n/f8n-contracts/dist/abis/WorldsHelpers.generated';
import type { Address } from 'viem';
import { useReadContract } from 'wagmi';

import { Worlds } from 'lib/abis/Worlds';
import { getContractAddress } from 'lib/addresses';

import { NftFilter } from 'types/Nft';

type Options = {
  enabled?: boolean;
};

export function useExhibitionIdForNft(
  variables: {
    nftFilter: NftFilter;
    sellerPublicKey: Address;
  },
  options: Options = {}
) {
  const { nftFilter, sellerPublicKey } = variables;
  const { chainId, contractAddress, tokenId } = nftFilter;

  return useReadContract({
    abi: Worlds,
    address: getContractAddress({
      chainId,
      contractName: 'worlds',
    }),
    chainId,
    functionName: 'getAssociationByNft',
    args: [contractAddress, BigInt(tokenId), sellerPublicKey],
    query: {
      enabled: options.enabled ?? true,
      select: getAssociationByNft.parseOutput,
    },
  });
}
