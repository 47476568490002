import { useSwitchChain } from 'wagmi';

import { hasPublicKey } from 'contexts/auth/helpers';
import useAuth from 'contexts/auth/useAuth';

import { ChainId } from 'lib/chains';
import { getChainConfigById } from 'utils/network';

import Button, { LoaderButtonProps } from './Button';

type Web3ActionButtonProps = Pick<
  LoaderButtonProps,
  'children' | 'size' | 'type' | 'variant' | 'width'
> & {
  chainId: ChainId;
};

export function ConnectToChainButton(props: Web3ActionButtonProps) {
  const { chainId, size, variant = 'primary', width, type } = props;

  const auth = useAuth();
  const network = useSwitchChain();

  const isDisconnected = !hasPublicKey(auth);

  const chainConfig = getChainConfigById(chainId);

  return (
    <Button.Loader
      disabled={false}
      isLoading={network.isPending}
      loadingLabel="Switch network in wallet"
      onClick={() => {
        if (isDisconnected) {
          auth.startLogin();
        }

        if (network.switchChain) {
          network.switchChain({ chainId });
        }
      }}
      size={size}
      type={type}
      variant={variant}
      width={width}
    >
      {chainConfig.copy.connectToChain}
    </Button.Loader>
  );
}
