import { styled } from '@f8n-frontend/stitches';
import { AnimatePresence, motion } from 'framer-motion';
import { match } from 'ts-pattern';
import { Address } from 'viem';

import CurrencyAvatar from 'components/CurrencyAvatar';
import Badge from 'components/base/Badge';
import Box from 'components/base/Box';
import Button from 'components/base/Button';
import Flex from 'components/base/Flex';
import Text from 'components/base/Text';
import UserAvatar from 'components/base/UserAvatar';

import { useIsHydrated } from 'hooks/use-is-hydrated';
import {
  formatETHWithSuffix,
  formatInteger,
  formatNumber,
} from 'utils/formatters';
import { areKeysEqual } from 'utils/users';

import { UserMicro } from 'types/Account';
import { Bid } from 'types/Nft';

import { CollectorsTable } from '../CollectorsTable';

import { useMarketWidget } from './MarketWidgetContext';
import { MARKET_WIDGET_BIDDER_MESSAGE, MARKET_WIDGET_LABEL } from './copy';
import { NftLiveAuction, NftLiveAuctionStatus } from './types';
import { createBidsTableBreakdown } from './utils';

export function MarketWidgetLiveAuctionBidsTable(props: {
  auctionStatus: NftLiveAuctionStatus;
  currentUserPublicKey: Address | null;
  isCurrentUserWinning: boolean;
  liveAuction: NftLiveAuction;
}) {
  const {
    auctionStatus,
    currentUserPublicKey,
    isCurrentUserWinning,
    liveAuction,
  } = props;
  const { bids, firstBid } = liveAuction;
  const { onViewMoreBids } = useMarketWidget();
  const totalBidsCount = liveAuction.bidCount;
  return (
    <CollectorsTable.Root>
      <CollectorsTable.Header>
        <CollectorsTable.HeaderTitleCount
          count={totalBidsCount}
          title={MARKET_WIDGET_LABEL.ALL_BIDS}
        />
        <LiveAuctionBidStatusBadge
          auctionStatus={auctionStatus}
          bids={bids}
          isCurrentUserWinning={isCurrentUserWinning}
          currentUserPublicKey={currentUserPublicKey}
        />
      </CollectorsTable.Header>
      <LiveAuctionBidTimelineTable
        auctionStatus={auctionStatus}
        bids={bids}
        firstBid={firstBid}
        onViewMoreBids={onViewMoreBids}
        totalBidsCount={totalBidsCount}
      />
    </CollectorsTable.Root>
  );
}

function LiveAuctionBidStatusBadge(props: {
  auctionStatus: NftLiveAuctionStatus;
  currentUserPublicKey: Address | null;
  isCurrentUserWinning: boolean;
  bids: Bid[];
}) {
  const { auctionStatus, bids, isCurrentUserWinning, currentUserPublicKey } =
    props;
  const isCurrentUserInvolvedInAuction = bids.some((bid) =>
    areKeysEqual([bid.bidder.publicKey, currentUserPublicKey])
  );

  const latestBid = bids[0];

  if (!latestBid) return null;

  return match({
    auctionStatus,
    isCurrentUserInvolvedInAuction,
    isCurrentUserWinning,
  })
    .with(
      {
        auctionStatus: 'LIVE',
        isCurrentUserInvolvedInAuction: true,
        isCurrentUserWinning: false,
      },
      () => {
        return (
          <Badge variant="red" radius="sharp">
            {MARKET_WIDGET_BIDDER_MESSAGE.YOU_LOST_LEAD}
          </Badge>
        );
      }
    )
    .otherwise(() => null);
}

const COLLAPSED_SECTION_AVATAR_WIDTH = 20;
const COLLAPSED_SECTION_OVERLAPPING_AVATAR_WIDTH = 12;
const AVATAR_STACK_WIDTH_2_ITEMS =
  COLLAPSED_SECTION_AVATAR_WIDTH + COLLAPSED_SECTION_OVERLAPPING_AVATAR_WIDTH;
const AVATAR_STACK_WIDTH_3_ITEMS =
  AVATAR_STACK_WIDTH_2_ITEMS + COLLAPSED_SECTION_OVERLAPPING_AVATAR_WIDTH;

const getBidKey = (bid: Bid) => {
  return `${bid.bidder.publicKey}-${bid.amount}`;
};

function LiveAuctionBidTimelineTable(props: {
  auctionStatus: NftLiveAuctionStatus;
  bids: Bid[];
  firstBid: Bid;
  totalBidsCount: number;
  onViewMoreBids: () => void;
}) {
  const { auctionStatus, bids, firstBid, totalBidsCount, onViewMoreBids } =
    props;

  const isHydrated = useIsHydrated();

  const breakdown = createBidsTableBreakdown({
    bids,
    firstBid,
    totalBidsCount,
  });

  const hasMaximumCollapsedPreviewCount = breakdown.expandableSection
    ? breakdown.expandableSection.hiddenItemsCount >= 3
    : false;

  const collapsedAvatarStackWidth = hasMaximumCollapsedPreviewCount
    ? AVATAR_STACK_WIDTH_3_ITEMS
    : AVATAR_STACK_WIDTH_2_ITEMS;

  return (
    <CollectorsTable.Body id="bid-table">
      {breakdown.latestBids.map((bid, index) => {
        const isWinningBid = index === 0;
        return (
          <BidTableBidderRow
            auctionStatus={auctionStatus}
            key={getBidKey(bid)}
            bid={bid}
            isHydrated={isHydrated}
            isOpeningBid={false}
            isWinningBid={isWinningBid}
          />
        );
      })}
      {breakdown.expandableSection && (
        <CollectorsTable.RowOuter>
          <CollectorsTable.RowInner
            css={{
              height: 50,
              justifyContent: 'center',
            }}
          >
            <Button
              css={{
                gap: '$2',
                display: 'flex',
                position: 'relative',
                alignItems: 'center',
                marginLeft: collapsedAvatarStackWidth + 6,
              }}
              onClick={onViewMoreBids}
              variant="base"
            >
              <Box
                css={{
                  display: 'flex',
                  alignItems: 'center',
                  position: 'absolute',
                  height: '100%',
                  left: 0 - collapsedAvatarStackWidth - 6,
                  width: collapsedAvatarStackWidth,
                }}
              >
                <AnimatePresence>
                  {breakdown.expandableSection.bidderPreview.map(
                    (bid, index) => {
                      const x = 0 + 12 * index;
                      return (
                        <motion.div
                          key={getBidKey(bid)}
                          initial={{ x, y: -40, opacity: 0.5 }}
                          animate={{ x, y: 0, opacity: 1 }}
                          exit={{ x, y: 40, opacity: 0.5 }}
                          layout="position"
                          style={{
                            position: 'absolute',
                            zIndex: index,
                          }}
                          transition={{
                            type: 'spring',
                            duration: 0.75,
                            bounce: 0.15,
                          }}
                        >
                          <UserAvatar
                            imageUrl={bid.bidder.profileImageUrl}
                            publicKey={bid.bidder.publicKey}
                            variant="white"
                            size={0}
                          />
                        </motion.div>
                      );
                    }
                  )}
                </AnimatePresence>
              </Box>
              <Box>
                <Text size={1} color="strong">
                  {formatNumber(breakdown.expandableSection.hiddenItemsCount)}{' '}
                  more bids
                </Text>
              </Box>
            </Button>
          </CollectorsTable.RowInner>
        </CollectorsTable.RowOuter>
      )}
      {breakdown.firstBid && (
        <BidTableBidderRow
          auctionStatus={auctionStatus}
          key={getBidKey(breakdown.firstBid)}
          bid={breakdown.firstBid}
          isHydrated={isHydrated}
          isOpeningBid
          isWinningBid={totalBidsCount === 1}
        />
      )}
    </CollectorsTable.Body>
  );
}

export function BidTableBidderRow(props: {
  auctionStatus: NftLiveAuctionStatus;
  bid: Bid;
  isHydrated: boolean;
  isOpeningBid: boolean;
  isWinningBid: boolean;
}) {
  const { auctionStatus, bid, isWinningBid, isOpeningBid, isHydrated } = props;
  const { currency } = useMarketWidget();
  return (
    <CollectorsTable.Row
      position={null}
      collector={
        <DetailsTagBidder
          auctionStatus={auctionStatus}
          bidder={bid.bidder}
          isOpeningBid={isOpeningBid}
          isWinningBid={isWinningBid}
        />
      }
      collectorText={
        currency ? (
          <TokenBidContainer>
            <CollectorsTable.CollectorText
              size={isWinningBid ? 'large' : 'small'}
            >
              {formatInteger(bid.amount)}
            </CollectorsTable.CollectorText>
            <CurrencyAvatar currency={currency} size={isWinningBid ? 1 : 0} />
          </TokenBidContainer>
        ) : (
          <CollectorsTable.CollectorText
            size={isWinningBid ? 'large' : 'small'}
          >
            {formatETHWithSuffix(bid.amount)}
          </CollectorsTable.CollectorText>
        )
      }
      isHydrated={isHydrated}
    />
  );
}

const TokenBidContainer = styled(Flex, {
  variant: 'center',
  gap: 5,
});

function DetailsTagBidder(props: {
  auctionStatus: NftLiveAuctionStatus;
  bidder: UserMicro;
  isOpeningBid: boolean;
  isWinningBid: boolean;
}) {
  const { bidder, isWinningBid } = props;
  return (
    <CollectorsTable.Collector
      collector={bidder}
      size={isWinningBid ? 'large' : 'small'}
      subline={match(props)
        .with(
          { auctionStatus: 'LIVE', isOpeningBid: true, isWinningBid: true },
          () => {
            return <OpeningBidBadge />;
          }
        )
        .with({ auctionStatus: 'ENDED', isWinningBid: true }, () => {
          return (
            <BidTimelineHighestBidderTextContainer>
              <Text color="dim" size={0}>
                Winning bid
              </Text>
            </BidTimelineHighestBidderTextContainer>
          );
        })
        .with({ isWinningBid: true }, () => {
          return (
            <BidTimelineHighestBidderTextContainer>
              <Text color="dim" size={0}>
                Highest bidder
              </Text>
              <CollectorsTable.NewBadge />
            </BidTimelineHighestBidderTextContainer>
          );
        })
        .otherwise(() => null)}
      badge={match(props)
        .with(
          {
            isOpeningBid: true,
            isWinningBid: false,
          },
          () => {
            return <OpeningBidBadge />;
          }
        )
        .otherwise(() => null)}
    />
  );
}

function OpeningBidBadge() {
  return <CollectorsTable.MicroBadge>1st bid</CollectorsTable.MicroBadge>;
}

const BidTimelineHighestBidderTextContainer = styled(motion.div, {
  display: 'flex',
  alignItems: 'center',
  gap: '$2',
});
