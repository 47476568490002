import Grid from 'components/base/Grid';

import { ApiSplitFragment } from 'gql/api/api-fragments.generated';

import SplitsBreakdownRow from './SplitsBreakdownRow';

type SplitsBreakdownProps = {
  splits: ApiSplitFragment[];
};

export default function SplitsBreakdown(props: SplitsBreakdownProps) {
  const { splits } = props;
  return (
    <Grid css={{ gap: '$7' }}>
      {splits.map((split, index) => (
        <SplitsBreakdownRow
          key={split.user.publicKey}
          split={split}
          index={index}
        />
      ))}
    </Grid>
  );
}
