import {
  MintPriceBreakdown,
  MintPriceStepperBreakdown,
  StepperBreakdown,
} from 'types/MintFee';
import {
  EthersTransactionError,
  TransactionState,
  TransactionStatus,
} from 'types/Transactions';
import {
  TxStatus,
  TxStatusCopy,
  TxStatusCopyOptions,
} from 'types/Transactions';

import { isValueInArray } from './helpers';
import { isZero } from './numbers';

const WALLET_ERROR_MESSAGES = [
  'No Provider Error',
  'MetaMask Tx Signature: User denied transaction signature.',
  'User rejected request',
  'User rejected the transaction',
];

export function isNonUserRejectedError(
  error: Error | EthersTransactionError | null
) {
  if (!error) return false;

  if ('message' in error) {
    return !WALLET_ERROR_MESSAGES.includes(error.message);
  } else if ('error' in error) {
    return !WALLET_ERROR_MESSAGES.includes(error.error.message);
  } else {
    return false;
  }
}

type LineItem = {
  value: bigint;
  label: string;
  percentage?: number;
  recipient?: string;
};

type BalanceBreakdown = {
  totalBalance: LineItem;
  lineItems: LineItem[];
};

export const getBalanceBreakdown = (options: {
  marketplaceBalance: bigint;
  ethBalance: bigint;
}): BalanceBreakdown => {
  const { marketplaceBalance, ethBalance } = options;

  const lineItems: LineItem[] = [
    {
      value: marketplaceBalance,
      label: 'Marketplace balance',
    },
    {
      value: ethBalance,
      label: 'Wallet balance',
    },
  ];
  const totalBalance = {
    label: 'Available balance',
    value: ethBalance + marketplaceBalance,
  };
  return { totalBalance, lineItems };
};

export function getTransactionStatus(
  args: TransactionState
): TransactionStatus {
  if (args.isSuccess) {
    return 'success';
  } else if (args.isConfirming) {
    return 'confirming';
  } else if (args.isAwaiting) {
    return 'awaiting-confirmation';
  } else {
    return 'initial';
  }
}

export function isTransactionSubmitting(status: TransactionStatus) {
  return isValueInArray(status, ['awaiting-confirmation', 'confirming']);
}

export function getExhibitionFeeValue(price: number, percent: number) {
  // we must ÷ 100 as percent is an integer
  return (price * percent) / 100;
}

type GetCopyOptions = {
  // Excluding INDEXING because we want to flatten PENDING and INDEXING into a single state in this context
  status: Exclude<TxStatus, 'INDEXING'>;
  customCopy: Partial<TxStatusCopyOptions>;
  defaultCopy: Omit<TxStatusCopy, 'INDEXING'>;
};

export const getCopy = (options: GetCopyOptions): string | undefined => {
  const statusCopyMap = {
    ...options.defaultCopy,
    ...options.customCopy,
  };

  return statusCopyMap[options.status];
};

/**
 * Options for calculating transaction value using bigint.
 */
type CalculateTransactionValueOptions = {
  transactionValue: bigint;
  marketplaceBalance: bigint;
};

/**
 * Calculate the transaction value based on the given options using bigint.
 *
 * @param {CalculateTransactionValueOptions} options - The options to calculate the transaction value.
 * @returns {bigint} The calculated transaction value.
 */
export const calculateTransactionValue = (
  options: CalculateTransactionValueOptions
): bigint => {
  return options.transactionValue <= options.marketplaceBalance
    ? BigInt(0)
    : options.transactionValue - options.marketplaceBalance;
};

export const getMintPriceBreakdown = (options: {
  count: bigint;
  fee: bigint;
  price: bigint;
}): MintPriceBreakdown => {
  const { count, fee, price } = options;

  const nonZeroMin = isZero(count) ? BigInt(1) : count;

  const mintPrice = price * nonZeroMin;
  const feePrice = fee * nonZeroMin;

  const totalPrice = mintPrice + feePrice;

  return {
    mintPrice,
    feePrice,
    totalPrice,
  };
};

const getStepperBreakdown = (options: {
  count: number;
  fee: bigint;
  price: bigint;
}): StepperBreakdown => {
  const { count, fee, price } = options;

  return {
    count,
    pricing: getMintPriceBreakdown({
      count: BigInt(count),
      fee,
      price,
    }),
  };
};

export const getMintPriceStepperBreakdown = (options: {
  count: number;
  fee: bigint;
  price: bigint;
}): MintPriceStepperBreakdown => {
  const { count, fee, price } = options;

  const currentIncrementCount = count;
  const nextDecrementCount = count - 1;
  const nextIncrementCount = count + 1;

  return {
    current: getStepperBreakdown({
      count: currentIncrementCount,
      fee,
      price,
    }),
    nextDecrement: getStepperBreakdown({
      count: nextDecrementCount,
      fee,
      price,
    }),
    nextIncrement: getStepperBreakdown({
      count: nextIncrementCount,
      fee,
      price,
    }),
  };
};
