import Button from 'components/base/Button';

import ExternalLinkIcon from 'assets/icons/external-link.svg';
import { ChainId } from 'lib/chains';
import { getBlockExplorerByChainId } from 'utils/block-explorer';

interface TransactionHashLinkProps {
  txHash: string;
  href?: string;
  chainId: ChainId;
}

export default function TransactionHashLink(props: TransactionHashLinkProps) {
  const { txHash, href, chainId } = props;

  const explorer = getBlockExplorerByChainId(chainId);

  return (
    <Button
      as="a"
      href={href || explorer.tx.getUrl({ txHash })}
      icon
      rel="noreferrer"
      size={1}
      target="_blank"
      variant="outline"
    >
      <ExternalLinkIcon width={16} height={16} />
      <span>{explorer.tx.copy.viewOnCta}</span>
    </Button>
  );
}
