import { mapTokenCategoryToProductBranding } from 'copy/token';

import { ModerationFrom } from 'types/Moderation';
import { TokenCategory } from 'types/Token';

import DMCANotice from './DMCANotice';
import WarningBlock from './WarningBlock';
import WarningTermsLink from './WarningTermsLink';

type TokenModerationBlockMinimalProps = { category: TokenCategory };

const getEntityName = (category: TokenCategory) => {
  const branding = mapTokenCategoryToProductBranding(category);
  return branding.instance;
};

function TokenSuspendedModerationBlock(
  props: TokenModerationBlockMinimalProps
) {
  const entity = getEntityName(props.category);

  return (
    <WarningBlock
      title={`This ${entity} has been permanently removed.`}
      description={
        <>
          This {entity} was found to be in violation of the Foundation{' '}
          <WarningTermsLink /> and has been permanently removed.
        </>
      }
      status="SUSPENDED"
    />
  );
}

function TokenTakedownModerationBlock(
  props: TokenModerationBlockMinimalProps & {
    moderationFrom: ModerationFrom;
  }
) {
  const entity = getEntityName(props.category);

  return (
    <DMCANotice
      moderationFrom={props.moderationFrom}
      title={`This ${entity} has received a DMCA takedown notice.`}
    />
  );
}

function TokenUnderReviewModerationBlock(
  props: TokenModerationBlockMinimalProps
) {
  const entity = getEntityName(props.category);

  return (
    <WarningBlock
      title={`This ${entity} is under review`}
      description={
        <>
          This {entity} is currently under review by the Foundation team to
          ensure it has not violated the Foundation <WarningTermsLink />.
        </>
      }
      status="UNDER_REVIEW"
    />
  );
}

export const TokenModeration = {
  Suspended: TokenSuspendedModerationBlock,
  TakedownRequested: TokenTakedownModerationBlock,
  UnderReview: TokenUnderReviewModerationBlock,
};
