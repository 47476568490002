import { P, match } from 'ts-pattern';
import { Address } from 'viem';

import { ArtworkEventFragment } from 'gql/hasura/hasura-fragments.generated';
import {
  eventIdSchema,
  provenanceEventSchema,
  saleEventSchema,
} from 'schemas/provenance';

import { UserLight } from 'types/Account';
import { ProvenanceEvent } from 'types/Provenance';

type HasuraEvent = ArtworkEventFragment & {
  user: UserLight;
};

export const mapHasuraEventsToProvenance = (options: {
  saleEvents: ArtworkEventFragment[];
  events: HasuraEvent[];
}): ProvenanceEvent[] => {
  const mergedEvents = mergeEvents(options);

  const dedupedWorldEvents = dedupeAddToWorldEvents(mergedEvents);

  const parsedEvents = dedupedWorldEvents.map((event) =>
    provenanceEventSchema.parse(event)
  );

  return dedupeAuctionSettleEvents(parsedEvents);
};

export const mergeEvents = (options: {
  saleEvents: ArtworkEventFragment[];
  events: HasuraEvent[];
}): HasuraEvent[] => {
  const withMintEvents = mergeMintEventWithSaleEvent(options);
  const withSettleEvents = mergeSellEventWithSettleEvent(withMintEvents);
  return mergeWorldListingEventWithListingEvent(withSettleEvents);
};

export function mergeMintEventWithSaleEvent(options: {
  saleEvents: ArtworkEventFragment[];
  events: HasuraEvent[];
}) {
  if (!options.saleEvents[0]) {
    return options.events;
  }

  const parsedSaleEvent = saleEventSchema.parse(options.saleEvents[0]);

  return options.events.map((event) => {
    if (event.eventType === 'MINT') {
      return {
        ...event,
        data: {
          ...event.data,
          amountInETH: parsedSaleEvent.data.price,
        },
      };
    } else {
      return event;
    }
  });
}

export function mergeWorldListingEventWithListingEvent(
  events: HasuraEvent[]
): HasuraEvent[] {
  return events.reduce((acc, curr, _index, events) => {
    if (curr.eventType !== 'NFT_ADDED_TO_EXHIBITION') {
      return [...acc, curr];
    }

    const addedToExhibitionId = eventIdSchema.parse(curr.id);

    /**
     * Find the next WORLD_LISTING event after the LISTING event
     */
    const auctionListingEvent = events.find((event) => {
      return (
        event.eventType === 'LIST' &&
        addedToExhibitionId === eventIdSchema.parse(event.id)
      );
    });

    if (!auctionListingEvent) {
      return [...acc, curr];
    }

    const mergedEvent: HasuraEvent = {
      ...curr,
      data: {
        ...curr.data,
        ...auctionListingEvent.data,
      },
    };

    return [...acc, mergedEvent];
  }, [] as HasuraEvent[]);
}

export function mergeSellEventWithSettleEvent(
  events: HasuraEvent[]
): HasuraEvent[] {
  return events.reduce((acc, curr, index, events) => {
    if (curr.eventType !== 'SETTLE') {
      return [...acc, curr];
    }

    const settleEventIndex = index;

    /**
     * Find the next SELL event after the SETTLE event
     */
    const sellEvent = events.find((event, sellEventIndex) => {
      return event.eventType === 'SELL' && sellEventIndex > settleEventIndex;
    });

    if (!sellEvent) {
      return [...acc, curr];
    }

    const mergedEvent: HasuraEvent = {
      ...curr,
      user: sellEvent.user,
      publicKey: sellEvent.publicKey,
      data: {
        ...curr.data,
        amountInETH: sellEvent.data.amountInETH,
      },
    };

    return [...acc, mergedEvent];
  }, [] as HasuraEvent[]);
}

export function dedupeAddToWorldEvents(events: HasuraEvent[]) {
  return events.filter((event, _index, events) => {
    if (event.eventType === 'LIST') {
      /**
       * If there is an AUCTION_ENDED event, remove any AUCTION_FINALIZED events
       */
      return !events.some((ev) => {
        return (
          ev.eventType === 'NFT_ADDED_TO_EXHIBITION' &&
          eventIdSchema.parse(event.id) === eventIdSchema.parse(ev.id)
        );
      });
    }
    return true;
  });
}

export function dedupeAuctionSettleEvents(events: ProvenanceEvent[]) {
  return events.filter((event, _index, events) => {
    if (event.type === 'AUCTION_ENDED') {
      /**
       * If there is an AUCTION_ENDED event, remove any AUCTION_FINALIZED events
       */
      return !events.some((ev) => {
        return (
          ev.type === 'AUCTION_FINALIZED' && ev.dateEnding === event.dateEnding
        );
      });
    }
    return true;
  });
}

export function getAnonUsersFromProvenance(
  events: ProvenanceEvent[]
): Address[] {
  const userMap = events.reduce(
    (acc, event) => {
      return {
        ...acc,
        [event.user.publicKey]: event.user,
      };
    },
    {} as Record<Address, UserLight>
  );

  const unknownUsers = events.reduce((acc, event) => {
    const userPublicKeys = match(event)
      .with(
        {
          settledBy: P.not(P.nullish),
        },
        (ev) => [ev.settledBy.publicKey]
      )
      .with(
        {
          fromUser: P.not(P.nullish),
          toUser: P.not(P.nullish),
        },
        (ev) => [ev.fromUser.publicKey, ev.toUser.publicKey]
      )
      .otherwise(() => []);

    return [...userPublicKeys.filter((user) => !userMap[user]), ...acc];
  }, [] as Address[]);

  return unknownUsers;
}

export function mergeEnrichedUsersIntoProvenance(options: {
  events: ProvenanceEvent[];
  users: Record<Address, UserLight>;
}): ProvenanceEvent[] {
  const userCache = options.users;

  return options.events.map((event) => {
    return match(event)
      .with(
        {
          fromUser: P.not(P.nullish),
          toUser: P.not(P.nullish),
        },
        (ev) => {
          return {
            ...ev,
            fromUser: userCache[ev.fromUser.publicKey] || ev.fromUser,
            toUser: userCache[ev.toUser.publicKey] || ev.toUser,
          };
        }
      )
      .with(
        {
          settledBy: P.not(P.nullish),
        },
        (ev) => {
          return {
            ...ev,
            settledBy: userCache[ev.settledBy.publicKey] || ev.settledBy,
          };
        }
      )
      .otherwise((ev) => ev);
  });
}
