import type { Address } from 'viem';
import { useReadContract } from 'wagmi';

import { useQueryEffects } from 'hooks/react-query';
import { NFTCollection } from 'lib/abis/NFTCollection';
import { getContractAddress } from 'lib/addresses';
import { ChainId } from 'lib/chains';

type Options = {
  enabled: boolean;
  onSuccess?: (data: boolean) => void;
};

export default function useHasApproval(
  variables: {
    contractAddress: Address;
    publicKey: Address;
    chainId: ChainId;
  },
  options: Options
) {
  const isApprovedForAllQuery = useReadContract({
    abi: NFTCollection,
    address: variables.contractAddress,
    functionName: 'isApprovedForAll',
    args: [
      variables.publicKey,
      getContractAddress({
        chainId: variables.chainId,
        contractName: 'nftMarket',
      }),
    ],
    chainId: variables.chainId,
    query: {
      enabled: options.enabled,
    },
  });

  useQueryEffects(isApprovedForAllQuery, {
    onSuccess(data) {
      if (options.onSuccess) {
        options.onSuccess(data);
      }
    },
  });

  return isApprovedForAllQuery;
}
