import { useQuery } from '@tanstack/react-query';

import * as Query from 'gql/hasura/queries/nft-provenance.generated';
import { PROVENANCE_EVENT_TYPES } from 'schemas/event';
import {
  getAnonUsersFromProvenance,
  mapHasuraEventsToProvenance,
  mergeEnrichedUsersIntoProvenance,
} from 'utils/provenance';

import { ProvenanceEvent } from 'types/Provenance';

import {
  buildUserCache,
  getUsersByPublicKeys,
} from './queries/hasura/users/use-users-by-public-keys-v2';

async function getProvenance(variables: {
  contractAddress: string;
  tokenId: number;
}): Promise<ProvenanceEvent[]> {
  const getNftProvenance = Query.useNftProvenance.fetcher({
    contractAddress: variables.contractAddress,
    tokenId: variables.tokenId,
    eventTypes: [...PROVENANCE_EVENT_TYPES],
  });

  const res = await getNftProvenance();

  const mappedEvents = mapHasuraEventsToProvenance({
    saleEvents: res.saleEvents,
    events: res.events,
  });

  /**
   * Get all the anon public keys from the provenance events
   */
  const anonUserPublicKeys = getAnonUsersFromProvenance(mappedEvents);

  const users = await getUsersByPublicKeys(anonUserPublicKeys);

  const userCache = buildUserCache([
    ...users,
    ...mappedEvents.map((event) => event.user),
  ]);

  /**
   * Merge the enriched users into the provenance events
   */
  return mergeEnrichedUsersIntoProvenance({
    events: mappedEvents,
    users: userCache,
  });
}

export function useNftProvenance(
  variables: {
    contractAddress: string;
    tokenId: number;
  },
  options: {
    enabled: boolean;
  }
) {
  return useQuery({
    enabled: options.enabled,
    queryKey: ['nft', 'provenance', variables],
    queryFn: () => getProvenance(variables),
  });
}
