import { ApiAuction } from 'gql/api/types-api.generated';
import { AuctionMarketType } from 'utils/markets/markets';

export const activeAuctionStateToAuctionMarketMap: Record<
  ApiAuction['state'],
  AuctionMarketType
> = {
  LIVE: 'LIVE_AUCTION',
  ENDED: 'ENDED_AUCTION',
  OPEN: 'LISTED_AUCTION',
  FINALIZED: 'FINALIZED_AUCTION',
};
