import { darkMode } from '@f8n-frontend/stitches';
import { minutesToSeconds } from 'date-fns';
import {
  GetStaticPaths,
  GetStaticPropsContext,
  InferGetStaticPropsType,
} from 'next';
import { P, match } from 'ts-pattern';
import { z } from 'zod';

import FarcasterHead, { FarcasterHeadNft } from 'components/FarcasterHead';
import PageHead from 'components/PageHead';
import ModerationBanner, {
  ModeratedCopyMap,
} from 'components/admin/ModerationBanner';
import Body from 'components/base/Body';
import Box from 'components/base/Box';
import Divider from 'components/base/Divider';
import Footer from 'components/footers/Footer';
import Header from 'components/headers/Header';
import { TokenLayout } from 'components/layouts/TokenLayout';
import AcceptOfferModal from 'components/modals/AcceptOfferModal';
import AuctionBiddersModal from 'components/modals/AuctionBiddersModal';
import BridgeModal from 'components/modals/BridgeModal';
import BurnNftModal from 'components/modals/BurnNftModal';
import ReportModal from 'components/modals/ReportModal';
import SettleAuctionModal from 'components/modals/SettleAuctionModal';
import ShareNftModal from 'components/modals/ShareNftModal';
import { SplitsModal } from 'components/modals/SplitsModal';
import TransferNftModal from 'components/modals/TransferNftModal';
import { getFacetValues } from 'components/modals/v2/CollectionAttributeModal';
import TokenPageMarketWidgetNftConnected from 'components/token-page/TokenPageMarketWidgetNftConnected';
import { TokenModerationBlock } from 'components/trust-safety/ModerationBlock';
import { hasPublicKey, isAdmin, isMyAddress } from 'contexts/auth/helpers';
import useAuth from 'contexts/auth/useAuth';
import { mapTokenCategoryToProductBranding } from 'copy/token';

import { useTokenPage } from 'gql/api/queries/token-page.generated';
import { useUserFollowState } from 'gql/api/queries/user-follow-state.generated';
import { getAlgoliaSearchResults } from 'hooks/queries/algolia/shared';
import useModal from 'hooks/use-modal';
import { useNftProvenance } from 'hooks/use-nft-provenance';
import { NOT_FOUND } from 'lib/constants';
import { tokenIdSchema } from 'schemas/forms/generic';
import { addressValueSchema } from 'schemas/shared';
import { chainSlugSchema } from 'schemas/url/chain';
import { getAttributeRarity } from 'utils/attributes';
import { truncateMetaDescription } from 'utils/helpers';
import { getTokenFilter } from 'utils/inputs';
import {
  mapApiMediaToAssetMedium,
  mapApiMediaToFullscreenMintMedia,
  mapApiMediaToMintMedia,
} from 'utils/media';
import { isFlaggedForModeration } from 'utils/moderation';
import { getChainConfigById, getChainConfigBySlug } from 'utils/network';
import { getPath } from 'utils/router';
import { serverQuery } from 'utils/server';

import { AlgoliaArtwork } from 'types/Algolia';
import { CuratedStore } from 'types/CuratedStore';
import { ModerationFrom } from 'types/Moderation';
import { NftAttribute } from 'types/Nft';
import { TokenCategory, TokenFilter } from 'types/Token';
import { ExtractQueryDataByTypename } from 'types/gql';

type TokenPageInitialProps = InferGetStaticPropsType<typeof getStaticProps>;
type TokenData = TokenPageInitialProps['pageData']['token'];

export default function TokenPage(initialProps: TokenPageInitialProps) {
  const tokenFilter = getTokenFilter(initialProps.pageData.token);

  const pageQuery = useTokenPage(
    { tokenFilter },
    { initialData: initialProps.pageData }
  );

  const moment =
    pageQuery.isSuccess && pageQuery.data.moment
      ? pageQuery.data.moment
      : initialProps.pageData.moment;

  const tokenData =
    pageQuery.isSuccess && pageQuery.data.token
      ? pageQuery.data.token
      : initialProps.pageData.token;

  const world =
    pageQuery.isSuccess && pageQuery.data.world
      ? pageQuery.data.world
      : initialProps.pageData.world;

  const splits = pageQuery.isSuccess
    ? pageQuery.data.splits
    : initialProps.pageData.splits;

  // This page only supports 721s, so we can return null if the token is not an NFT
  if (tokenData.__typename !== 'MarketNft') {
    return null;
  }

  return (
    <TokenPageConnected
      token={tokenData}
      attributes={initialProps.attributes}
      moment={moment}
      splits={splits}
      tokenFilter={tokenFilter}
      world={world}
    />
  );
}

type TokenPageConnectedProps = {
  attributes: NftAttribute[];
  moment: TokenPageInitialProps['pageData']['moment'];
  tokenFilter: TokenFilter;
  world: TokenPageInitialProps['pageData']['world'];
  splits: TokenPageInitialProps['pageData']['splits'];
  token: ExtractQueryDataByTypename<TokenData, 'MarketNft'>;
};

function TokenPageConnected(props: TokenPageConnectedProps) {
  const { attributes, moment, tokenFilter, token, world, splits } = props;
  const { moderationFrom, moderationStatus } = token;

  const auth = useAuth();
  const modal = useModal();

  const userFollowQuery = useUserFollowState({
    publicKey: token.creator.publicKey,
  });

  const nftProvenanceQuery = useNftProvenance(
    { contractAddress: token.contractAddress, tokenId: token.tokenId },
    { enabled: true }
  );

  const creatorFollowersCount = match(userFollowQuery.data)
    .with(
      { userFollowState: { followerCount: P.number } },
      (data) => data.userFollowState.followerCount
    )
    .otherwise(() => null);

  const apiMedia = token.media;
  const chainConfig = getChainConfigById(token.chainId);

  const media = mapApiMediaToMintMedia(apiMedia);
  const mediaFullscreen = mapApiMediaToFullscreenMintMedia(apiMedia);
  const mediaAssetMedium = mapApiMediaToAssetMedium(apiMedia);

  const metaTitle = token.name;
  const metaDescription = token.description
    ? truncateMetaDescription(token.description)
    : null;

  const store = match({ moment, world })
    .with({ moment: P.not(null) }, ({ moment }): CuratedStore => {
      return { type: 'MOMENT', moment };
    })
    .with({ world: P.not(null) }, ({ world }): CuratedStore => {
      return { type: 'WORLD', world };
    })
    .otherwise(() => null);

  const collection = {
    ...token.collection,
    chainId: token.chainId,
  };

  const isTokenModerated = isFlaggedForModeration(moderationStatus);
  const isCurrentUserAdmin = isAdmin(auth);
  const isCurrentUserCreator = isMyAddress(auth, token.creator.publicKey);
  const isCurrentUserAdminOrCreator =
    isCurrentUserAdmin || isCurrentUserCreator;
  const hasFullScreenModerationBlock =
    isTokenModerated && !isCurrentUserAdminOrCreator;

  const meta = (
    <PageHead
      description={metaDescription}
      title={metaTitle}
      // set to null to allow dynamic frame OG to take priority (set in FarcasterHeadNft)
      image={null}
    />
  );

  const header = <Header absolute={false} mode="LIGHT" type="MAXIMAL" />;

  if (hasFullScreenModerationBlock) {
    return (
      <>
        {meta}
        {header}
        <TokenModerationBlock
          moderationFrom={moderationFrom}
          moderationStatus={moderationStatus}
          category="nft"
        />
      </>
    );
  }

  return (
    <>
      {/* Modals */}
      <AcceptOfferModal />
      <AuctionBiddersModal />
      <BridgeModal />
      <BurnNftModal />
      <SettleAuctionModal />
      <ShareNftModal />
      <SplitsModal />
      <TransferNftModal />
      <ReportModal
        publicKey={hasPublicKey(auth) ? auth.publicKey : ''}
        reportedPublicKey={token.collection.contractAddress}
        pageType="Collection"
      />
      <>
        <FarcasterHeadNft nft={token} />
        <FarcasterHead
          collectionName={token.collection.name}
          contractAddress={token.contractAddress}
          creatorAddress={token.creator.publicKey}
        />
      </>
      <PageHead
        description={metaDescription}
        title={metaTitle}
        // set to null to allow dynamic frame OG to take priority (set in FarcasterHeadNft)
        image={null}
      />

      {/* Moderation */}
      {isTokenModerated && isCurrentUserAdminOrCreator && (
        <ModerationBanner
          status={moderationStatus}
          messages={getModeratedCopyMap({
            isCurrentUserCreator,
            moderationFrom,
            category: 'nft',
          })}
        />
      )}

      <Header absolute={false} mode="LIGHT" type="MAXIMAL" />
      <Divider />
      <Body>
        <TokenLayout
          attributes={attributes}
          provenanceEvents={
            nftProvenanceQuery.isSuccess ? nftProvenanceQuery.data : []
          }
          chainConfig={chainConfig}
          collection={collection}
          creator={token.creator}
          creatorFollowersCount={creatorFollowersCount}
          description={token.description}
          market={{
            activeAuction: token.activeAuction,
            activeBuyNow: token.activeBuyNow,
            activeOffer: token.activeOffer,
          }}
          media={media}
          mediaFullscreen={mediaFullscreen}
          mediaAssetMedium={mediaAssetMedium}
          metadataUrl={token.metadataUrl}
          checkoutWidget={
            <TokenPageMarketWidgetNftConnected
              media={media}
              nft={token}
              tokenFilter={tokenFilter}
              world={world}
            />
          }
          name={token.name}
          onShare={() => {
            modal.setModal({
              type: 'SHARE_NFT',
              creator: token.creator,
              nft: tokenFilter,
            });
          }}
          onSplitsClick={() => {
            modal.setModal({
              type: 'SPLITS',
              chainId: token.chainId,
              splits,
            });
          }}
          owner={token.owner}
          sourceUrl={token.sourceUrl}
          splits={splits}
          store={store}
          tokenId={token.tokenId}
        />
      </Body>

      <Box className={darkMode} css={{ backgroundColor: '$black100' }}>
        <Footer footerMode="DARK" type="MAXIMAL" />
      </Box>
    </>
  );
}

const getModeratedCopyMap = (options: {
  category: TokenCategory;
  isCurrentUserCreator: boolean;
  moderationFrom: ModerationFrom;
}): ModeratedCopyMap => {
  const { category, isCurrentUserCreator, moderationFrom } = options;

  const categoryBranding = mapTokenCategoryToProductBranding(category);
  const entity = categoryBranding.singular;
  const prefix = isCurrentUserCreator ? 'Your' : 'This';

  const SUSPENDED = `${prefix} ${entity} has been removed` as const;
  const UNDER_REVIEW = `${prefix} ${entity} is under review` as const;
  const TAKEDOWN_REQUESTED =
    `${prefix} ${entity} has received a DMCA takedown notice from ${moderationFrom}` as const;

  return {
    SUSPENDED,
    UNDER_REVIEW,
    TAKEDOWN_REQUESTED,
  } as const;
};

const tokenPageParamsSchema = z.object({
  chain: chainSlugSchema,
  contractAddress: addressValueSchema,
  tokenId: tokenIdSchema,
});

export const getStaticProps = async (context: GetStaticPropsContext) => {
  const params = tokenPageParamsSchema.safeParse(context.params);

  if (!params.success) {
    return NOT_FOUND;
  }

  const { chain: chainSlug, contractAddress, tokenId } = params.data;

  const chainConfig = getChainConfigBySlug(chainSlug);
  const chainId = chainConfig.chainId;

  const tokenFilter: TokenFilter = {
    chainId,
    contractAddress,
    tokenId,
  };

  const getPage = useTokenPage.fetcher({ tokenFilter });

  const [pageQuery, tokenAttributesQuery, collectionAttributesQuery] =
    await Promise.all([
      // Api data
      serverQuery(getPage),

      // Algolia data
      getAlgoliaSearchResults<AlgoliaArtwork>({
        index: 'artworks_sort_default',
        query: '',
        options: {
          hitsPerPage: 0,
          facets: ['attributes.*'],
          filters: `tokenId = ${tokenFilter.tokenId}`,
          facetFilters: [
            `collection.contractAddress:${tokenFilter.contractAddress}`,
          ],
        },
      }),
      getAlgoliaSearchResults<AlgoliaArtwork>({
        index: 'artworks_sort_default',
        query: '',
        options: {
          hitsPerPage: 0,
          facets: ['attributes.*'],
          facetFilters: [
            `collection.contractAddress:${tokenFilter.contractAddress}`,
          ],
        },
      }),
    ]);

  // Re-throw when page query fails
  if (pageQuery.error) {
    throw pageQuery.error;
  }

  if (!tokenAttributesQuery) {
    throw new Error('No attributes found');
  }

  if (!collectionAttributesQuery) {
    throw new Error('No collection attributes found');
  }

  // 404 when token not found
  if (!pageQuery.data.token) {
    return NOT_FOUND;
  }

  const collectionNftsCount = collectionAttributesQuery.nbHits;
  const collectionFacets = getFacetValues(collectionAttributesQuery);
  const tokenFacets = getFacetValues(tokenAttributesQuery);

  const collectionAttributes = collectionFacets.flatMap(
    (facet) => facet.attributes
  );

  const initialAttributesWithRarity: NftAttribute[] = [];

  const attributesWithRarity = tokenFacets.reduce(
    (prev, curr): NftAttribute[] => {
      const tokenAttribute = curr.attributes[0];

      if (!tokenAttribute) {
        return prev;
      }

      const collectionAttribute = collectionAttributes.find(
        (attr) => attr.value == tokenAttribute.value
      );

      if (!collectionAttribute) {
        return prev;
      }

      const attributeCount = collectionAttribute.count;

      return [
        ...prev,
        {
          category: curr.category,
          deepLinkUrl: getPath.mint.filterByAttribute({
            collection: {
              chainId,
              contractAddress,
            },
            attributeValue: tokenAttribute.value,
          }),
          label: tokenAttribute.label,
          count: attributeCount,
          rarity: getAttributeRarity({
            count: attributeCount,
            total: collectionNftsCount,
          }),
        },
      ];
    },
    initialAttributesWithRarity
  );

  return {
    props: {
      attributes: attributesWithRarity,
      pageData: {
        moment: pageQuery.data.moment,
        token: pageQuery.data.token,
        world: pageQuery.data.world,
        splits: pageQuery.data.splits,
      },
    },
    revalidate: minutesToSeconds(60),
  };
};

export const getStaticPaths: GetStaticPaths = () => {
  return {
    paths: [],
    fallback: 'blocking',
  };
};
