import { match } from 'ts-pattern';

import { TokenCategory } from 'types/Token';

import { PRODUCT_BRANDING } from './branding';

export const mapTokenCategoryToProductBranding = (category: TokenCategory) => {
  return match(category)
    .with('editionToken', () => PRODUCT_BRANDING.editionToken)
    .with('nft', () => PRODUCT_BRANDING.nft)
    .exhaustive();
};

export const OWNER_TABLE_COPY = {
  EMPTY_STATE: 'Be the first to mint this artwork',
};
