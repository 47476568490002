import { ContractErrorsBySignature } from '@f8n/f8n-contracts';
import { match, P } from 'ts-pattern';
import { UseSimulateContractReturnType } from 'wagmi';
import { z } from 'zod';

import {
  INSUFFICIENT_FUNDS_ERROR,
  INSUFFICIENT_FUNDS_MESSAGE,
  revertCopyMap,
} from 'copy/errors';

import { RevertName, RevertReason, RevertSignature } from 'types/Revert';
import { UnsafeAny } from 'types/utils';

export const hasEnrichedRevert = (
  error: RevertReason | null
): error is Extract<RevertReason, { type: 'ENRICHED' }> => {
  return error !== null && error.type === 'ENRICHED';
};

export const hasPlainRevert = (
  error: RevertReason | null
): error is Extract<RevertReason, { type: 'PLAIN' }> => {
  return error !== null && error.type === 'PLAIN';
};

function hasZodError(
  errors: z.ZodIssue[]
): errors is [z.ZodIssue, ...z.ZodIssue[]] {
  return Boolean(errors[0] && errors[0].message);
}

export function extractErrorMessage({
  simulateError,
  parsedZodSchema,
}: {
  simulateError: UseSimulateContractReturnType['error'];
  /**
   * okay to use UnsafeAny here as we’re not concerned with specifics
   * about the individual fields in the schema, just the error string
   */
  parsedZodSchema: z.SafeParseReturnType<UnsafeAny, UnsafeAny>;
}): string | null {
  if (
    !parsedZodSchema.success &&
    'error' in parsedZodSchema &&
    hasZodError(parsedZodSchema.error.errors)
  ) {
    return parsedZodSchema.error.errors[0].message;
  }

  return extractPrepareContractWriteRevertReason({
    error: simulateError,
  });
}

export function extractSimulateRevert(
  prepareContractWrite: Pick<UseSimulateContractReturnType, 'error'>
) {
  if (!prepareContractWrite.error) {
    return null;
  }

  return (
    match(prepareContractWrite.error)
      /**
       * insufficient funds error is returned as a generic
       * ContractFunctionExecutionError so we therefore
       * need to match exactly on the error string itself
       */
      .with(
        {
          shortMessage: INSUFFICIENT_FUNDS_MESSAGE,
        },
        () => ({
          type: 'ENRICHED',
          ...INSUFFICIENT_FUNDS_ERROR,
        })
      )
      .with(
        {
          cause: {
            data: {
              errorName: P.string,
            },
          },
        },
        (error) => {
          const parsedError = RevertName.safeParse(error.cause.data.errorName);

          if (parsedError.success) {
            const enrichedError = revertCopyMap[parsedError.data];

            if (enrichedError) {
              return {
                type: 'ENRICHED',
                reason: enrichedError.reason || error.cause.data.errorName,
                description: enrichedError.description || '',
              };
            }
          }

          return {
            type: 'PLAIN',
            reason: error.cause.data.errorName,
          };
        }
      )
      .with(
        {
          cause: {
            signature: P.string,
          },
        },
        (error) => {
          const errorSignature = RevertSignature.safeParse(
            error.cause.signature
          );

          if (errorSignature.success) {
            const errorName = ContractErrorsBySignature[errorSignature.data];

            const enrichedError = revertCopyMap[errorName.name];
            return {
              type: 'ENRICHED',
              reason: enrichedError
                ? enrichedError.reason
                : error.cause.signature,
              description: enrichedError ? enrichedError.description : '',
            };
          }

          if ('shortMessage' in error) {
            return {
              type: 'PLAIN',
              reason: error.shortMessage,
            };
          }

          return {
            type: 'PLAIN',
            reason: error.cause.signature,
          };
        }
      )
      .with(
        {
          shortMessage: P.string,
        },
        (err) => ({
          type: 'PLAIN',
          reason: err.shortMessage,
        })
      )
      .otherwise(() => null)
  );
}

// TODO: rename as extractSimulated*
export function extractPrepareContractWriteRevertReason(
  prepareContractWrite: Pick<UseSimulateContractReturnType, 'error'>
): string | null {
  const enrichedRevert = extractSimulateRevert(prepareContractWrite);
  return enrichedRevert ? enrichedRevert.reason : null;
}
