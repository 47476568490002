import { absoluteUrl } from './seo';

/**
 * Instructions for setting up local development with Farcaster:
 * 1. Install ngrok: `brew install ngrok/ngrok/ngrok`
 * 2. Add auth token: `ngrok config add-authtoken NGROK_AUTH_TOKEN` (from 1Pass)
 * 3. Start tunnel: `ngrok http 3000 --domain fnd-frames.ngrok.app`
 * 4. Preview frame: Paste https://fnd-frames.ngrok.app/${relevantPath} into https://warpcast.com/~/developers/frames
 */
const LOCAL_DEV_FRAME_TESTING_PROXY = 'https://fnd-frames.ngrok.app';
const SHOULD_USE_LOCAL_PROXY = false; // set to true to enable testing on Farcaster from localhost

/**
 * required for local development
 */
export const buildFarcasterUrl = (pathname: string) => {
  if (SHOULD_USE_LOCAL_PROXY) {
    const url = new URL(LOCAL_DEV_FRAME_TESTING_PROXY);
    url.pathname = pathname;
    return url.href;
  } else {
    return absoluteUrl(pathname);
  }
};

/**
 * Note: For now we're assuming that Farcaster === Warpcast
 *
 * @see https://docs.farcaster.xyz/reference/warpcast/cast-composer-intents
 */
export const getShareOnFarcasterUrl = (options: {
  text: string;
  url: string;
}) => {
  const url = new URL('https://warpcast.com/~/compose');
  url.searchParams.append('text', options.text);
  url.searchParams.append('embeds[]', options.url);

  return url.toString();
};
