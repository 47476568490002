import { css } from '@stitches/react';
import { useRef } from 'react';

import NftMedia from 'components/NftMedia';
import MarkdownWithReadMoreModal from 'components/base/MarkdownWithReadMoreModal';
import { ReportMenuItem } from 'components/menu-items/ReportDropdownMenuItem';
import Model from 'components/model-media/Model';

import { ApiSplitFragment } from 'gql/api/api-fragments.generated';
import { ChainConfig } from 'lib/chains';
import { getBlockExplorerByChainId } from 'utils/block-explorer';

import { UserLight } from 'types/Account';
import { CollectionLight, ContractType } from 'types/Collection';
import { CuratedStore } from 'types/CuratedStore';
import { MarketNft, NftAttribute, NftFilter } from 'types/Nft';
import { ProvenanceEvent } from 'types/Provenance';
import { MediaAsset, ModelMediaAsset, PreviewMediaAsset } from 'types/media';

import { MintLayout, useMediaContainerSize } from './MintLayout';
import {
  MintLayoutFullscreenImageMedia,
  useFullscreenMedia,
} from './MintLayoutFullscreenImageMedia';
import { MintLayoutTable } from './MintLayoutTable';

type ERC721SecondaryMarket = Pick<
  MarketNft,
  'activeAuction' | 'activeBuyNow' | 'activeOffer'
>;

type TokenLayoutProps = {
  attributes: NftAttribute[];
  chainConfig: ChainConfig;
  collection: CollectionLight;
  creator: UserLight;
  creatorFollowersCount: number | null;
  description: string | null;
  name: string | null;
  market: ERC721SecondaryMarket;
  media: MediaAsset | null;
  mediaAssetMedium: string | null;
  mediaFullscreen: PreviewMediaAsset | null;
  metadataUrl: string | null;
  checkoutWidget: JSX.Element;
  onShare: () => void;
  onSplitsClick: () => void;
  owner: UserLight | null;
  provenanceEvents: ProvenanceEvent[];
  sourceUrl: string | null;
  splits: ApiSplitFragment[];
  store: CuratedStore | null;
  tokenId: number;
};

export function TokenLayout(props: TokenLayoutProps) {
  const {
    attributes,
    chainConfig,
    collection,
    creator,
    creatorFollowersCount,
    description,
    media,
    mediaAssetMedium,
    mediaFullscreen,
    metadataUrl,
    checkoutWidget,
    name,
    onShare,
    onSplitsClick,
    owner,
    provenanceEvents,
    sourceUrl,
    splits,
    store,
    tokenId,
  } = props;

  const { chainId } = chainConfig;

  const hasAttributes = attributes.length > 0;

  const blockExplorer = getBlockExplorerByChainId(chainId);
  const blockExplorerHref = blockExplorer.nft.getUrl({
    contractAddress: collection.contractAddress,
    tokenId,
  });

  const { isFullscreen, toggleFullscreen } = useFullscreenMedia();

  const nftFilter = {
    chainId,
    contractAddress: collection.contractAddress,
    tokenId,
  };

  // TODO: disable for Highlight HTML
  const isFullscreenSupported = media ? media.type !== 'model' : false;

  return (
    <>
      {isFullscreenSupported && mediaFullscreen && (
        <MintLayoutFullscreenImageMedia
          media={mediaFullscreen}
          isFullscreen={isFullscreen}
          toggleFullscreen={toggleFullscreen}
        />
      )}
      <MintLayout.Root>
        <MintLayout.Body>
          <NftMediaRenderer
            contractType={collection.contractType}
            nft={nftFilter}
            media={media}
            onMediaClick={toggleFullscreen}
          />
        </MintLayout.Body>
        <MintLayout.Sidebar>
          <MintLayout.SidebarStack css={{ gap: '$6' }}>
            <MintLayout.SidebarHeader
              creator={creator}
              name={name ?? ''}
              type="TOKEN"
            />
            {checkoutWidget}
          </MintLayout.SidebarStack>
          {description && (
            <MintLayout.SidebarSection heading="Description">
              <MarkdownWithReadMoreModal
                user={creator}
                size="small"
                align="left"
                title={name}
              >
                {description}
              </MarkdownWithReadMoreModal>
            </MintLayout.SidebarSection>
          )}
          <MintLayout.SidebarTabs.Root defaultValue="details">
            <MintLayout.SidebarTabs.List>
              <MintLayout.SidebarTabs.Trigger value="details">
                Details
              </MintLayout.SidebarTabs.Trigger>
              {hasAttributes && (
                <MintLayout.SidebarTabs.Trigger value="attributes">
                  Attributes
                </MintLayout.SidebarTabs.Trigger>
              )}
              <MintLayout.SidebarTabs.Trigger value="activity">
                Activity
              </MintLayout.SidebarTabs.Trigger>
            </MintLayout.SidebarTabs.List>
            <MintLayout.SidebarTabs.Content value="details">
              <MintLayoutTable.Details
                blockExplorerHref={blockExplorerHref}
                collection={collection}
                chainConfig={chainConfig}
                contractAddress={collection.contractAddress}
                contractType={collection.contractType}
                medium={mediaAssetMedium}
                metadataUrl={metadataUrl}
                owner={owner}
                sourceUrl={sourceUrl}
                splits={splits.map((split) => split.user)}
                onSplitsClick={onSplitsClick}
                tokenId={tokenId}
                tokenCategory="nft"
              />
            </MintLayout.SidebarTabs.Content>
            <MintLayout.SidebarTabs.Content value="attributes">
              <MintLayoutTable.Attributes attributes={attributes} />
            </MintLayout.SidebarTabs.Content>
            <MintLayout.SidebarTabs.Content value="activity">
              <MintLayoutTable.Activity
                provenanceEvents={provenanceEvents}
                chainId={chainConfig.chainId}
              />
            </MintLayout.SidebarTabs.Content>
          </MintLayout.SidebarTabs.Root>
          <MintLayout.SidebarStoreSection store={store} />
          <MintLayout.SidebarSection heading="Artist">
            <MintLayout.SidebarArtist
              user={creator}
              userFollowersCount={creatorFollowersCount}
            />
          </MintLayout.SidebarSection>
          <MintLayout.SidebarSection>
            <MintLayout.SidebarActions
              menuItems={
                <>
                  <ReportMenuItem />
                </>
              }
              isReferralRewardsSupported={false}
              onShare={onShare}
            />
          </MintLayout.SidebarSection>
        </MintLayout.Sidebar>
      </MintLayout.Root>
    </>
  );
}

function NftMediaRenderer(props: {
  contractType: ContractType;
  media: MediaAsset | null;
  nft: NftFilter;
  onMediaClick: () => void;
}) {
  const { contractType, media, onMediaClick } = props;

  if (!media) return;

  if (media.type === 'model') {
    return <NftMediaModelAsset media={media} />;
  }

  if (contractType === 'HIGHLIGHT_GENERATIVE_SERIES') {
    // TODO: update to render highlight HTML
    return <NftMediaAsset media={media} onMediaClick={onMediaClick} />;
  }

  return <NftMediaAsset media={media} onMediaClick={onMediaClick} />;
}

const modelStyles = css({
  width: '100%',
  height: '100%',
})();

function NftMediaModelAsset(props: { media: ModelMediaAsset }) {
  const { media } = props;

  return (
    <MintLayout.MediaContainer>
      <Model src={media.src} className={modelStyles} />
    </MintLayout.MediaContainer>
  );
}

function NftMediaAsset(props: {
  media: PreviewMediaAsset;
  onMediaClick: () => void;
}) {
  const { media, onMediaClick } = props;

  const mediaRef = useRef(null);
  const mediaContainerSize = useMediaContainerSize(mediaRef);

  return (
    <MintLayout.MediaContainer ref={mediaRef}>
      <NftMedia
        containerAspectRatio={mediaContainerSize.aspectRatio}
        onClick={onMediaClick}
        media={media}
        variant="flat"
      />
    </MintLayout.MediaContainer>
  );
}
