import { CloseIcon } from '@f8n/icons';
import { styled } from '@f8n-frontend/stitches';
import { AnimatePresence, motion } from 'framer-motion';
import { useHotkeys } from 'react-hotkeys-hook';
import { useLockBodyScroll } from 'react-use';
import { useToggle } from 'usehooks-ts';

import NftMedia from 'components/NftMedia';
import Pin from 'components/Pin';
import Button from 'components/base/Button';

import { PreviewMediaAsset } from 'types/media';

import { MintLayout, useScreenSize } from './MintLayout';

export function useFullscreenMedia() {
  const [isFullscreen, toggleFullscreen, setFullscreen] = useToggle(false);

  useLockBodyScroll(isFullscreen);

  useHotkeys(
    'Esc',
    () => {
      setFullscreen(false);
    },
    { enabled: isFullscreen }
  );

  return {
    isFullscreen,
    toggleFullscreen,
  };
}

type FullscreenConfig = ReturnType<typeof useFullscreenMedia>;

export function MintLayoutFullscreenImageMedia(
  props: Pick<FullscreenConfig, 'isFullscreen' | 'toggleFullscreen'> & {
    media: PreviewMediaAsset;
  }
) {
  const { media, isFullscreen, toggleFullscreen } = props;

  const windowSize = useScreenSize();

  return (
    <AnimatePresence>
      {isFullscreen && (
        <MintLayout.MediaContainer
          as={motion.div}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          initial={{ opacity: 0 }}
          transition={{ duration: 0.3, ease: [0.23, 1, 0.32, 1] }}
          variant="fullscreen"
          onClick={toggleFullscreen}
        >
          <Pin.TopRight css={{ right: '$2', top: '$2' }}>
            <Button
              icon="standalone"
              variant="subtle"
              onClick={(e) => {
                e.stopPropagation();
                toggleFullscreen();
              }}
              size={0}
            >
              <CloseIcon />
            </Button>
          </Pin.TopRight>
          <FullScreenMediaContainer
            as={motion.div}
            animate={{ scale: 1 }}
            initial={{ scale: 0.8 }}
            transition={{ duration: 0.8, ease: [0.23, 1, 0.32, 1] }}
          >
            <NftMedia
              containerAspectRatio={windowSize.aspectRatio}
              media={media}
              variant="flat"
            />
          </FullScreenMediaContainer>
        </MintLayout.MediaContainer>
      )}
    </AnimatePresence>
  );
}

const FullScreenMediaContainer = styled('div', {
  display: 'flex',
  justifyContent: 'center',
  width: '100%',
  height: '100%',
});
