import { Address } from 'viem';
import { useSimulateContract } from 'wagmi';

import { NFTCollection } from 'lib/abis/NFTCollection';
import { getContractAddress } from 'lib/addresses';
import { ChainId } from 'lib/chains';

/**
 * Note that this `useSimulateContract` call is in a new file so that we can mock it out in tests for our transaction modals
 */
export default function useSimulateSetApprovalForAll(
  variables: { contractAddress: Address; chainId: ChainId },
  options: { enabled: boolean }
) {
  return useSimulateContract({
    abi: NFTCollection,
    functionName: 'setApprovalForAll',
    address: variables.contractAddress,
    args: [
      getContractAddress({
        chainId: variables.chainId,
        contractName: 'nftMarket',
      }),
      true,
    ],
    chainId: variables.chainId,
    query: {
      enabled: options.enabled,
      retry: false,
    },
  });
}
