import { useQuery, UseQueryOptions } from '@tanstack/react-query';

import * as Generated from 'gql/api/queries/world-id-by-nft.generated';

type WorldId = number | null;

async function getWorldIdByNft(
  variables: Generated.ApiWorldIdByNftVariables
): Promise<WorldId | null> {
  const fetchWorldId = Generated.useWorldIdByNft.fetcher(variables);
  const response = await fetchWorldId();

  return response.world ? response.world.id : null;
}

export default function useWorldIdByNft(
  variables: Generated.ApiWorldIdByNftVariables,
  options: Pick<
    UseQueryOptions<WorldId | null, Error>,
    'enabled' | 'refetchOnWindowFocus' | 'refetchOnMount'
  > = {}
) {
  return useQuery({
    ...options,
    queryKey: ['world', 'idByNft', variables],
    queryFn: () => getWorldIdByNft(variables),
  });
}
