import { InfoIcon } from '@f8n/icons';

import DropdownMenu from 'components/base/DropdownMenu';

import useModal from 'hooks/use-modal';

export function ReportMenuItem() {
  const { setModal } = useModal();

  return (
    <DropdownMenu.Item
      type="button"
      onClick={() => setModal({ type: 'REPORT' })}
      variant="danger"
    >
      <InfoIcon />
      Report
    </DropdownMenu.Item>
  );
}
