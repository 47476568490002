import { darkMode, styled } from '@f8n-frontend/stitches';
import { VariantProps } from '@stitches/react';

import { COINBASE_BLUE } from 'design/tokens/partners';

type RootVariants = VariantProps<typeof Root>;

type Item = {
  content: React.ReactNode;
  label: string;
};

type DetailsTableBaseProps = {
  items: Item[];
  size?: RootVariants['size'];
};

/**
 * Please note that this is a rough implementation.
 * It will likely need to evolve as we use it with variable-length content, and for more complex tables.
 * Speak to Scott if you have any questions :)
 */
export function DetailsTableBase(props: DetailsTableBaseProps) {
  const { items, size } = props;

  if (items.length === 0) {
    return null;
  }

  return (
    <Root size={size}>
      {props.items.map((item, index) => (
        <Row key={`${item.label}-${index}`}>
          <Label>{item.label}</Label>
          <Item>{item.content}</Item>
        </Row>
      ))}
    </Root>
  );
}

const Row = styled('div', {
  width: '100%',
  display: 'grid',
  alignItems: 'center',
  borderBottom: '1px solid $black5',

  [darkMode]: {
    borderColor: '$black10',
  },
});

const Item = styled('div', {
  fontFamily: '$body',
});

const Label = styled(Item, {
  color: '$black60',
  height: '100%',
  display: 'flex',
  alignItems: 'center',
});

const IconTagContainer = styled('div', {
  display: 'flex',
  alignItems: 'center',

  variants: {
    color: {
      // Coinbase
      base: {
        svg: {
          color: COINBASE_BLUE,
        },
      },
    },
  },
});

const Root = styled('div', {
  width: '100%',
  borderTop: '1px solid $black5',

  [darkMode]: {
    borderColor: '$black10',
  },

  variants: {
    size: {
      // Note that hard coded column width is not ideal, but fine for initial use cases. Be careful when editing this.
      // The goal is for each label to have a consistent width, so that the content is always perfectly aligned.
      0: {
        [`${Row}`]: {
          gap: '$2',
          minHeight: 54,
          fontSize: '$0',
          gridTemplateColumns: '120px 1fr',
        },
        [`${IconTagContainer}`]: {
          gap: '5px',
          svg: {
            width: '14px',
            height: '14px',
          },
        },
        [`${Item}`]: {
          paddingY: '$4',
        },
      },
      1: {
        [`${Row}`]: {
          gap: '$2',
          fontSize: '$2',
          gridTemplateColumns: '140px 1fr',
        },
        [`${IconTagContainer}`]: {
          gap: '$2',
          svg: {
            width: '14px',
            height: '14px',
          },
        },
        [`${Item}`]: {
          paddingY: '$4',
        },
      },
    },
  },
  defaultVariants: {
    size: 1,
  },
});

type IconTagContainerVariants = VariantProps<typeof IconTagContainer>;

function IconTag(props: {
  content: string;
  icon: React.ReactNode;
  color?: IconTagContainerVariants['color'];
}) {
  return (
    <IconTagContainer color={props.color}>
      {props.icon}
      <span>{props.content}</span>
    </IconTagContainer>
  );
}

export const DetailsTable = Object.assign(DetailsTableBase, {
  Root,
  Row,
  Item,
  Label,
  IconTag,
});
