import { useSwitchChain } from 'wagmi';

import Button, { ButtonProps } from 'components/base/Button';
import { getConnectToNetworkCopy } from 'copy/auth';

import { ChainId } from 'lib/chains';
import { ENV } from 'utils/env';
import { getChainConfigById } from 'utils/network';

export function ChangeNetworkButton(props: {
  chainId?: ChainId;
  size?: ButtonProps['size'];
}) {
  const { chainId = ENV.PRIMARY_CHAIN_ID } = props;
  const { isPending, switchChain } = useSwitchChain();

  const chainConfig = getChainConfigById(chainId);

  return (
    <Button
      disabled={isPending}
      variant="primary"
      onClick={() => {
        switchChain({
          chainId: chainConfig.chainId,
        });
      }}
      {...props}
    >
      {getConnectToNetworkCopy(chainConfig.chain)}
    </Button>
  );
}
