import { styled } from '@f8n-frontend/stitches';

import Icon from 'components/Icon';
import { LearnMoreLink } from 'components/LearnMoreLink';
import Box from 'components/base/Box';
import Link from 'components/base/Link';
import Modal from 'components/base/Modal';
import SplitsBreakdown from 'components/splits/SplitsBreakdown';
import TransactionHashLink from 'components/transactions/TransactionHashLink';

import SplitIcon from 'assets/icons/split-icon.svg';
import { SplitsFragment } from 'gql/hasura/hasura-fragments.generated';
import useModalVisibility from 'hooks/use-modal-visibility';
import { ChainId } from 'lib/chains';
import { getEtherscanUrl } from 'utils/etherscan';
import { getFirstValue } from 'utils/helpers';
import { helpCenterPaths } from 'utils/router';

import { ModalOptions } from 'types/modal';

const MODAL_KEY = 'SPLITS';
type SplitsModalOptions = ModalOptions<typeof MODAL_KEY>;

export function SplitsModal() {
  const modal = useModalVisibility(MODAL_KEY);

  return (
    <Modal.Root open={modal.open} onOpenChange={modal.onOpenChange}>
      <Modal.Portal>
        <Modal.BlurOverlay />
        <Modal.PositionOverlay>
          <Modal.UnmountListener onUnmount={modal.onUnmount} />
          {modal.config && <SplitsModalWindow {...modal.config} />}
        </Modal.PositionOverlay>
      </Modal.Portal>
    </Modal.Root>
  );
}

function SplitsModalWindow(props: SplitsModalOptions) {
  return (
    <Modal.Window
      size={2}
      // TODO: add BlockExplorer link after split contract address is exposed via the API
      footer={null}
    >
      <Modal.BodyTitle
        title="Split"
        description={
          <>
            Split earnings are automatically deposited into each recipient’s
            wallet. <LearnMoreLink article="splitNftEarnings" />
          </>
        }
      />
      <Box css={{ paddingTop: '$6' }}>
        <SplitsBreakdown splits={props.splits} />
      </Box>
    </Modal.Window>
  );
}

interface SplitsModalProps {
  percentSplits: SplitsFragment[];
  chainId: ChainId;
}

/** @deprecated in favor of SplitsModal */
export default function LegacySplitsModal(props: SplitsModalProps) {
  const { percentSplits, chainId } = props;
  const firstSplit = getFirstValue(percentSplits);

  return (
    <Modal.Content size={2}>
      <Header>
        <Modal.BodyTitle
          title={
            <Title>
              <Icon
                icon={SplitIcon}
                width={36}
                height={31}
                style={{ top: 3 }}
              />
              Split
            </Title>
          }
          description={
            <>
              Split earnings are automatically deposited into each recipient’s
              wallet.{' '}
              <Link
                as="a"
                target="_blank"
                rel="noreferrer"
                hasUnderline
                variant="primary"
                href={helpCenterPaths.splitNftEarnings}
              >
                Learn more →
              </Link>
            </>
          }
        />
      </Header>
      <SplitsBreakdown splits={percentSplits} />
      {firstSplit && (
        <Footer>
          <TransactionHashLink
            href={getEtherscanUrl.address(firstSplit.contractAddress)}
            txHash={firstSplit.contractAddress}
            chainId={chainId}
          />
        </Footer>
      )}
    </Modal.Content>
  );
}

const Header = styled('div', {
  marginBottom: '$7',
  '@bp1': {
    marginBottom: '$8',
  },
});

const Title = styled('div', {
  gap: '$3',
  display: 'flex',
  alignItems: 'center',
});

const Footer = styled('div', {
  display: 'flex',
  marginTop: '$7',
  justifyContent: 'center',
});
