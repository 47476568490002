import { styled } from '@f8n-frontend/stitches';
import { match } from 'ts-pattern';

import Pulse from 'components/Pulse';
import Theme from 'components/Theme';
import CollectionLogo from 'components/base/CollectionLogo';
import Heading from 'components/base/Heading';
import Skeleton from 'components/base/Skeleton';
import CollectionTag from 'components/collections/CollectionTag';
import { OfferProgressCircle } from 'components/transactions/offer/OfferProgressCircle';

import { activeAuctionStateToAuctionMarketMap } from 'schemas/parse/market';
import { buildImageUrlFromAnyAsset } from 'utils/assets';
import { formatDurationFromSeconds, parseDateToUnix } from 'utils/dates/dates';
import { formatETHWithSuffix } from 'utils/formatters';
import { AuctionMarket, OfferMarket } from 'utils/markets/markets';

import { UserLight } from 'types/Account';
import { ArtworkV2 } from 'types/Artwork';
import { CollectionCardFragment } from 'types/Collection';
import { MarketNft } from 'types/Nft';

import InlineCountdown from './InlineCountdown';

type AuctionFields = Pick<
  AuctionMarket,
  'marketType' | 'amountInEth' | 'eventDate' | 'duration'
>;

type OfferFields = Pick<OfferMarket, 'amountInEth' | 'eventDate'>;

type SmallArtworkCardProps = {
  artworkName: string | null;
  assetUrl: string;
  // TODO: make this required once CollectionTag works with new NFT types-
  collection: CollectionCardFragment | null;
  creator: UserLight;
} & (
  | { enrichment?: 'NONE' }
  | { enrichment: 'AUCTION'; auction: AuctionFields }
  | { enrichment: 'OFFER'; offer: OfferFields }
);

function SmallArtworkCardBase(props: SmallArtworkCardProps) {
  const { artworkName, assetUrl, collection, creator } = props;

  return (
    <Theme
      theme={
        props.enrichment === 'AUCTION' &&
        props.auction.marketType === 'LIVE_AUCTION'
          ? 'dark'
          : 'light'
      }
    >
      <Card>
        <Container>
          <CollectionLogo imageUrl={assetUrl} size={7} />
          <Body>
            {collection !== null && creator !== null && (
              <CollectionTag
                collection={collection}
                creator={creator}
                disableHoverCard
                size={0}
                type="avatar-text"
                variant="primary"
              />
            )}
            {artworkName && <MainHeading>{artworkName}</MainHeading>}
          </Body>
        </Container>

        {props.enrichment === 'OFFER' && (
          <Footer>
            <OfferContainer>
              <OfferProgressCircle
                expiresAt={props.offer.eventDate as string}
                size={40}
                strokeWidth={6}
              />
              <TwoLine align="left">
                <PreHeading>Highest Offer</PreHeading>
                <MainHeading>
                  {formatETHWithSuffix(props.offer.amountInEth)}
                </MainHeading>
              </TwoLine>
            </OfferContainer>
          </Footer>
        )}

        {props.enrichment === 'AUCTION' && (
          <>
            <Footer>
              {props.auction.marketType === 'LIVE_AUCTION' &&
                props.auction.eventDate && (
                  <TwoLine>
                    <PreHeading>Auction ends in</PreHeading>
                    <MainHeading>
                      <Pulse size={22} />
                      <InlineCountdown
                        timestamp={parseDateToUnix(props.auction.eventDate)}
                      />
                    </MainHeading>
                  </TwoLine>
                )}
              <TwoLine
                align={
                  props.auction.marketType === 'LIVE_AUCTION' ? 'right' : 'left'
                }
              >
                <PreHeading>
                  {props.auction.marketType === 'LIVE_AUCTION'
                    ? 'Highest bid'
                    : 'Reserve price'}
                </PreHeading>
                <MainHeading>
                  {formatETHWithSuffix(props.auction.amountInEth)}
                </MainHeading>
              </TwoLine>
              {props.auction.duration &&
                props.auction.marketType !== 'LIVE_AUCTION' && (
                  <TwoLine css={{ width: '50%' }}>
                    <PreHeading>Duration</PreHeading>
                    <MainHeading>
                      {formatDurationFromSeconds(props.auction.duration)}
                    </MainHeading>
                  </TwoLine>
                )}
            </Footer>
          </>
        )}
      </Card>
    </Theme>
  );
}

const Card = styled('div', {
  gap: '$4',
  display: 'flex',
  flexDirection: 'column',

  padding: '$4',
  borderRadius: '$4',
  boxShadow: '$regular0',
  background: '$white100',
});

const Container = styled('div', {
  gap: '$4',
  display: 'flex',
  alignItems: 'center',
});

const Body = styled('div', {
  gap: '$2',
  display: 'flex',
  flexDirection: 'column',
});

const Footer = styled('div', {
  padding: '6px',
  paddingTop: '$4',
  borderTop: '1px solid $black10',

  display: 'flex',
  justifyContent: 'space-between',
});

const OfferContainer = styled('div', {
  gap: '$3',
  display: 'flex',
  alignItems: 'center',
});

const TwoLine = styled('div', {
  gap: '6px',
  display: 'flex',
  flexDirection: 'column',
  variants: {
    align: {
      left: {},
      right: { textAlign: 'right' },
    },
  },
});

const PreHeading = styled(Heading);

PreHeading.defaultProps = {
  lineHeight: 1,
  size: 1,
  color: 'dim',
  weight: 'medium',
};

const MainHeading = styled(Heading, {
  gap: '6px',
  display: 'flex',
  alignItems: 'center',
  '@bp2': {
    fontSize: '18px !important',
  },
});

MainHeading.defaultProps = {
  lineHeight: 0,
  size: 2,
};

function SmallArtworkCardSkeleton() {
  return (
    <Container>
      <Skeleton.Block css={{ width: '$avatar7', height: '$avatar7' }} />
      <Body>
        <Skeleton.Block css={{ width: '90px', height: '18px' }} />
        <Skeleton.Block css={{ width: '140px', height: '18px' }} />
      </Body>
    </Container>
  );
}

type MarketFields = {
  activeOffer?: OfferFields | null;
  activeAuction?: AuctionFields | null;
};

type SmallArtworkCardLegacyProps = MarketFields & {
  artwork: ArtworkV2 | undefined;
};

/**
 * Marked as Legacy because this is coupled to the legacy ArtworkV2 type
 */
function SmallArtworkCardLegacy(props: SmallArtworkCardLegacyProps) {
  const { artwork, activeAuction, activeOffer } = props;

  const assetUrl = artwork ? buildImageUrlFromAnyAsset(artwork) : undefined;

  if (!artwork || !artwork.collection || !artwork.creator || !assetUrl) {
    return <SmallArtworkCardSkeleton />;
  }

  if (activeOffer) {
    return (
      <SmallArtworkCard
        enrichment="OFFER"
        artworkName={artwork.name}
        assetUrl={assetUrl}
        collection={artwork.collection}
        creator={artwork.creator}
        offer={activeOffer}
      />
    );
  }

  if (activeAuction) {
    return (
      <SmallArtworkCard
        enrichment="AUCTION"
        artworkName={artwork.name}
        assetUrl={assetUrl}
        collection={artwork.collection}
        creator={artwork.creator}
        auction={activeAuction}
      />
    );
  }

  return (
    <SmallArtworkCard
      artworkName={artwork.name}
      assetUrl={assetUrl}
      collection={artwork.collection}
      creator={artwork.creator}
    />
  );
}

type SmallArtworkCardMarketNftProps = {
  nft: Pick<MarketNft, 'creator' | 'media' | 'name'>;
  activeAuction?: MarketNft['activeAuction'];
  activeOffer?: MarketNft['activeOffer'];
};

function SmallArtworkCardMarketNft(props: SmallArtworkCardMarketNftProps) {
  const { nft, activeAuction, activeOffer } = props;

  // Intentionally set to null because SmallArtworkCard collection prop is coupled to a legacy Hasura type
  const collection = null;

  if (!nft.media) return null;

  const { media } = nft;

  const assetUrl = match(media)
    .with({ type: 'video' }, (video) => {
      return video.poster;
    })
    .otherwise((img) => {
      return img.src;
    });

  if (activeOffer) {
    return (
      <SmallArtworkCard
        enrichment="OFFER"
        artworkName={nft.name}
        assetUrl={assetUrl}
        collection={collection}
        creator={nft.creator}
        offer={{
          amountInEth: activeOffer.amount,
          eventDate: activeOffer.expiryDate,
        }}
      />
    );
  }

  if (activeAuction) {
    return (
      <SmallArtworkCard
        enrichment="AUCTION"
        artworkName={nft.name}
        assetUrl={assetUrl}
        collection={collection}
        creator={nft.creator}
        auction={{
          amountInEth:
            activeAuction.highestBid?.amount || activeAuction.reservePrice,
          eventDate: activeAuction.expiryDate,
          marketType: activeAuctionStateToAuctionMarketMap[activeAuction.state],
          duration: activeAuction.duration,
        }}
      />
    );
  }

  return (
    <SmallArtworkCard
      artworkName={nft.name}
      assetUrl={assetUrl}
      collection={collection}
      creator={nft.creator}
    />
  );
}

const SmallArtworkCard = Object.assign(SmallArtworkCardBase, {
  MarketNft: SmallArtworkCardMarketNft,
  /** @deprecated use MarketNft instead */
  Legacy: SmallArtworkCardLegacy,
  Skeleton: SmallArtworkCardSkeleton,
});

export default SmallArtworkCard;
