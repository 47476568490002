import { Abi, Address } from 'viem';

import { MultiTokenCollection } from 'lib/abis/MultiTokenCollection';
import { NFTCollectionFactory } from 'lib/abis/NFTCollectionFactory';
import { NFTDropMarket } from 'lib/abis/NFTDropMarket';
import { NFTMarket } from 'lib/abis/NFTMarket';
import { NFTMarketRouter } from 'lib/abis/NFTMarketRouter';
import { Worlds } from 'lib/abis/Worlds';
import { getContractAddress } from 'lib/addresses';

import { createSimulateWriteConfigForContract } from './simulate';
import { GetAddress } from './types';

function createContractClient<TAbi extends Abi>(config: {
  abi: TAbi;
  getAddress: GetAddress;
}) {
  return {
    getSimulateConfig: createSimulateWriteConfigForContract<TAbi>({
      abi: config.abi,
      getAddress: config.getAddress,
    }),
  };
}

export const dropMarketClient = createContractClient({
  abi: NFTDropMarket,
  getAddress: (options) => {
    return getContractAddress({
      chainId: options.chainId,
      contractName: 'nftDropMarket',
    });
  },
});

export const marketClient = createContractClient({
  abi: NFTMarket,
  getAddress: (options) => {
    return getContractAddress({
      chainId: options.chainId,
      contractName: 'nftMarket',
    });
  },
});

export const marketRouterClient = createContractClient({
  abi: NFTMarketRouter,
  getAddress: (options) => {
    return getContractAddress({
      chainId: options.chainId,
      contractName: 'nftMarketRouter',
    });
  },
});

export const worldsClient = createContractClient({
  abi: Worlds,
  getAddress: (options) => {
    return getContractAddress({
      chainId: options.chainId,
      contractName: 'worlds',
    });
  },
});

export const nftCollectionFactoryClient = createContractClient({
  abi: NFTCollectionFactory,
  getAddress: (options) => {
    return getContractAddress({
      chainId: options.chainId,
      contractName: 'nftCollectionFactoryV2',
    });
  },
});

export const createMultiTokenCollectionClient = (contractAddress: Address) =>
  createContractClient({
    abi: MultiTokenCollection,
    getAddress: (_options) => contractAddress,
  });
