import { ApiMediaFragment } from 'gql/api/api-fragments.generated';

import { mapApiMediaToPreviewMediaAsset } from './media-preview';

export function selectNftWithPreviewMedia<
  Nft extends { media: ApiMediaFragment | null },
>(data: { nft: Nft | null }) {
  if (!data.nft) {
    return { nft: null };
  }

  const { nft } = data;

  return {
    nft: {
      ...nft,
      media: mapApiMediaToPreviewMediaAsset(nft.media),
    },
  };
}
