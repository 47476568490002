import { Address } from 'viem';
import { useWriteContract, useSimulateContract } from 'wagmi';

import useTransactionStore from 'state/stores/transactions';
import { satisfiesExperimentalWeb3ActionConfig } from 'web3/config';
import { marketClient } from 'web3/contract-clients';

import useMarketBalances from 'hooks/web3/use-market-balances';
import { UNSET_ARG } from 'lib/constants';
import { extractPrepareContractWriteRevertReason } from 'utils/revert-reasons';
import { calculateTransactionValue } from 'utils/transactions';
import { parseEther } from 'utils/units';

import { NftFilter } from 'types/Nft';

export const PLACE_BID_ACTION = 'place-bid';

export type ProposedBid = {
  amount: string;
  auctionId: number;
  referrer: Address;
};

export type PlaceBidOptions = {
  proposedBid: ProposedBid | null;
  nftFilter: NftFilter;
  worldId: number | null;
};

export default function usePlaceBid(options: PlaceBidOptions) {
  const { nftFilter, proposedBid, worldId } = options;
  const { chainId } = nftFilter;

  const txStore = useTransactionStore();
  const marketBalancesQuery = useMarketBalances(nftFilter);

  const getTransactionValue = (transactionValue: bigint) => {
    if (marketBalancesQuery.data) {
      return calculateTransactionValue({
        transactionValue,
        marketplaceBalance: marketBalancesQuery.data.availableFethBalance,
      });
    } else {
      return transactionValue;
    }
  };

  const placeBidConfig = marketClient.getSimulateConfig('placeBidV2', {
    args: proposedBid
      ? [
          // Auction id
          BigInt(proposedBid.auctionId),
          // Bid amount
          parseEther(proposedBid.amount),
          // Referrer
          proposedBid.referrer,
        ]
      : undefined,
    chainId: nftFilter.chainId,
    enabled: Boolean(proposedBid),
    value: proposedBid
      ? getTransactionValue(parseEther(proposedBid.amount))
      : UNSET_ARG,
  });

  const simulatePlaceBid = useSimulateContract(placeBidConfig);

  const contractWrite = useWriteContract({
    mutation: {
      onSuccess: (txHash) => {
        txStore.startTracking({
          chainId,
          ui: 'toast',
          action: {
            name: PLACE_BID_ACTION,
            worldId,
          },
          txHash,
          title: {
            PENDING: 'Placing bid…',
            SUCCESS: 'Bid placed',
          },
          description: {
            PENDING:
              'Your bid is being confirmed. You can close this if you like.',
            SUCCESS:
              'Your bid was confirmed. Please keep an eye on this auction in case someone outbids you before it’s over.',
          },
        });
      },
    },
  });

  return satisfiesExperimentalWeb3ActionConfig({
    disabledReason: extractPrepareContractWriteRevertReason(simulatePlaceBid),
    hasTxPrompt: contractWrite.isPending,
    reset: contractWrite.reset,
    simulation: simulatePlaceBid,
    txHash: contractWrite.data || null,

    prompt: () => {
      if (simulatePlaceBid.isSuccess) {
        contractWrite.writeContract(simulatePlaceBid.data.request);
      }
    },
  });
}
