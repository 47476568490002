import { styled } from '@f8n-frontend/stitches';

import Button from './base/Button';
import Text from './base/Text';

export const IconContainer = styled('div', {
  cursor: 'pointer',
  display: 'flex',
  alignItems: 'center',
  transition: 'transform $1 $ease',
  variants: {
    isOpen: {
      true: {
        transform: 'rotate(180deg)',
      },
    },
  },
});

export const CollapsibleTriggerText = styled(Button, {
  color: '$black50',
  width: '100%',
  [`${Text}`]: {
    transition: 'color $1 $ease',
  },
  '@hover': {
    '&:hover': {
      color: '$black100',
      [`${Text}`]: {
        color: '$black100',
      },
    },
  },
});

CollapsibleTriggerText.defaultProps = {
  variant: 'base',
};
