import { VariantProps } from '@stitches/react';

import Avatar from 'components/base/Avatar';
import Box from 'components/base/Box';
import Flex from 'components/base/Flex';
import Heading from 'components/base/Heading';
import Mono from 'components/base/Mono';
import Skeleton from 'components/base/Skeleton';
import Text from 'components/base/Text';
import ProfileHoverCard from 'components/profiles/ProfileHoverCard';

import useUserByPublicKey from 'hooks/queries/api/use-user-by-public-key';
import { FeeType } from 'hooks/web3/transactions/use-get-fees';
import { formatETHWithSuffix } from 'utils/formatters';
import {
  getUsernameOrTruncatedAddress,
  isNumberType,
  truncateAddress,
} from 'utils/helpers';

type LineItemWithReceiverProps = {
  publicKey: string;
  amountInEth: number | string;
  percentage: number;
  label: string;
};

function LineItemWithReceiver(props: LineItemWithReceiverProps) {
  const { percentage, amountInEth, publicKey, label } = props;

  const { data: user } = useUserByPublicKey(
    { publicKey },
    { refetchOnWindowFocus: false, refetchOnMount: false }
  );

  const getUserInfo = () => {
    if (user) {
      return <Heading size={1}>{getUsernameOrTruncatedAddress(user)}</Heading>;
    } else {
      return (
        <Mono size={1} weight="regular">
          {truncateAddress(publicKey)}
        </Mono>
      );
    }
  };

  return (
    <Flex css={{ alignItems: 'center', justifyContent: 'space-between' }}>
      <ProfileHoverCard publicKey={publicKey}>
        <Flex css={{ alignItems: 'center' }}>
          {user ? (
            <Avatar
              imageUrl={user.profileImageUrl}
              publicKey={publicKey}
              size={3}
            />
          ) : (
            <Skeleton.Circle css={{ width: 32, height: 32 }} />
          )}

          <Box css={{ marginLeft: '$3' }}>
            {getUserInfo()}

            <Text size={1} color="dim">
              {label}
            </Text>
          </Box>
        </Flex>
      </ProfileHoverCard>

      <CostPercentage
        percentSplit={percentage}
        amountInEth={amountInEth}
        size={1}
      />
    </Flex>
  );
}

type CostPercentageProps = {
  amountInEth: number | string;
  percentSplit: number;
  size: VariantProps<typeof Text>['size'];
};

function CostPercentage(props: CostPercentageProps) {
  const { amountInEth, percentSplit, size } = props;
  return (
    <Box css={{ textAlign: 'right', display: 'flex', alignItems: 'center' }}>
      <Heading size={size}>{formatETHWithSuffix(amountInEth)}</Heading>
      <Mono size={2} color="dim" css={{ marginLeft: '$3' }}>
        {percentSplit}%
      </Mono>
    </Box>
  );
}

type LineItemProps = {
  label: string;
  value: string;
  percentage?: number;
  size?: VariantProps<typeof Text>['size'];
};

function LineItem(props: LineItemProps) {
  const { label, value, percentage, size = 2 } = props;

  return (
    <Flex
      css={{
        alignItems: 'flex-start',
        justifyContent: 'space-between',
        width: '100%',

        [`&:hover svg`]: {
          color: '$black70',
        },
      }}
    >
      <Heading size={size} color="dim" weight="regular">
        {label}
      </Heading>
      <Flex css={{ alignItems: 'center', justifyContent: 'space-between' }}>
        <Text size={size} weight="semibold" color="strong">
          {value}
        </Text>
        {isNumberType(percentage) && (
          <Mono color="dim" css={{ marginLeft: '$3' }} size={2}>
            {percentage}%
          </Mono>
        )}
      </Flex>
    </Flex>
  );
}

export const feeLabelMap: Record<FeeType, string> = {
  CURATOR: 'Curator',
  CREATOR: 'Creator',
  OWNER: 'Owner',
  SPLIT_RECIPIENT: 'Split',
  FOUNDATION: 'Marketplace fee',
};

const Fees = {
  CostPercentage,
  LineItem,
  LineItemWithReceiver,
};

export default Fees;
