import {
  ContractErrorNames,
  ContractErrorSignatures,
} from '@f8n/f8n-contracts';
import { z } from 'zod';

export const RevertName = z.enum(ContractErrorNames);
export const RevertSignature = z.enum(ContractErrorSignatures);

export type RevertReasonCopy = {
  reason: string;
  description: string;
};

export type RevertReason =
  | ({ type: 'ENRICHED' } & RevertReasonCopy)
  | { type: 'PLAIN'; reason: string };

export type RevertError = z.infer<typeof RevertName>;

export type RevertCopyMap = Record<
  z.infer<typeof RevertName>,
  RevertReasonCopy
>;
