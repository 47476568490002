import { Currency } from 'types/Currency';
import { ResponsiveProp } from 'types/stitches';

import Avatar from './base/Avatar';

type CurrencyAvatarProps = {
  currency: Currency;
  size?: ResponsiveProp<0 | 1>;
};

/** `Avatar` tailored to visually represent a `Currency`. Defaults to `size` of `1`. */
export default function CurrencyAvatar(props: CurrencyAvatarProps) {
  return (
    <Avatar
      imageUrl={props.currency.imageUrl}
      shape="square"
      size={props.size ?? 1}
    />
  );
}
