import { StarIcon, TimeIcon } from '@f8n/icons';
import { differenceInHours, isFuture } from 'date-fns';
import { match } from 'ts-pattern';

import useCountdown from 'hooks/use-countdown';
import { formatDateTime, parseDateToUnix } from 'utils/dates/dates';

import { PresaleEligibility } from 'types/DropSale';

import Pulse from './Pulse';
import Text from './base/Text';
import { Checkout } from './checkout-widget/Checkout';

function SaleScheduleDynamicLabel(props: {
  children: React.ReactNode;
  isUpcoming: boolean;
}) {
  const { isUpcoming } = props;

  return (
    <Text
      color={isUpcoming ? 'upcoming' : 'dim'}
      css={{
        display: 'flex',
        alignItems: 'center',

        '& > svg': {
          marginRight: '$2',
          width: '$icon0',
          height: '$icon0',
        },
      }}
      size={1}
    >
      {props.children}
    </Text>
  );
}

function PresaleSchedule(props: {
  startDate: Date;
  eligibilityStatus: PresaleEligibility;
}) {
  const { eligibilityStatus, startDate } = props;

  const countdown = useCountdown(parseDateToUnix(startDate));

  if (countdown.message === null) {
    return (
      <Checkout.StatusRowContainer>
        <Pulse color="$live" />
        <Text color="live" weight="medium" size={1}>
          Presale live
        </Text>
      </Checkout.StatusRowContainer>
    );
  }

  return (
    <SaleScheduleDynamicLabel isUpcoming={eligibilityStatus !== 'NOT_ELIGIBLE'}>
      <StarIcon />
      <span>
        {match(startDate)
          .when(isUpcoming24h, () => {
            return `Presale opens in ${countdown.message}`;
          })
          .otherwise(() => {
            return `Presale opens ${formatDateTime(startDate, {
              alwaysShowTime: true,
            })}`;
          })}
      </span>
    </SaleScheduleDynamicLabel>
  );
}

function PublicSaleSchedule(props: {
  /**
   * - Use `public-sale` when a sale has a sale has an associated pre-sale period.
   * - Use `mint-opens` for sales without a pre-sale
   * */
  prefix: 'public-sale' | 'mint-opens' | 'sale-starts';
  startDate: Date;
}) {
  const { prefix, startDate } = props;

  const countdown = useCountdown(parseDateToUnix(startDate));

  if (countdown.message === null) {
    return null;
  }

  const prefixText = match(prefix)
    .with('mint-opens', () => 'Mint opens')
    .with('public-sale', () => 'Public sale opens')
    .with('sale-starts', () => 'Sale starts')
    .exhaustive();

  return (
    <SaleScheduleDynamicLabel isUpcoming>
      <TimeIcon />
      <span>
        {match(startDate)
          .when(isUpcoming24h, () => {
            return `${prefixText} in ${countdown.message}`;
          })
          .otherwise(() => {
            return `${prefixText} ${formatDateTime(startDate, {
              alwaysShowTime: true,
            })}`;
          })}
      </span>
    </SaleScheduleDynamicLabel>
  );
}

const isUpcoming24h = (date: Date) => {
  return isFuture(date) && differenceInHours(date, Date.now()) < 24;
};

export const SaleSchedule = {
  Presale: PresaleSchedule,
  Public: PublicSaleSchedule,
};
