import { z } from 'zod';

export const PROVENANCE_EVENT_TYPES = [
  'TRANSFER',
  'MINT',
  'LIST',
  'BID',
  'SELL',
  'SETTLE',
  'UNLIST',
  'PRICE_CHANGE',
  'BUY_NOW_PRICE_ACCEPT',
  'BUY_NOW_PRICE_CANCEL',
  'BUY_NOW_PRICE_CHANGE',
  'BUY_NOW_PRICE_SET',
  'OFFER_ACCEPT',
  'OFFER_CHANGE',
  'OFFER_CREATE',
  'OFFER_OUTBID',
  'PRIVATE_SALE',
  'NFT_ADDED_TO_EXHIBITION',
] as const;

export const EventTypeSchema = z.enum([
  ...PROVENANCE_EVENT_TYPES,
  'BURN',
  'COLLECTION_CREATE',
  'COLLECTION_DELETE',
  'COLLECTION_REVEAL',
  'FIXED_PRICE_SALE_CREATE',
  'MAX_TOKEN_ID_UPDATE',
  'EXHIBITION_CREATED',
  'CREATE_LINEAR_DUTCH_AUCTION',
]);
