import { createContext, useContext } from 'react';

import { noop } from 'utils/helpers';

import { MarketWidgetContextValue } from './types';

export const MarketWidgetContext = createContext<MarketWidgetContextValue>({
  onAcceptOffer: noop,
  onBurn: noop,
  onBuyNow: noop,
  onEditListing: noop,
  onMakeOffer: noop,
  onSell: noop,
  onSettle: noop,
  onTransfer: noop,
  onUnlist: noop,
  onViewMoreBids: noop,
  placeBidCta: { type: 'CONNECT', prompt: noop },
  bid: {
    amount: '',
    setAmount: noop,
  },
  currency: null,
});

export const useMarketWidget = () => {
  return useContext(MarketWidgetContext);
};
