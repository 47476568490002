import { NFTMarket } from '@f8n/f8n-contracts/dist/abis/NFTMarket.generated';
import { useReadContract } from 'wagmi';

import { getContractAddress } from 'lib/addresses';
import { ChainId } from 'lib/chains';

export function useGetReserveAuction(
  variables: {
    chainId: ChainId;
    auctionId: number;
  },
  options: {
    enabled?: boolean;
  } = {}
) {
  return useReadContract({
    abi: NFTMarket,
    address: getContractAddress({
      chainId: variables.chainId,
      contractName: 'nftMarket',
    }),
    chainId: variables.chainId,
    functionName: 'getReserveAuction',
    args: [BigInt(variables.auctionId)],
    query: {
      enabled: options.enabled ?? true,
    },
  });
}
