import { z } from 'zod';

import { MIN_LIST_PRICE } from 'lib/constants';
import { addressValueSchema } from 'schemas/shared';

type PriceSchemaOptions = {
  minPrice: number;
};

const createPriceSchema = (options: PriceSchemaOptions) => {
  const minPriceSchema = z
    .number()
    .positive({
      message: 'Price must be greater than 0',
    })
    .min(options.minPrice, {
      message: `Price must be minimum of ${options.minPrice} ETH`,
    });

  return z.union([
    minPriceSchema,
    z
      .string()
      .transform((price, ctx) => {
        /**
         * for languages that format a number using a comma
         * as the decimal separator, replace it with a period
         */
        const normalizedPrice = price.replace(',', '.');
        const parsedPrice = Number(normalizedPrice);

        if (isNaN(parsedPrice)) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: 'Price is invalid',
          });
          return z.NEVER;
        } else {
          return parsedPrice;
        }
      })
      .pipe(minPriceSchema),
  ]);
};

export const VALID_PRICE = createPriceSchema({
  minPrice: MIN_LIST_PRICE,
});

/** Transfer schema */
export const transferNftSchema = z.object({
  senderAddress: addressValueSchema,
  receiverAddress: addressValueSchema,
});

const numberRegex = /^-?\d+(\.\d+)?$/;

export const validNumberSchema = z
  .string()
  .transform((input) => {
    /**
     * for languages that format a number using a comma
     */
    const withPeriods = input.replace(/,/g, '.');
    /**
     * append a leading zero if the input starts with a period
     */
    if (withPeriods.startsWith('.')) {
      return '0' + withPeriods;
    } else {
      return withPeriods;
    }
  })
  .refine((value) => numberRegex.test(value), {
    message: 'Invalid number format',
  });

export const placeBidSchema = z.object({
  amount: validNumberSchema,
  referrer: addressValueSchema,
});

export const makeOfferSchema = placeBidSchema;
