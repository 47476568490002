import { always } from 'ramda';
import { match } from 'ts-pattern';

import { hasPublicKey, isConnectedToChainId } from 'contexts/auth/helpers';
import { Auth } from 'contexts/auth/types';

import { ChainId } from 'lib/chains';
import { extractPrepareContractWriteRevertReason } from 'utils/revert-reasons';

import {
  PreTxConnectConfig,
  PreTxSwitchChainConfig,
  Web3ActionCtaConfig,
  Web3PromptLifecycle,
} from 'types/Web3ActionCta';
import { StepperIncrementControls } from 'types/stepper';
import { MinimalUseSimulateContractResult } from 'types/wagmi';

import { isBigInt } from './bigint';
import { noop } from './helpers';
import { isZero } from './numbers';

export const isWeb3CtaLoading = (config: Web3PromptLifecycle) => {
  return (
    match(config)
      /** Transaction is in-flight */
      .with({ trackedTx: { status: 'PENDING' } }, always(true))
      /** Pre-flight transaction prompt is visible in users wallet */
      .with({ config: { hasTxPrompt: true } }, always(true))
      .otherwise(always(false))
  );
};

export const getOnPageWeb3ActionCtaConfig = (options: {
  action: Web3PromptLifecycle;
  auth: Auth;
  bridgeCta: PreTxConnectConfig | null;
  chainId: ChainId;
  connectCta: PreTxConnectConfig;
  switchChainCta: PreTxSwitchChainConfig;
}): Web3ActionCtaConfig => {
  const { action, auth, bridgeCta, chainId, connectCta, switchChainCta } =
    options;

  if (!hasPublicKey(auth)) {
    {
      return {
        type: 'CONNECT',
        prompt: connectCta.prompt,
      };
    }
  }

  if (!isConnectedToChainId({ auth, chainId })) {
    return {
      type: 'SWITCH_CHAIN' as const,
      status: switchChainCta.status,
      prompt: switchChainCta.prompt,
    };
  }

  if (bridgeCta && isBigInt(auth.balance) && isZero(auth.balance)) {
    return {
      type: 'BRIDGE' as const,
      prompt: bridgeCta.prompt,
    };
  }

  return {
    type: 'WEB_3_TX' as const,
    config: action.config,
    trackedTx: action.trackedTx,
  };
};

export const createWeb3StepperControls = (
  options: Pick<StepperIncrementControls, 'onClick'> & {
    count: number;
    simulation: MinimalUseSimulateContractResult;
  }
): StepperIncrementControls => {
  const { count, simulation, onClick } = options;

  // Prevent users from choosing a quantity below 1
  if (count <= 0) {
    return {
      isDisabled: true,
      onClick: noop,
      // We're assuming users will understand why they can't decrement to zero.
      tooltipContent: null,
    };
  }

  const parsedError = extractPrepareContractWriteRevertReason(simulation);

  return {
    isDisabled: simulation.isError || simulation.isLoading,
    onClick,
    tooltipContent: parsedError,
  };
};
