import { ErrorIcon } from '@f8n/icons';
import { styled } from '@f8n-frontend/stitches';
import { ComponentProps } from '@stitches/react';

import Box from 'components/base/Box';

import { getHelpCenterPath } from 'utils/help-center';
import { HelpCenterArticleName } from 'utils/router';

import Link from './Link';

type MessageContainerProps = ComponentProps<typeof Container>;

type MessageProps = {
  message?: string | React.ReactNode;
  title?: string;
  variant?: MessageContainerProps['variant'];
  icon?: boolean;
  helpCenterArticle?: HelpCenterArticleName;
};

export default function Message(props: MessageProps) {
  const { title, message, variant, helpCenterArticle, icon = true } = props;

  const helpCenterHref = helpCenterArticle
    ? getHelpCenterPath(helpCenterArticle)
    : undefined;

  return (
    <Container variant={variant}>
      {icon && (
        <Icon>
          <ErrorIcon size={2} />
        </Icon>
      )}
      <Box as="h3">
        {title && <Title>{title}</Title>}
        {message && <MessageText> {message}</MessageText>}
        {helpCenterHref && (
          <>
            {' '}
            <Link
              css={{
                display: 'inline',
                fontSize: '$1',
                fontWeight: '$regular',
              }}
              target="_blank"
              rel="noopener noreferrer"
              variant="current"
              href={helpCenterHref}
              weight="medium"
              hasUnderline
            >
              Learn more.
            </Link>
          </>
        )}
      </Box>
    </Container>
  );
}

const Title = styled('span', {
  fontSize: '$1',
  fontWeight: '$semibold',
});

const MessageText = styled('span', {
  fontSize: '$1',
  fontWeight: '$regular',
});

const Icon = styled('div', {
  padding: '$3',
  position: 'relative',
  borderRadius: '$round',
  svg: {
    display: 'block',
  },
});

const Container = styled('div', {
  padding: '$5',
  borderRadius: '$4',
  border: 'solid 1px',

  gap: '$3',
  display: 'flex',
  alignItems: 'flex-start',

  variants: {
    variant: {
      strong: {
        borderColor: '$black100',
        backgroundColor: '$black100',
        [`${Title}`]: {
          color: '$white100',
        },
        [`${MessageText}`]: {
          color: '$white70',
        },
        [`${Icon}`]: {
          color: '$white100',
          backgroundColor: '$white15',
        },
      },
      subtle: {
        borderColor: '$black10',
        backgroundColor: '$black2',
        [`${Title}`]: {
          color: '$black100',
        },
        [`${MessageText}`]: {
          color: '$black70',
        },
        [`${Icon}`]: {
          color: '$black100',
          backgroundColor: '$black5',
        },
      },
      red: {
        border: 'none',
        backgroundColor: '$red4',
        color: '$white100',
        display: 'flex',
        alignItems: 'center',
        gap: '$1',
        paddingLeft: '$2',
        [`${Icon}`]: {
          color: '$white100',
          backgroundColor: '$red4',
        },
      },
    },
  },

  defaultVariants: {
    variant: 'subtle',
  },
});
