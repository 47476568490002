import { useReadContract } from 'wagmi';

import { NFTMarket, getOffer } from 'lib/abis/NFTMarket';
import { getContractAddress } from 'lib/addresses';

import { NftFilter } from 'types/Nft';

type Options = {
  enabled?: boolean;
};

export function useGetOffer(variables: NftFilter, options: Options = {}) {
  return useReadContract({
    abi: NFTMarket,
    address: getContractAddress({
      chainId: variables.chainId,
      contractName: 'nftMarket',
    }),
    chainId: variables.chainId,
    functionName: 'getOffer',
    args: [variables.contractAddress, BigInt(variables.tokenId)],
    query: {
      select: getOffer.parseOutput,
      enabled: options.enabled ?? true,
    },
  });
}
