import { match } from 'ts-pattern';

import Button from 'components/base/Button';
import Tooltip from 'components/base/Tooltip';
import { TRANSACTION_CTA } from 'copy/transactions';

import { isWeb3CtaLoading } from 'utils/web3-cta';

import { useMarketWidget } from './MarketWidgetContext';
import {
  MARKET_WIDGET_BIDDER_MESSAGE,
  MARKET_WIDGET_CTA,
  MARKET_WIDGET_LOADING_MESSAGE,
} from './copy';

export function MarketWidgetPlaceBidCta(props: {
  isCurrentUserWinning: boolean;
}) {
  const { isCurrentUserWinning } = props;
  const { placeBidCta } = useMarketWidget();

  if (isCurrentUserWinning) {
    return (
      <Button
        as="div"
        variant="colored"
        css={{
          color: '$green5',
          backgroundColor: '$green1',
          pointerEvents: 'none',
        }}
        width="full"
      >
        {MARKET_WIDGET_BIDDER_MESSAGE.YOU_ARE_WINNING}
      </Button>
    );
  }

  return match(placeBidCta)
    .with({ type: 'CONNECT' }, ({ prompt }) => {
      return <PreBidPrompt onClick={prompt} />;
    })
    .with({ type: 'SWITCH_CHAIN' }, ({ prompt, status }) => {
      return (
        <Button.Loader
          isLoading={status === 'pending'}
          disabled={false}
          loadingLabel={TRANSACTION_CTA.CONFIRM}
          onClick={prompt}
          width="full"
        >
          {MARKET_WIDGET_CTA.BID}
        </Button.Loader>
      );
    })
    .with({ type: 'BRIDGE' }, ({ prompt }) => {
      return <PreBidPrompt onClick={prompt} />;
    })
    .with({ type: 'WEB_3_TX' }, (tx) => {
      const { config } = tx;

      return (
        <Tooltip content={config.disabledReason}>
          <Button.Loader
            isLoading={isWeb3CtaLoading(tx)}
            disabled={Boolean(config.disabledReason)}
            loadingLabel={
              config
                ? TRANSACTION_CTA.CONFIRM
                : MARKET_WIDGET_LOADING_MESSAGE.BIDDING
            }
            onClick={config.prompt}
            width="full"
          >
            {MARKET_WIDGET_CTA.BID}
          </Button.Loader>
        </Tooltip>
      );
    })
    .otherwise(() => null);
}

function PreBidPrompt(props: { onClick: () => void }) {
  return (
    <Button onClick={props.onClick} variant="primary" width="full">
      {MARKET_WIDGET_CTA.BID}
    </Button>
  );
}
