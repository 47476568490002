import Pulse from 'components/Pulse';
import Box from 'components/base/Box';
import Text from 'components/base/Text';
import { Checkout } from 'components/checkout-widget/Checkout';

export function MarketWidgetLiveAuctionStatus(props: { isEnding: boolean }) {
  const { isEnding } = props;

  return (
    <Checkout.StatusRowContainer>
      <Box>
        <Pulse color="$live" />
      </Box>
      <Text color="live" weight="medium" size={1}>
        {isEnding ? 'Checking for final bids' : 'Live auction'}
      </Text>
    </Checkout.StatusRowContainer>
  );
}
