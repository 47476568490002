import { FrameButtonMetadata } from '@coinbase/onchainkit';

import { CollectionFilter } from 'types/Collection';

import { buildFarcasterUrl } from './farcaster';
import { getPath } from './router';

export const sharedButtons: Record<'refresh', FrameButtonMetadata> = {
  refresh: {
    label: 'Refresh',
    action: 'post',
  },
};

export const editionButtons = {
  mint: (edition: CollectionFilter): FrameButtonMetadata => ({
    label: 'Mint',
    action: 'tx',
    target: buildFarcasterUrl(
      getPath.api.farcasterWebhook.edition.mint(edition)
    ),
    postUrl: buildFarcasterUrl(
      getPath.api.farcasterWebhook.edition.result(edition)
    ),
  }),
  view: (mintPageUrl: string): FrameButtonMetadata => ({
    label: 'View edition',
    action: 'link',
    target: mintPageUrl,
  }),
};

export const nftButtons = {
  buy: (nftPageUrl: string): FrameButtonMetadata => ({
    label: 'Buy now',
    action: 'link',
    target: nftPageUrl,
  }),
  bid: (nftPageUrl: string): FrameButtonMetadata => ({
    label: 'Place bid',
    action: 'link',
    target: nftPageUrl,
  }),
  view: (nftPageUrl: string): FrameButtonMetadata => ({
    label: 'View artwork',
    action: 'link',
    target: nftPageUrl,
  }),
};
