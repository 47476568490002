import { compose, flatten, isNil, map, paths, reject, uniq } from 'ramda';

import { ArtworkEvent } from 'types/Event';

export const getPublicKeysFromHistoryEvents = compose<
  ArtworkEvent[],
  string[][],
  string[],
  string[],
  string[]
>(
  reject(isNil),
  uniq,
  flatten,
  // @ts-expect-error [ramda]
  map<ArtworkEvent, string[]>(
    // @ts-expect-error [ramda]
    paths([
      ['data', 'fromAddress'],
      ['data', 'toAddress'],
    ])
  )
);
