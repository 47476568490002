import { getUnixTime, isFuture, parseJSON } from 'date-fns';
import { Address } from 'viem';

import { NftLiveAuction } from 'components/market-widget/types';

import { ApiBidFragment } from 'gql/api/api-fragments.generated';
import { ApiLiveAuction as ApiLiveAuctionQueryData } from 'gql/api/queries/live-auction.generated';
import { areKeysEqual } from 'utils/users';

type ApiLiveAuction = NonNullable<ApiLiveAuctionQueryData['liveAuction']>;

export const mapApiLiveAuctionToNftLiveAuction = (
  liveAuction: ApiLiveAuction & { auctionId: number }
): NftLiveAuction => {
  const parsedEndTime = parseJSON(liveAuction.endTime);

  const bids = flattenBids({
    bids: liveAuction.bids,
    recentBids: liveAuction.recentBids,
  });

  return {
    bids,
    bidCount: liveAuction.bids.totalItems,
    firstBid: liveAuction.firstBid,
    currentBidAmount: liveAuction.currentBidAmount,
    endsAtUnix: getUnixTime(parsedEndTime),
    status: isFuture(parsedEndTime) ? 'LIVE' : 'ENDED',
    auctionId: liveAuction.auctionId,
    highestBidder: bids[0] ? bids[0].bidder.publicKey : null,
  };
};

const flattenBids = (
  data: Pick<ApiLiveAuction, 'bids' | 'recentBids'>
): ApiBidFragment[] => {
  /** These results are sent via our API, directly from Alchemy. Items here are not indexed in our DB yet. */
  const recentOnChainBids = data.recentBids;

  const indexedBids = data.bids.items;

  const mergedBidsData = recentOnChainBids.concat(indexedBids);

  const allBids = dedupeBids(mergedBidsData);

  return allBids;
};

/**
 * Guards against cases where the API returns the same bid in both `bid.items` and `recentBids`
 */
const dedupeBids = (bids: ApiBidFragment[]) => {
  return bids.reduce<ApiBidFragment[]>((prev, curr, _index) => {
    // Sanity check that we don't have any duplicated bid amounts in the table
    if (prev.some((item) => item.amount === curr.amount)) {
      return prev;
    }

    prev.push(curr);
    return prev;
  }, []);
};

export const getIsCurrentUserWinning = (options: {
  currentUserPublicKey: Address;
  liveAuction: NftLiveAuction;
}): boolean => {
  const { currentUserPublicKey, liveAuction } = options;
  const { bids, firstBid } = liveAuction;

  if (bids.length === 0) {
    return areKeysEqual([firstBid.bidder.publicKey, currentUserPublicKey]);
  }

  if (bids[0]) {
    return areKeysEqual([bids[0].bidder.publicKey, currentUserPublicKey]);
  }

  return false;
};
