import { ExternalIcon } from '@f8n/icons';

import { LearnMoreLink } from 'components/LearnMoreLink';
import NetworkPrompt from 'components/auth/NetworkPrompt';
import Button from 'components/base/Button';
import Modal from 'components/base/Modal';

import useModalVisibility from 'hooks/use-modal-visibility';
import { ChainConfig } from 'lib/chains';

import { ModalOfType } from 'types/modal';

const MODAL_KEY = 'BRIDGE';
type BridgeModalOptions = ModalOfType<typeof MODAL_KEY>;

export default function BridgeModal() {
  const modal = useModalVisibility(MODAL_KEY);

  return (
    <Modal.Root open={modal.open} onOpenChange={modal.onOpenChange}>
      <Modal.Portal>
        <Modal.BlurOverlay />
        <Modal.PositionOverlay>
          <Modal.UnmountListener onUnmount={modal.onUnmount} />
          {modal.config && <BridgeModalWindowConnected {...modal.config} />}
        </Modal.PositionOverlay>
      </Modal.Portal>
    </Modal.Root>
  );
}

function BridgeModalWindowConnected(props: BridgeModalOptions) {
  return (
    <BridgeModalWindow chainConfig={props.chainConfig} onContinue={null} />
  );
}

export function BridgeModalWindow(props: {
  chainConfig: Extract<ChainConfig, { bridge: string }>;
  onContinue: (() => void) | null;
}) {
  const { chainConfig, onContinue } = props;

  // TODO: make dynamic when we support other L2's
  const ctaLabel = 'Bridge ETH to Base ETH';
  const title = 'You need some Base ETH';
  const description = (
    <>
      To mint works that are on the Base network, you will need to bridge ETH to
      Base ETH. <LearnMoreLink article="aboutBaseL2.bridging" />
    </>
  );

  return (
    <Modal.Window size={{ '@initial': 1, '@bp2': 2 }}>
      <NetworkPrompt
        actions={
          <>
            <Button
              as="a"
              href={chainConfig.bridge}
              target="_blank"
              rel="noreferrer"
              size={2}
              variant="primary"
              icon
            >
              <span>{ctaLabel}</span>
              <ExternalIcon />
            </Button>

            {onContinue && (
              <Button onClick={onContinue} size={2} variant="outline">
                Continue
              </Button>
            )}
          </>
        }
        chainId={chainConfig.chainId}
        description={description}
        title={title}
      />
    </Modal.Window>
  );
}
