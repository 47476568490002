import { HelpIcon, SparklesIcon } from '@f8n/icons';
import { styled } from '@f8n-frontend/stitches';
import { P, match } from 'ts-pattern';

import { isFinal5Mins } from 'utils/countdown';
import {
  abbreviateValue,
  formatInteger,
  formatTotalEthValue,
  roundEth,
} from 'utils/formatters';

import { Currency } from 'types/Currency';
import { Marketplace } from 'types/Marketplace';
import { Sale } from 'types/Sale';
import { CountdownV2 } from 'types/countdown';

import CurrencyAvatar from './CurrencyAvatar';
import { EthLabel } from './EthLabel';
import Badge from './base/Badge';
import Dash from './base/Dash';
import Flex from './base/Flex';
import Text from './base/Text';
import Tooltip from './base/Tooltip';

const SaleStatRoot = styled('div', {});

const SaleStatLabel = styled(Text, {
  whiteSpace: 'nowrap',
});
SaleStatLabel.defaultProps = {
  color: 'dim',
  lineHeight: 2,
  size: 1,
  weight: 'regular',
};

const SaleStatValue = styled(Text, {
  whiteSpace: 'nowrap',

  [`& ${Badge}`]: {
    borderRadius: '$1',
    paddingY: 2,
    paddingX: '$2',
    transform: 'translateY(-4px)',
    height: 22,
  },
});
SaleStatValue.defaultProps = {
  color: 'strong',
  lineHeight: 1,
  size: 4,
  weight: 'medium',
};

const SaleStatSmallprint = styled(Text, {
  whiteSpace: 'nowrap',

  [`& ${Badge}`]: {
    borderRadius: '$1',
    paddingY: 2,
    paddingX: '$2',
    transform: 'translateY(-4px)',
    height: 22,
  },
});
SaleStatSmallprint.defaultProps = {
  color: 'dim',
  lineHeight: 1,
  size: 0,
  weight: 'medium',
};

function SaleStatTotalEth(props: { amount: number }) {
  const { amount } = props;

  if (amount === 0) {
    return <Dash />;
  }

  return (
    <SaleStatValue>
      {formatTotalEthValue(amount)} <SaleStatEthSuffix />
    </SaleStatValue>
  );
}

function SaleStatEthPrice(props: { amount: number }) {
  const { amount } = props;

  if (amount === 0) {
    return <SaleStatValue>Free</SaleStatValue>;
  }

  return (
    <SaleStatValue>
      {roundEth(amount)} <SaleStatEthSuffix />
    </SaleStatValue>
  );
}

const SaleStatTokenValue = styled(Flex, {
  alignItems: 'center',
  gap: 6,
  fontSize: '$4',
});

/** Price represented by a quantity of a token (not ETH). */
function SaleStatTokenPrice(props: { quantity: number; currency: Currency }) {
  const { quantity, currency } = props;

  if (quantity === 0) {
    return <SaleStatValue>Free</SaleStatValue>;
  }

  return (
    <SaleStatTokenValue>
      {formatInteger(quantity)}
      <CurrencyAvatar currency={currency} />
    </SaleStatTokenValue>
  );
}

/** Renders price either in ETH or a token quantity. */
function SaleStatPrice(props: { amount: number; currency: Currency | null }) {
  if (props.currency) {
    return (
      <SaleStatTokenPrice quantity={props.amount} currency={props.currency} />
    );
  }

  return <SaleStat.EthPrice amount={props.amount} />;
}

function SaleStatMintFee(props: { amount: number; market: Marketplace }) {
  const { amount, market } = props;

  if (amount === 0) {
    return null;
  }

  const tooltipContent = match(market)
    .with('FND', () => {
      // TODO: add help center link, after adding support for hoverable tooltips
      return 'Foundation charges a small fee that is used to run the platform.';
    })
    .with('HIGHLIGHT', () => {
      return 'Highlight charges a small fee that is used to run their platform.';
    })
    .exhaustive();

  return (
    <Tooltip content={tooltipContent} placement="top" align="left" size={1}>
      <SaleStatSmallprint
        css={{
          svg: {
            height: 10,
            width: 10,
          },
        }}
      >
        +{roundEth(amount)} ETH mint fee <HelpIcon size={0} />
      </SaleStatSmallprint>
    </Tooltip>
  );
}

function SaleStatMintedCount(props: { nftCount: number; sale: Sale }) {
  const { nftCount, sale } = props;

  const supply = 'maxTokenId' in sale ? sale.maxTokenId : null;

  return (
    <SaleStatValue>
      <SaleStatMintedRatio minted={nftCount} supply={supply} />
    </SaleStatValue>
  );
}

function SaleStatMintedStatus(props: { nftCount: number; sale: Sale }) {
  const { nftCount, sale } = props;

  const supply = 'maxTokenId' in sale ? sale.maxTokenId : null;

  return (
    <SaleStatValue>
      <SaleStatMintedRatio minted={nftCount} supply={supply} />
      {match(sale)
        .with({ type: 'FND_1155' }, () => null)
        .with({ status: 'ENDED' }, () => {
          return (
            <SaleStatMintOverBadgeContainer>
              <SaleStatMintOverBadge variant="ended" />
            </SaleStatMintOverBadgeContainer>
          );
        })
        .with({ status: 'MINTED_OUT' }, () => {
          return (
            <SaleStatMintOverBadgeContainer>
              <SaleStatMintOverBadge variant="minted-out" />
            </SaleStatMintOverBadgeContainer>
          );
        })
        .with({ maxTokenId: P.number }, ({ maxTokenId }) => {
          if (nftCount === maxTokenId) {
            return (
              <SaleStatMintOverBadgeContainer>
                <SaleStatMintOverBadge variant="minted-out" />
              </SaleStatMintOverBadgeContainer>
            );
          }

          return null;
        })
        .otherwise(() => null)}
    </SaleStatValue>
  );
}

function SaleStatMintedRatio(props: { minted: number; supply: number | null }) {
  const { minted, supply } = props;

  const formattedMintCount = abbreviateValue(minted);

  if (supply === null) {
    return `${formattedMintCount}`;
  }

  const formattedSupplyCount = abbreviateValue(supply);

  return (
    <>
      {formattedMintCount}
      <Text as="span" color="dim" css={{ fontSize: 'inherit ' }}>
        /{formattedSupplyCount}
      </Text>
    </>
  );
}

const SaleStatMintOverBadgeContainer = styled('span', {
  marginLeft: '$2',
});

function SaleStatMintOverBadge(props: { variant: 'minted-out' | 'ended' }) {
  return (
    <Badge variant="pink" size={0}>
      <SparklesIcon />
      {match(props.variant)
        .with('ended', () => 'Ended')
        .with('minted-out', () => 'Minted out')
        .exhaustive()}
    </Badge>
  );
}

function SaleStatEthSuffix() {
  return <EthLabel color="strong" size={2} weight="medium" />;
}

function SaleStatSupply(props: { supply: number }) {
  const { supply } = props;
  return (
    <SaleStatValue>
      {Number.isFinite(supply) ? abbreviateValue(props.supply) : 'Open'}
    </SaleStatValue>
  );
}

function SaleStatCountdown(props: { countdown: CountdownV2 }) {
  const { countdown } = props;

  return (
    <SaleStatValue
      css={{
        color: isFinal5Mins(countdown.secondsRemaining) ? '$red4' : undefined,
        // TODO: add pulsing effect when 0s
      }}
    >
      {countdown.message ? countdown.message : '0s'}
    </SaleStatValue>
  );
}

export const SaleStat = {
  Countdown: SaleStatCountdown,
  EthPrice: SaleStatEthPrice,
  Label: SaleStatLabel,
  MintedStatus: SaleStatMintedStatus,
  MintedCount: SaleStatMintedCount,
  MintFee: SaleStatMintFee,
  MintOverBadge: SaleStatMintOverBadge,
  Price: SaleStatPrice,
  Root: SaleStatRoot,
  Supply: SaleStatSupply,
  TotalEth: SaleStatTotalEth,
};
