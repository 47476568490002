import { CheckIcon, ErrorIcon, ExternalIcon } from '@f8n/icons';
import { styled } from '@f8n-frontend/stitches';
import React from 'react';

import { DotsSmall } from 'components/SpinnerDots';
import SpinnerStroked from 'components/SpinnerStroked';
import Link from 'components/base/Link';
import Text from 'components/base/Text';
import { txStatusMessageMap } from 'copy/transactions';

import { getBlockExplorerByChainId } from 'utils/block-explorer';

import { TrackedTx, TxStatus, TxStatusCopyOptions } from 'types/Transactions';

type TransactionStatusProps = {
  heading?: Partial<TxStatusCopyOptions>;
  chainId: TrackedTx['chainId'];
  status: TrackedTx['status'];
  txHash: TrackedTx['txHash'];
};

export default function TransactionStatus(props: TransactionStatusProps) {
  const { chainId, heading = {}, status, txHash } = props;

  const explorer = getBlockExplorerByChainId(chainId);

  return (
    <Container>
      {txStatusIconMap[status]}
      <BodyWrapper>
        <TrackerHeading>
          {heading[status] ? heading[status] : txStatusMessageMap[status]}
        </TrackerHeading>
        <ExternalLink
          href={explorer.tx.getUrl({ txHash })}
          target="_blank"
          rel="noreferrer"
          variant="primary"
        >
          {explorer.tx.copy.viewOnCta} <ExternalIcon size={0} />
        </ExternalLink>
      </BodyWrapper>
    </Container>
  );
}

const Container = styled('div', {
  display: 'flex',
  alignItems: 'center',
  gap: '$4',
});

const TrackerHeading = styled(Text);
TrackerHeading.defaultProps = {
  color: 'strong',
  weight: 'semibold',
  lineHeight: 0,
  size: 1,
};

const BodyWrapper = styled('div', {
  gap: '$1',
  display: 'flex',
  flexDirection: 'column',
});

const IconWrapper = styled('div', {
  width: '42px',
  height: '42px',
  display: 'flex',
  borderRadius: '$2',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: '$black5',
});

const IconInner = styled('div', {
  width: '$icon1',
  height: '$icon1',
  borderRadius: '$round',

  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',

  svg: {
    display: 'block',
  },

  variants: {
    variant: {
      success: {
        color: '$white100',
        backgroundColor: '$green4',
      },
      error: {
        color: '$red3',
      },
    },
  },
});

const ExternalLink = styled(Link, {
  gap: '2px',
  display: 'flex',
  fontSize: '12px',
  alignItems: 'center',
  lineHeight: '$base',
});

function ErrorOrRevertedStatusIcon() {
  return (
    <IconWrapper>
      <IconInner variant="error">
        <ErrorIcon size={1} />
      </IconInner>
    </IconWrapper>
  );
}

function IndexingStatusIcon() {
  return (
    <IconWrapper>
      <DotsSmall css={{ width: '20px', backgroundSize: 'calc(100%/5) 40%' }} />
    </IconWrapper>
  );
}

const txStatusIconMap: Record<TxStatus, JSX.Element> = {
  ERROR: <ErrorOrRevertedStatusIcon />,
  INDEXING: <IndexingStatusIcon />,
  PENDING: (
    <IconWrapper>
      <SpinnerStroked size={16} />
    </IconWrapper>
  ),
  REVERTED: <ErrorOrRevertedStatusIcon />,
  SUCCESS: (
    <IconWrapper>
      <IconInner variant="success">
        <CheckIcon size={0} />
      </IconInner>
    </IconWrapper>
  ),
};
