import { keepPreviousData } from '@tanstack/react-query';

import { CollectorsTable } from 'components/CollectorsTable';
import InfiniteScroll from 'components/InfiniteScroll';
import Box from 'components/base/Box';
import Modal from 'components/base/Modal';
import { BidTableBidderRow } from 'components/market-widget/MarketWidgetLiveAuctionBidTable';
import { MARKET_WIDGET_LABEL } from 'components/market-widget/copy';
import { NftLiveAuctionStatus } from 'components/market-widget/types';
import { hasPublicKey } from 'contexts/auth/helpers';
import useAuth from 'contexts/auth/useAuth';

import { ApiBidFragment } from 'gql/api/api-fragments.generated';
import { useInfiniteLiveAuction } from 'gql/api/queries/live-auction.generated';
import { useTokenPage } from 'gql/api/queries/token-page.generated';
import useModalVisibility from 'hooks/use-modal-visibility';
import { getTokenFilter } from 'utils/inputs';
import { apiPaginator } from 'utils/react-query';

import { ModalOfType } from 'types/modal';

const MODAL_KEY = 'AUCTION_BIDDERS';
type AuctionBiddersModalOptions = ModalOfType<typeof MODAL_KEY>;

export default function AuctionBiddersModal() {
  const modal = useModalVisibility(MODAL_KEY);

  return (
    <Modal.Root open={modal.open} onOpenChange={modal.onOpenChange}>
      <Modal.Portal>
        <Modal.BlurOverlay />
        <Modal.PositionOverlay>
          <Modal.UnmountListener onUnmount={modal.onUnmount} />
          {modal.config && <AuctionBiddersModalWindow {...modal.config} />}
        </Modal.PositionOverlay>
      </Modal.Portal>
    </Modal.Root>
  );
}

function AuctionBiddersModalWindow(props: AuctionBiddersModalOptions) {
  const { nft } = props;

  const auth = useAuth();

  const currentUserPublicKey = hasPublicKey(auth) ? auth.publicKey : null;
  const nftFilter = getTokenFilter(nft);

  const liveAuctionQuery = useInfiniteLiveAuction(
    {
      currentUserPublicKey,
      nftFilter,
    },
    {
      enabled: true,
      placeholderData: keepPreviousData,
      getNextPageParam: (lastPage) => {
        if (!lastPage.liveAuction) return null;

        return apiPaginator.getNextPageParam(lastPage.liveAuction.bids);
      },
      initialPageParam: apiPaginator.initialPageParam,
    }
  );

  const activeAuctionQuery = useTokenPage(
    {
      tokenFilter: nftFilter,
    },
    {
      select: (data) => {
        if (!data.token) return null;
        const nft = data.token.__typename === 'MarketNft' ? data.token : null;

        if (!nft) return null;
        if (!nft.activeAuction) return null;

        return nft.activeAuction;
      },
    }
  );

  // TODO: add util to make deep paginated data lookup easier
  const bids = liveAuctionQuery.data
    ? liveAuctionQuery.data.pages
        .flatMap((page) => {
          return page.liveAuction ? page.liveAuction.bids.items : null;
        })
        .filter((v) => v)
    : null;

  const activeAuction = activeAuctionQuery.data
    ? activeAuctionQuery.data
    : null;

  const auctionStatus = activeAuction ? activeAuction.state : null;

  return (
    <Modal.Window
      height="normal"
      header={<Modal.Header title={MARKET_WIDGET_LABEL.ALL_BIDS} />}
      size={0}
    >
      <Box
        css={{
          [`${CollectorsTable.RowInner}`]: {
            paddingX: '$6',
          },
        }}
      >
        {bids &&
          bids
            .filter((item): item is ApiBidFragment => item !== null)
            .map((bid, index, array) => {
              const isWinningBid = index === 0;
              const isOpeningBid = index === array.length - 1;

              return (
                <BidTableBidderRow
                  key={index}
                  // casting because we're assuming this modal is only opened when auction is live
                  auctionStatus={auctionStatus as NftLiveAuctionStatus}
                  bid={bid}
                  isHydrated
                  isWinningBid={isWinningBid}
                  isOpeningBid={isOpeningBid}
                />
              );
            })}
        {liveAuctionQuery.hasNextPage && (
          <InfiniteScroll
            handleInView={() => {
              liveAuctionQuery.fetchNextPage();
            }}
          />
        )}
      </Box>
    </Modal.Window>
  );
}
