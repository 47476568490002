import { BaseIcon, EthereumIcon } from '@f8n/icons';
import { styled } from '@f8n-frontend/stitches';
import { match } from 'ts-pattern';

import Modal from 'components/base/Modal';
import {
  getConnectToNetworkCopy,
  getPleaseConnectToNetworkToContinueCopy,
} from 'copy/auth';

import { CHAINS, ChainId } from 'lib/chains';
import { getChainConfigById } from 'utils/network';

type NetworkPromptProps = {
  actions: React.ReactNode;
  chainId: ChainId;
  description: React.ReactNode;
  title: string;
};

export default function NetworkPrompt(props: NetworkPromptProps) {
  const { actions, chainId, description, title } = props;

  const chainConfig = getChainConfigById(chainId);

  return (
    <RootContainer>
      <HeadingContainer>
        <ChainIconContainer>
          {match(chainId)
            .with(CHAINS.base.chainId, CHAINS.baseSepolia.chainId, () => {
              return <BaseIcon />;
            })
            .otherwise(() => {
              return <EthereumIcon />;
            })}
        </ChainIconContainer>
        <Modal.BodyTitle
          align="center"
          description={
            description || getConnectToNetworkCopy(chainConfig.chain)
          }
          title={
            title || getPleaseConnectToNetworkToContinueCopy(chainConfig.chain)
          }
        />
      </HeadingContainer>
      <Modal.ContentStack css={{ gap: '$4' }}>{actions}</Modal.ContentStack>
    </RootContainer>
  );
}

const RootContainer = styled('div', {
  gap: '$6',
  display: 'flex',
  flexDirection: 'column',
  '@bp2-max': {
    paddingY: '$3',
  },
  '@bp2': {
    gap: '$7',
  },
});

const HeadingContainer = styled('div', {
  gap: '$5',
  display: 'flex',
  flexDirection: 'column',
});

const ChainIconContainer = styled('div', {
  display: 'flex',
  flexAlign: 'center',
  justifyContent: 'center',

  '& svg': {
    width: '54px',
    height: '54px',
  },
});
