import { keepPreviousData, useQueryClient } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { P, match } from 'ts-pattern';
import { parseEther } from 'viem';
import { useWaitForTransactionReceipt } from 'wagmi';

import useAnalytics from 'contexts/analytics/useAnalytics';
import { hasPublicKey, isMyAddress } from 'contexts/auth/helpers';
import useAuth from 'contexts/auth/useAuth';
import useTransactionStore from 'state/stores/transactions';

import { useLiveAuction } from 'gql/api/queries/live-auction.generated';
import { useTokenPage } from 'gql/api/queries/token-page.generated';
import { useQueryEffects } from 'hooks/react-query';
import useModal from 'hooks/use-modal';
import { useGetMinBidAmount } from 'hooks/web3/use-get-min-bid-amount';
import { useGetReserveAuction } from 'hooks/web3/use-get-reserve-auction';
import usePlaceBid, {
  PLACE_BID_ACTION,
  ProposedBid,
} from 'hooks/web3/use-place-bid';
import { useWatchBidEvent } from 'hooks/web3/use-watch-bid-event';
import { useWeb3ActionCta } from 'hooks/web3/use-web3-action-cta';
import { ZERO_ADDRESS } from 'lib/constants';
import { validNumberSchema } from 'schemas/forms/price';
import { mapApiLiveAuctionToNftLiveAuction } from 'schemas/parse/live-auction';
import { ethToNumber } from 'utils/bigint';
import { isAuctionWithinEndedThreshold, isUpcoming } from 'utils/dates/dates';
import { formatBidAmount } from 'utils/formatters';
import { getTokenFilter } from 'utils/inputs';
import { mapApiMediaToPreviewMediaAsset } from 'utils/media-preview';
import { isZero } from 'utils/numbers';
import { extractGeneratedQueryKeyRoot } from 'utils/react-query';
import { selectTransactionByAction } from 'utils/transaction-tracking';
import { areKeysEqual } from 'utils/users';

import { MarketWidgetNftSummaryPayload } from 'types/Analytics';
import { ERC721Token, TokenFilter } from 'types/Token';
import { WorldOverview } from 'types/World';
import { MediaAsset } from 'types/media';

import {
  FallbackMarketWidget,
  MarketWidget,
} from '../market-widget/MarketWidget';
import { MarketWidgetContext } from '../market-widget/MarketWidgetContext';
import { NftLiveAuction } from '../market-widget/types';
import { getAuctionStatus } from '../market-widget/utils';

export default function TokenPageMarketWidgetNftConnected(props: {
  media: MediaAsset | null;
  nft: ERC721Token;
  tokenFilter: TokenFilter;
  world: WorldOverview | null;
}) {
  const { media, nft, tokenFilter, world } = props;

  const auth = useAuth();
  const analytics = useAnalytics();
  const modal = useModal();
  const queryClient = useQueryClient();

  const auctionId = nft.activeAuction ? nft.activeAuction.id : 0;

  const trackedPlaceBid = useTransactionStore(
    selectTransactionByAction({
      action: PLACE_BID_ACTION,
    })
  );

  const currentUserPublicKey = hasPublicKey(auth) ? auth.publicKey : null;

  /**
   * the liveAuction query field is cached using redis so
   * we are safe to poll it as a source of truth for whether
   * a live auction is happening
   */
  const liveAuctionQuery = useLiveAuction(
    { currentUserPublicKey, nftFilter: tokenFilter, page: 0, perPage: 24 },
    {
      enabled: auctionId > 0,
      placeholderData: keepPreviousData,
      refetchInterval(query) {
        if (!query.state.data || !query.state.data.liveAuction) {
          return 5000;
        }

        if (isUpcoming(query.state.data.liveAuction.endTime)) {
          return 2500;
        }

        return 5000;
      },
    }
  );

  useQueryEffects(liveAuctionQuery, {
    onSuccess: (data) => {
      if (!data.liveAuction) return;

      /**
       * if the auction has ended in the past five mins invalidate
       * the nft page cache to reflect the updated auction status
       */
      if (isAuctionWithinEndedThreshold(data.liveAuction.endTime)) {
        queryClient.invalidateQueries({
          queryKey: [extractGeneratedQueryKeyRoot(useTokenPage.getKey)],
        });
      }
    },
  });

  const marketNftPreview = {
    ...nft,
    media: mapApiMediaToPreviewMediaAsset(nft.media),
  };

  const isOwner = isMyAddress(auth, nft.owner.publicKey);
  const isCreatorOwner = areKeysEqual([
    nft.creator.publicKey,
    nft.owner.publicKey,
  ]);

  const [bidAmount, setBidAmount] = useState('');

  /**
   * always fetch the reserve auction and the minimum
   * bid amount RPC-first on the initial page load
   */
  const reserveAuctionQuery = useGetReserveAuction(
    { chainId: nft.chainId, auctionId },
    { enabled: auctionId > 0 }
  );

  const minBidAmountQuery = useGetMinBidAmount(
    { chainId: nft.chainId, auctionId },
    { enabled: auctionId > 0 }
  );

  const refetchAuctionQueries = async () => {
    await Promise.all([
      liveAuctionQuery.refetch(),
      queryClient.invalidateQueries({
        queryKey: reserveAuctionQuery.queryKey,
      }),
      queryClient.invalidateQueries({
        queryKey: minBidAmountQuery.queryKey,
      }),
    ]);
  };

  const getLiveAuction = (): NftLiveAuction | null => {
    const liveAuction = liveAuctionQuery.data?.liveAuction;

    if (
      !reserveAuctionQuery.isSuccess ||
      !minBidAmountQuery.isSuccess ||
      reserveAuctionQuery.data.bidder === ZERO_ADDRESS
    ) {
      return null;
    }

    /**
     * if there is no live auction data from the API but we have
     * onchain state, we can use that to render the market widget
     */
    if (!liveAuction) {
      const endsAtUnix = Number(reserveAuctionQuery.data.endTime);

      const firstBid = {
        amount: ethToNumber(reserveAuctionQuery.data.amount),
        bidder: {
          publicKey: reserveAuctionQuery.data.bidder,
          profileImageUrl: null,
          username: null,
        },
      };

      return {
        auctionId,
        bids: [firstBid],
        bidCount: 1,
        firstBid,
        endsAtUnix,
        currentBidAmount: firstBid.amount,
        status: getAuctionStatus(endsAtUnix),
        highestBidder: firstBid.bidder.publicKey,
      };
    }

    const mappedLiveAuction = mapApiLiveAuctionToNftLiveAuction({
      ...liveAuction,
      auctionId,
    });

    /**
     * in the case where we have data directly from the RPC call
     * we will overwrite the current bid amount and the status
     * to achieve greater levels of liveness
     */
    const endsAtUnix = Number(reserveAuctionQuery.data.endTime);

    return {
      ...mappedLiveAuction,
      endsAtUnix,
      currentBidAmount: ethToNumber(reserveAuctionQuery.data.amount),
      status: getAuctionStatus(endsAtUnix),
      highestBidder: reserveAuctionQuery.data.bidder,
    };
  };

  const liveAuction = getLiveAuction();

  const getFirstBidderPublicKey = () => {
    if (liveAuctionQuery.data && liveAuctionQuery.data.liveAuction) {
      return liveAuctionQuery.data.liveAuction.firstBid.bidder.publicKey;
    }
    return undefined;
  };

  const firstBidderPublicKey = getFirstBidderPublicKey();

  const proposedBid: ProposedBid | null = match({
    activeAuction: nft.activeAuction,
  })
    .with(
      { activeAuction: { id: P.number } },
      ({ activeAuction }): ProposedBid => {
        return {
          auctionId: activeAuction.id,
          amount: bidAmount,
          referrer: ZERO_ADDRESS, // TODO: add referrer support
        };
      }
    )
    .otherwise(() => null);

  const placeBid = usePlaceBid({
    nftFilter: tokenFilter,
    proposedBid,
    worldId: world ? world.id : null,
  });

  useWatchBidEvent(
    { auctionId, chainId: nft.chainId },
    {
      onBidEvent: async () => {
        await refetchAuctionQueries();
        placeBid.reset();
      },
    }
  );

  useEffect(() => {
    if (!firstBidderPublicKey) return;

    refetchAuctionQueries();
  }, [firstBidderPublicKey]);

  const awaitedTransaction = useWaitForTransactionReceipt({
    hash: placeBid.txHash || undefined,
  });

  useQueryEffects(awaitedTransaction, {
    onSuccess: refetchAuctionQueries,
  });

  useQueryEffects(minBidAmountQuery, {
    onSuccess(minBidAmount) {
      /**
       * if the current bid amount is unset
       */
      if (!bidAmount) {
        return setBidAmount(formatBidAmount(minBidAmount));
      }

      const parsedBid = validNumberSchema.safeParse(bidAmount);

      /**
       * if the current bid amount is less than the minimum bid amount
       */
      if (parsedBid.success && parseEther(parsedBid.data) < minBidAmount) {
        return setBidAmount(formatBidAmount(minBidAmount));
      }
    },
  });

  const placeBidCtaConfig = useWeb3ActionCta({
    chainId: nft.chainId,
    config: placeBid,
    context: 'on-page',
    trackedTx: trackedPlaceBid,
  });

  const analyticsNftSummary: MarketWidgetNftSummaryPayload = {
    nft: getTokenFilter(nft),
  };

  const getMarketWidget = () => {
    /**
     * if the live auction query is in an error state
     * and the live auction should be fetched
     */
    if (
      (liveAuctionQuery.isError || liveAuctionQuery.failureReason) &&
      minBidAmountQuery.isSuccess
    ) {
      if (!reserveAuctionQuery.isSuccess || isZero(minBidAmountQuery.data)) {
        return (
          <FallbackMarketWidget
            chainId={nft.chainId}
            auctionStatus="FINALIZED"
            currentUserPublicKey={currentUserPublicKey}
          />
        );
      }

      const highestBidder = reserveAuctionQuery.data.bidder;
      const endsAtUnix = Number(reserveAuctionQuery.data.endTime);

      const getAuctionStatus = () => {
        if (highestBidder === ZERO_ADDRESS) {
          return 'OPEN';
        } else if (endsAtUnix < Date.now() / 1000) {
          return 'ENDED';
        } else {
          return 'LIVE';
        }
      };

      return (
        <FallbackMarketWidget
          chainId={nft.chainId}
          highestBidder={highestBidder}
          currentUserPublicKey={currentUserPublicKey}
          auctionId={auctionId}
          endsAtUnix={endsAtUnix}
          currentBidAmount={ethToNumber(reserveAuctionQuery.data.amount)}
          minimumBidAmount={ethToNumber(minBidAmountQuery.data)}
          auctionStatus={getAuctionStatus()}
        />
      );
    } else {
      return (
        <MarketWidget
          currentUserPublicKey={currentUserPublicKey}
          liveAuction={liveAuction}
          isOwner={isOwner}
          nft={nft}
        />
      );
    }
  };

  return (
    <MarketWidgetContext.Provider
      value={{
        bid: {
          amount: bidAmount,
          setAmount: setBidAmount,
        },
        placeBidCta: placeBidCtaConfig,
        onAcceptOffer: () => {
          if (!nft.activeOffer) return;

          analytics.track({
            name: 'market_widget_accept_offer_cta',
            ...analyticsNftSummary,
          });

          modal.setModal({
            type: 'ACCEPT_OFFER',
            nft: {
              ...nft,
              media: media && media.type !== 'model' ? media : null,
            },
          });
        },
        onBurn: isCreatorOwner
          ? () => {
              modal.setModal({
                type: 'BURN_NFT',
                creator: nft.creator,
                nft,
              });
            }
          : null,
        onBuyNow: () => {
          if (!nft.activeBuyNow) return;

          analytics.track({
            name: 'market_widget_buy_now_cta',
            ...analyticsNftSummary,
          });

          modal.setModal({
            type: 'BUY_NOW',
            version: 2,
            nft: marketNftPreview,
          });
        },
        onMakeOffer: () => {
          analytics.track({
            name: 'market_widget_make_offer_cta',
            ...analyticsNftSummary,
          });

          modal.setModal({
            type: 'MAKE_OFFER',
            version: 2,
            nft: marketNftPreview,
          });
        },
        onEditListing: () => {
          if (!isOwner) return;

          analytics.track({
            name: 'market_widget_edit_listing_cta',
            ...analyticsNftSummary,
          });

          modal.setModal({
            type: 'SELL',
            nft,
          });
        },
        onSell: () => {
          if (!isOwner) return;

          analytics.track({
            name: 'market_widget_sell_cta',
            ...analyticsNftSummary,
          });

          modal.setModal({
            type: 'SELL',
            nft,
          });
        },
        onTransfer: () => {
          analytics.track({
            name: 'market_widget_transfer_cta',
            ...analyticsNftSummary,
          });

          modal.setModal({
            type: 'TRANSFER_NFT',
            ownerPublicKey: nft.owner.publicKey,
            nft,
          });
        },
        onUnlist: () => {
          if (!isOwner) return;

          analytics.track({
            name: 'market_widget_remove_listing_cta',
            ...analyticsNftSummary,
          });

          modal.setModal({
            type: 'REMOVE_LISTINGS',
            nft,
          });
        },
        onSettle: ({ auctionId }) => {
          analytics.track({
            name: 'market_widget_settle_cta',
            ...analyticsNftSummary,
          });

          modal.setModal({
            type: 'SETTLE_AUCTION',
            auctionId,
            chainId: nft.chainId,
            contractAddress: nft.contractAddress,
            tokenId: nft.tokenId,
          });
        },
        onViewMoreBids: () => {
          analytics.track({
            name: 'market_widget_view_more_bids',
            ...analyticsNftSummary,
          });

          modal.setModal({
            type: 'AUCTION_BIDDERS',
            nft: tokenFilter,
          });
        },
        currency: null,
      }}
    >
      {getMarketWidget()}
    </MarketWidgetContext.Provider>
  );
}
