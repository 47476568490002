import { UseQueryOptions } from '@tanstack/react-query';
import { propOr } from 'ramda';
import { Address } from 'viem';

import {
  useUsersByPublicKeys as useUsersByPublicKeysBaseHook,
  UsersByPublicKeys,
  UsersByPublicKeysVariables,
} from 'gql/hasura/queries/users-by-public-keys.generated';
import { getPublicKeysFromHistoryEvents } from 'queries/users-v2';
import { getAddress } from 'utils/address';
import { notEmptyOrNil } from 'utils/helpers';

import { UserLight } from 'types/Account';
import { ArtworkEvent } from 'types/Event';

export async function getUsersByPublicKeys(
  publicKeys: Address[]
): Promise<UserLight[]> {
  const uniquePublicKeys = Array.from(new Set(publicKeys));
  const getUsers = useUsersByPublicKeysBaseHook.fetcher({
    publicKeys: uniquePublicKeys,
  });
  const res = await getUsers();
  return res.users;
}

export default function useUsersByPublicKeys<TData = UsersByPublicKeys>(
  variables: UsersByPublicKeysVariables,
  options?: UseQueryOptions<UsersByPublicKeys, Error, TData>
) {
  const { publicKeys } = variables;
  return useUsersByPublicKeysBaseHook(
    {
      publicKeys: Array.isArray(publicKeys)
        ? publicKeys.map((publicKey) => getAddress(publicKey))
        : getAddress(publicKeys),
    },
    options
  );
}

interface UsersFromHistoryEventsVariables {
  events: ArtworkEvent[];
}

export function useUsersFromHistoryEvents(
  variables: UsersFromHistoryEventsVariables,
  options: Pick<UseQueryOptions<UsersByPublicKeys, Error>, 'enabled'> = {}
) {
  const events = propOr<
    [],
    UsersFromHistoryEventsVariables,
    UsersFromHistoryEventsVariables['events']
  >([], 'events', variables);

  return useUsersByPublicKeys(
    // @ts-expect-error null-checks
    { publicKeys: getPublicKeysFromHistoryEvents(events) },
    {
      ...options,
      queryKey: ['users', 'from-history-events', events],
      enabled: notEmptyOrNil(events),
      initialData: {
        users: [],
      },
    }
  );
}

export function buildUserCache(users: UserLight[]) {
  return users.reduce(
    (acc, user) => {
      return {
        ...acc,
        [user.publicKey]: user,
      };
    },
    {} as Record<string, UserLight>
  );
}
