import { darkMode, styled } from '@f8n-frontend/stitches';
import { getUnixTime } from 'date-fns';

import Text from 'components/base/Text';

import useCountdown from 'hooks/use-countdown';

const CheckoutRoot = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  gap: '$6',
  borderTop: '1px solid $black5',
  borderBottom: '1px solid $black5',
  paddingTop: '$6',
  paddingBottom: '$6',

  [darkMode]: {
    borderColor: '$black10',
  },
});

const CheckoutStack = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  gap: '$4',
});

const CheckoutStatRowContainer = styled('div', {
  display: 'flex',
  gap: '$6',
});

const CheckoutStatusRowContainer = styled('div', {
  display: 'flex',
  alignItems: 'center',
  gap: '$1',
});

function CheckoutSaleCountdown(props: { endDate: Date }) {
  const countdown = useCountdown(getUnixTime(props.endDate), { maxUnits: 3 });

  if (!countdown.message)
    return (
      <Text size={1} color="dim">
        Ending…
      </Text>
    );

  return (
    <Text size={1} color="dim">
      Ends in{' '}
      <Text as="span" weight="medium" color="strong">
        {countdown.message}
      </Text>
    </Text>
  );
}

function CheckoutCtaSmallprintText(props: { children: React.ReactNode }) {
  return (
    <Text css={{ textAlign: 'center' }} size={0} color="dim">
      {props.children}
    </Text>
  );
}

export const Checkout = {
  // Stacks
  Root: CheckoutRoot,
  Stack: CheckoutStack,

  // Text
  CtaSmallprintText: CheckoutCtaSmallprintText,

  // Rows
  StatRowContainer: CheckoutStatRowContainer,
  StatusRowContainer: CheckoutStatusRowContainer,

  // Interactive
  SaleEndCountdown: CheckoutSaleCountdown,
};
