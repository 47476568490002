import { NFTMarket } from '@f8n/f8n-contracts/dist/abis/NFTMarket.generated';
import { useEffect } from 'react';

import { getContractAddress } from 'lib/addresses';
import { ChainId } from 'lib/chains';
import { getViemWebSocketClientByChainId } from 'lib/wagmi';

type WatchBidEventVariables = {
  auctionId: number;
  chainId: ChainId;
};

type WatchBidEventOptions = {
  onBidEvent: () => Promise<void>;
};

export function watchBidEvent(
  variables: WatchBidEventVariables,
  options: WatchBidEventOptions
) {
  const { chainId, auctionId } = variables;

  const client = getViemWebSocketClientByChainId(chainId);

  return client.watchContractEvent({
    address: getContractAddress({
      chainId,
      contractName: 'nftMarket',
    }),
    abi: NFTMarket,
    eventName: 'ReserveAuctionBidPlaced',
    args: {
      auctionId: BigInt(auctionId),
    },
    onLogs: async () => {
      await options.onBidEvent();
    },
  });
}

export function useWatchBidEvent(
  variables: WatchBidEventVariables,
  options: WatchBidEventOptions
) {
  useEffect(() => {
    if (variables.auctionId === 0) return;

    const unwatch = watchBidEvent(variables, options);
    return unwatch;
  }, [variables.auctionId, variables.chainId]);
}
