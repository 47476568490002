import { EthereumIcon, BaseIcon } from '@f8n/icons';
import { match } from 'ts-pattern';

import { ChainConfig } from 'lib/chains';
import { isBaseChainConfig } from 'utils/network';

import { IconProps } from 'types/icon';

type ChainIconProps = Pick<IconProps, 'size'> & {
  config: ChainConfig;
};

export default function ChainIcon(props: ChainIconProps) {
  const config = props.config;

  const Icon = match(config)
    .when(isBaseChainConfig, () => BaseIcon)
    .otherwise(() => EthereumIcon);

  return <Icon size={props.size} />;
}
