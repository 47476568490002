import {
  Abi,
  AbiParametersToPrimitiveTypes,
  ExtractAbiFunction,
  ExtractAbiFunctionNames,
} from 'abitype';
import { UseSimulateContractParameters } from 'wagmi';

import { ChainId } from 'lib/chains';

import { GetAddress } from './types';

export type SimulateContractWriteHelperOptions = {
  enabled?: boolean;
};

type DisabledSimulateContractWriteConfig = {
  abi: Abi;
  query: {
    enabled: false;
    retry: false;
  };
};

/**
 * Use this when you want to prevent `useSimulateContract` from doing anything.
 */
export const DO_NOT_SIMULATE_CONTRACT_WRITE: DisabledSimulateContractWriteConfig =
  {
    abi: [],
    query: {
      enabled: false,
      retry: false,
    },
  };

/**
 * This is a curried function that receives basic configuration for a contract,
 * and returns a helper that can create a configuration object to pass down to `useSimulateContract`.
 */
export function createSimulateWriteConfigForContract<
  TAbi extends Abi,
>(contract: { abi: TAbi; getAddress: GetAddress }) {
  type IsPayableFunction<TFunctionName extends string> = ExtractAbiFunction<
    TAbi,
    TFunctionName
  >['stateMutability'] extends 'payable'
    ? true
    : false;

  function createSimulateConfig<
    TFunctionName extends ExtractAbiFunctionNames<
      TAbi,
      'nonpayable' | 'payable'
    >,
  >(
    /** Name of the function. Note that the type is derived from the abi. */
    functionName: TFunctionName,
    /**  */
    options: SimulateContractWriteHelperOptions & {
      /** args to pass down to the contract */
      args:
        | AbiParametersToPrimitiveTypes<
            ExtractAbiFunction<TAbi, TFunctionName>['inputs']
          >
        | undefined;
      /** chainId of the contract to call */
      chainId: ChainId;
    } & (IsPayableFunction<TFunctionName> extends true
        ? {
            /** enforce that a `value` is provided for payable functions */
            value: bigint;
          }
        : {
            /** dissalow `value` for nonpayable functions */
            value?: never;
          })
  ): UseSimulateContractParameters {
    return {
      abi: contract.abi as Abi,
      address: contract.getAddress({ chainId: options.chainId }),
      args: options.args as unknown[],
      chainId: options.chainId,
      functionName,
      query: {
        enabled: options.enabled,
        retry: false,
      },
      value: options.value,
    };
  }

  return createSimulateConfig;
}
