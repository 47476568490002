import { CheckIcon, ErrorIcon } from '@f8n/icons';
import { styled } from '@f8n-frontend/stitches';
import { useState } from 'react';
import { useWriteContract, useSimulateContract } from 'wagmi';

import SmallArtworkCard from 'components/SmallArtworkCard';
import TransactionModal from 'components/TransactionModal';
import InputField from 'components/base/InputField';
import Modal from 'components/base/Modal';
import Text from 'components/base/Text';
import UserTag from 'components/users/UserTag';
import { hasPublicKey } from 'contexts/auth/helpers';
import useAuth from 'contexts/auth/useAuth';
import useTransactionStore from 'state/stores/transactions';

import { useNft } from 'gql/api/queries/nft.generated';
import useUserByPublicKey from 'hooks/queries/api/use-user-by-public-key';
import useModalVisibility from 'hooks/use-modal-visibility';
import { useExhibitionIdForNft } from 'hooks/web3/use-exhibition-id-for-nft';
import { NFTCollection } from 'lib/abis/NFTCollection';
import { transferNftSchema } from 'schemas/forms/price';
import { getAddress, isAddress } from 'utils/address';
import { truncateAddress } from 'utils/helpers';
import { getTokenFilter } from 'utils/inputs';
import { selectNftWithPreviewMedia } from 'utils/nft';
import { extractErrorMessage } from 'utils/revert-reasons';

import { ModalOptions } from 'types/modal';

const MODAL_KEY = 'TRANSFER_NFT';
const ACTION = 'transfer-nft';

type TransferNftModalOptions = ModalOptions<typeof MODAL_KEY>;

export default function TransferNftModal() {
  const modal = useModalVisibility(MODAL_KEY);

  return (
    <Modal.Root open={modal.open} onOpenChange={modal.onOpenChange}>
      <Modal.Portal>
        <Modal.BlurOverlay />
        <Modal.PositionOverlay>
          <Modal.UnmountListener onUnmount={modal.onUnmount} />
          {modal.config && <TransferNftModalWindow {...modal.config} />}
        </Modal.PositionOverlay>
      </Modal.Portal>
    </Modal.Root>
  );
}

function TransferNftModalWindow(props: TransferNftModalOptions) {
  const { nft, ownerPublicKey } = props;

  const nftFilter = getTokenFilter(nft);
  const { chainId, contractAddress, tokenId } = nftFilter;

  const auth = useAuth();
  const nftQuery = useNft(nftFilter, {
    select: selectNftWithPreviewMedia,
  });

  const txStore = useTransactionStore();

  const [receiverAddress, setReceiverAddress] = useState('');
  const isValidAddress = isAddress(receiverAddress);

  const userByPublicKey = useUserByPublicKey(
    { publicKey: receiverAddress },
    { enabled: isAddress(receiverAddress) }
  );

  const parsedTransfer = transferNftSchema.safeParse({
    senderAddress: hasPublicKey(auth) ? auth.publicKey : '',
    receiverAddress,
  });

  const simulateTransferNft = useSimulateContract({
    abi: NFTCollection,
    functionName: 'safeTransferFrom',
    address: getAddress(contractAddress),
    chainId,
    query: {
      enabled: parsedTransfer.success,
      retry: false,
    },
    args: parsedTransfer.success
      ? [
          parsedTransfer.data.senderAddress,
          parsedTransfer.data.receiverAddress,
          BigInt(tokenId),
        ]
      : undefined,
  });

  const exhibitionIdForNft = useExhibitionIdForNft({
    nftFilter,
    sellerPublicKey: ownerPublicKey,
  });

  const contractWrite = useWriteContract({
    mutation: {
      onSuccess: (txHash) => {
        txStore.startTracking({
          chainId,
          ui: 'toast',
          action: {
            name: ACTION,
            worldId: exhibitionIdForNft.data
              ? Number(exhibitionIdForNft.data.worldId)
              : null,
          },
          txHash,
          title: {
            PENDING: 'Transferring NFT…',
            SUCCESS: 'NFT transferred',
          },
          description: {
            PENDING:
              'The NFT is being transferred. You can close this if you like.',
            SUCCESS: 'This NFT has been transferred to the new owner.',
          },
        });
      },
    },
  });

  return (
    <TransactionModal
      chainId={chainId}
      preSignPrompt={{
        type: 'skip',
      }}
    >
      <TransactionModal.Content
        action={ACTION}
        chainId={chainId}
        txHash={contractWrite.data || null}
        footer={
          <Modal.Footer>
            <TransactionModal.TransactionButton
              chainId={chainId}
              isLoading={contractWrite.isPending || Boolean(contractWrite.data)}
              isDisabled={!simulateTransferNft.isSuccess}
              write={() => {
                if (simulateTransferNft.isSuccess) {
                  contractWrite.writeContract(simulateTransferNft.data.request);
                }
              }}
              error={extractErrorMessage({
                simulateError: simulateTransferNft.error,
                parsedZodSchema: parsedTransfer,
              })}
              label="Transfer NFT"
            />
          </Modal.Footer>
        }
      >
        <TransactionModal.Body>
          <Modal.BodyTitle
            align="left"
            title="Transfer NFT"
            description="Transfer the NFT to another user or wallet by entering an Ethereum address below."
          />
          <TransactionModal.Body css={{ gap: '$5' }}>
            {nftQuery.isSuccess && nftQuery.data.nft ? (
              <SmallArtworkCard.MarketNft nft={nftQuery.data.nft} />
            ) : (
              <SmallArtworkCard.Skeleton />
            )}
            <TransactionModal.InputWrapper>
              <InputField
                id="address"
                label="Ethereum address"
                mono
                placeholder="0x..."
                required
                size={2}
                suffix={
                  <InputIcon
                    isValidAddress={isValidAddress}
                    receiverAddress={receiverAddress}
                  />
                }
                value={
                  isValidAddress
                    ? truncateAddress(receiverAddress)
                    : receiverAddress
                }
                onChange={(ev) => setReceiverAddress(ev.target.value)}
              />
              {userByPublicKey.isSuccess && (
                <TransferringTo>
                  <Text size={1} weight="medium" color="dim">
                    Transferring to
                  </Text>
                  <UserTag
                    nameVariant="prefer-username"
                    type="avatar-text"
                    user={userByPublicKey.data}
                  />
                </TransferringTo>
              )}
            </TransactionModal.InputWrapper>
          </TransactionModal.Body>
        </TransactionModal.Body>
      </TransactionModal.Content>
    </TransactionModal>
  );
}

function InputIcon(props: {
  receiverAddress: string;
  isValidAddress: boolean;
}) {
  const { receiverAddress, isValidAddress } = props;

  if (!receiverAddress) {
    return null;
  }

  if (isValidAddress) {
    return (
      <IconHolder variant="success">
        <CheckIcon />
      </IconHolder>
    );
  }

  return (
    <IconHolder variant="error">
      <ErrorIcon />
    </IconHolder>
  );
}

const IconHolder = styled('div', {
  variants: {
    variant: {
      error: {
        color: '$red3',
      },
      success: {
        color: '$green4',
      },
    },
  },
});

const TransferringTo = styled('div', {
  gap: '$2',
  display: 'flex',
  alignItems: 'center',
});
