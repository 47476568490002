import { ModerationFrom } from 'types/Moderation';

import DMCANotice from './DMCANotice';
import WarningBlock from './WarningBlock';
import WarningTermsLink from './WarningTermsLink';

function CollectionSuspendedModerationBlock() {
  return (
    <WarningBlock
      title="This collection has been permanently removed."
      description={
        <>
          This collection was found to be in violation of the Foundation{' '}
          <WarningTermsLink /> and has been permanently removed.
        </>
      }
      status="SUSPENDED"
    />
  );
}

function CollectionTakedownModerationBlock(props: {
  moderationFrom: ModerationFrom;
}) {
  return (
    <DMCANotice
      moderationFrom={props.moderationFrom}
      title="This collection has received a DMCA takedown notice."
    />
  );
}

function CollectionUnderReviewModerationBlock() {
  return (
    <WarningBlock
      title="This collection is under review"
      description={
        <>
          This collection is currently under review by the Foundation team to
          ensure it has not violated the Foundation <WarningTermsLink />.
        </>
      }
      status="UNDER_REVIEW"
    />
  );
}

export const CollectionModeration = {
  Suspended: CollectionSuspendedModerationBlock,
  TakedownRequested: CollectionTakedownModerationBlock,
  UnderReview: CollectionUnderReviewModerationBlock,
};
