import { useWriteContract, useSimulateContract } from 'wagmi';

import SmallArtworkCard from 'components/SmallArtworkCard';
import TransactionModal from 'components/TransactionModal';
import Message from 'components/base/Message';
import Modal from 'components/base/Modal';
import useTransactionStore from 'state/stores/transactions';

import { useNft } from 'gql/api/queries/nft.generated';
import useModalVisibility from 'hooks/use-modal-visibility';
import { useExhibitionIdForNft } from 'hooks/web3/use-exhibition-id-for-nft';
import { NFTCollection } from 'lib/abis/NFTCollection';
import { getAddress } from 'utils/address';
import { getTokenFilter } from 'utils/inputs';
import { selectNftWithPreviewMedia } from 'utils/nft';
import { extractPrepareContractWriteRevertReason } from 'utils/revert-reasons';

import { ModalOptions } from 'types/modal';

const MODAL_KEY = 'BURN_NFT';
const ACTION = 'burn-nft';

type BurnNftModalOptions = ModalOptions<typeof MODAL_KEY>;

export default function BurnNftModal() {
  const modal = useModalVisibility(MODAL_KEY);

  return (
    <Modal.Root open={modal.open} onOpenChange={modal.onOpenChange}>
      <Modal.Portal>
        <Modal.BlurOverlay />
        <Modal.PositionOverlay>
          <Modal.UnmountListener onUnmount={modal.onUnmount} />
          {modal.config && <BurnNftModalWindow {...modal.config} />}
        </Modal.PositionOverlay>
      </Modal.Portal>
    </Modal.Root>
  );
}

function BurnNftModalWindow(props: BurnNftModalOptions) {
  const { creator, nft } = props;
  const { chainId, contractAddress, tokenId } = nft;

  const txStore = useTransactionStore();

  const nftFilter = getTokenFilter(nft);

  const nftQuery = useNft(nftFilter, {
    select: selectNftWithPreviewMedia,
  });

  const exhibitionByNft = useExhibitionIdForNft({
    nftFilter,
    sellerPublicKey: creator.publicKey,
  });

  const simulateBurnNft = useSimulateContract({
    abi: NFTCollection,
    functionName: 'burn',
    address: getAddress(contractAddress),
    chainId,
    args: [BigInt(tokenId)],
    query: {
      retry: false,
    },
  });

  const contractWrite = useWriteContract({
    mutation: {
      onSuccess: (txHash) => {
        txStore.startTracking({
          chainId,
          ui: 'toast',
          action: {
            name: ACTION,
            worldId: exhibitionByNft.data
              ? Number(exhibitionByNft.data.worldId)
              : null,
          },
          txHash,
          title: {
            PENDING: 'Burning…',
            SUCCESS: 'NFT burned',
          },
          description: {
            PENDING: 'The NFT is being burned. You can close this if you like.',
            SUCCESS:
              'This NFT has been burned, and it will no longer be displayed on Foundation.',
          },
        });
      },
    },
  });

  return (
    <TransactionModal
      chainId={chainId}
      preSignPrompt={{
        type: 'skip',
      }}
    >
      <TransactionModal.Content
        action={ACTION}
        chainId={chainId}
        txHash={contractWrite.data || null}
        footer={
          <Modal.Footer>
            <TransactionModal.TransactionButton
              chainId={chainId}
              isLoading={contractWrite.isPending || Boolean(contractWrite.data)}
              isDisabled={!simulateBurnNft.isSuccess}
              write={() => {
                if (simulateBurnNft.isSuccess) {
                  contractWrite.writeContract(simulateBurnNft.data.request);
                }
              }}
              error={extractPrepareContractWriteRevertReason(simulateBurnNft)}
              label="Burn NFT"
            />
          </Modal.Footer>
        }
      >
        <TransactionModal.Body>
          <Modal.BodyTitle
            align="left"
            title="Burn the NFT"
            description="Burning an NFT destroys the NFT and removes it from your creator profile on Foundation."
          />
          <TransactionModal.Body css={{ gap: '$5' }}>
            {nftQuery.isSuccess && nftQuery.data.nft ? (
              <SmallArtworkCard.MarketNft nft={nftQuery.data.nft} />
            ) : (
              <SmallArtworkCard.Skeleton />
            )}
            <Message
              title="Please note"
              message=" — once completed, this action cannot be reversed."
            />
          </TransactionModal.Body>
        </TransactionModal.Body>
      </TransactionModal.Content>
    </TransactionModal>
  );
}
