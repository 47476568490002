import { SaleStat } from 'components/SaleStat';
import { Checkout } from 'components/checkout-widget/Checkout';

import { Currency } from 'types/Currency';
import { CountdownV2 } from 'types/countdown';

import { MARKET_WIDGET_CTA, MARKET_WIDGET_LABEL } from './copy';

function MarketWidgetBuyNowPriceStat(props: {
  amount: number;
  hasReserve: boolean;
}) {
  return (
    <SaleStat.Root>
      <SaleStat.Label>
        {props.hasReserve ? MARKET_WIDGET_CTA.BUY : MARKET_WIDGET_LABEL.PRICE}
      </SaleStat.Label>
      <SaleStat.EthPrice amount={props.amount} />
    </SaleStat.Root>
  );
}

function MarketWidgetCurrentBidStat(props: {
  value: {
    amount: number;
    currency: Currency | null;
  };
}) {
  return (
    <SaleStat.Root>
      <SaleStat.Label>{MARKET_WIDGET_LABEL.CURRENT_BID}</SaleStat.Label>
      <SaleStat.Price
        amount={props.value.amount}
        currency={props.value.currency}
      />
    </SaleStat.Root>
  );
}

function MarketWidgetReservePriceStat(props: {
  amount: number;
  currency: Currency | null;
}) {
  return (
    <SaleStat.Root>
      <SaleStat.Label>{MARKET_WIDGET_LABEL.RESERVE}</SaleStat.Label>
      <SaleStat.Price amount={props.amount} currency={props.currency} />
    </SaleStat.Root>
  );
}

function MarketWidgetLiveAuctionStats(props: {
  currentBid: {
    amount: number;
    currency: Currency | null;
  };
  countdown: CountdownV2;
}) {
  const { currentBid, countdown } = props;

  return (
    <Checkout.StatRowContainer>
      <MarketWidgetCurrentBidStat value={currentBid} />
      <SaleStat.Root>
        <SaleStat.Label>{MARKET_WIDGET_LABEL.ENDS_IN}</SaleStat.Label>
        <SaleStat.Countdown countdown={countdown} />
      </SaleStat.Root>
    </Checkout.StatRowContainer>
  );
}

/** Shown to users when both a Buy Now and Reserve price is set */
function MarketWidgetBuyOptions(props: {
  buyNowPrice: number;
  reservePrice: number;
  currency: Currency | null;
}) {
  const { buyNowPrice, currency, reservePrice } = props;

  return (
    <Checkout.StatRowContainer
      // TODO: cleanup these overrides. The intention is to make these stats algin with the CTAs shown below them.
      css={{
        gap: 2,

        '& > *': {
          flexBasis: '50%',
          paddingRight: '$2',
        },
      }}
    >
      <MarketWidgetReservePriceStat amount={reservePrice} currency={currency} />
      <MarketWidgetBuyNowPriceStat amount={buyNowPrice} hasReserve />
    </Checkout.StatRowContainer>
  );
}

export const MarketWidgetSaleStats = {
  // Singular stats
  BuyNowPrice: MarketWidgetBuyNowPriceStat,
  CurrentBid: MarketWidgetCurrentBidStat,
  ReservePrice: MarketWidgetReservePriceStat,

  // Rows with multiple stats
  BuyOptions: MarketWidgetBuyOptions,
  LiveAuction: MarketWidgetLiveAuctionStats,
};
