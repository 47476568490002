import { hasPublicKey } from 'contexts/auth/helpers';
import useAuth from 'contexts/auth/useAuth';

import { ChainId } from 'lib/chains';

import Button, { LoaderButtonProps } from './Button';
import { ConnectToChainButton } from './ConnectToChainButton';
import Tooltip from './Tooltip';

type Web3ActionButtonBaseProps = Omit<LoaderButtonProps, 'onClick' | 'type'> & {
  disabledReason: string | null;
  chainId: ChainId;
};

type Web3ActionButtonProps =
  | (Web3ActionButtonBaseProps & { type: 'submit' })
  | (Web3ActionButtonBaseProps & { type: 'button'; write: () => void });

export function Web3ActionButton(props: Web3ActionButtonProps) {
  const {
    chainId,
    disabled,
    children,
    disabledReason,
    isLoading,
    loadingLabel,
    icon,
    size,
    variant = 'primary',
    width,
    type,
  } = props;

  const auth = useAuth();

  const isConnected = hasPublicKey(auth);
  const isWrongNetwork = isConnected ? auth.chainId !== chainId : true;

  if (!isConnected || isWrongNetwork) {
    return (
      <ConnectToChainButton
        chainId={chainId}
        size={size}
        type={type}
        variant={variant}
        width={width}
      />
    );
  }

  return (
    <Tooltip content={disabledReason}>
      <Button.Loader
        disabled={disabled}
        icon={icon}
        isLoading={isLoading}
        loadingLabel={loadingLabel}
        onClick={() => {
          if (type === 'button' && props.write) {
            props.write();
          }
        }}
        size={size}
        type={type}
        variant={variant}
        width={width}
      >
        {children}
      </Button.Loader>
    </Tooltip>
  );
}
