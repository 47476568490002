import { isL2ChainId } from 'utils/network';
import { getCopy } from 'utils/transactions';

import { TrackedTx } from 'types/Transactions';

import Modal from './base/Modal';

type TransactionTrackerModalBodyProps = {
  tx: TrackedTx;
};

export default function TransactionTrackerModalBody(
  props: TransactionTrackerModalBodyProps
) {
  const { tx } = props;

  // TODO: if `tx` errored, show a human readable error message

  // Note: PENDING and INDEXING are intentionally flattened into a single state here
  const status = tx.status === 'INDEXING' ? 'PENDING' : tx.status;

  return (
    <Modal.BodyTitle
      title={getCopy({
        status,
        customCopy: tx.title,
        defaultCopy: {
          ERROR: 'Something went wrong',
          PENDING: 'Processing transaction',
          REVERTED: 'Something went wrong',
          SUCCESS: 'Transaction complete',
        },
      })}
      description={getCopy({
        status,
        customCopy: tx.description,
        defaultCopy: {
          ERROR: undefined,
          PENDING: isL2ChainId(tx.chainId)
            ? 'This usually takes a few seconds.'
            : 'This can take a couple of minutes. Feel free to close this message.',
          REVERTED: undefined,
          SUCCESS: undefined,
        },
      })}
    />
  );
}
