import { styled } from '@f8n-frontend/stitches';
import { useEffect } from 'react';
import { useBoolean } from 'react-use';
import type { Address } from 'viem';
import { useWriteContract } from 'wagmi';

import PreSignPrompt from 'components/auth/PreSignPrompt';
import Button from 'components/base/Button';
import Modal from 'components/base/Modal';
import { TooltipContent } from 'components/base/Tooltip';
import { hasPublicKey, hasToken } from 'contexts/auth/helpers';
import useAuth from 'contexts/auth/useAuth';
import { getChangeNetworkCopy } from 'copy/auth';
import { TRANSACTION_CTA } from 'copy/transactions';
import useTargetChain from 'state/stores/target-chain';
import useTransactionStore from 'state/stores/transactions';

import { useQueryEffects } from 'hooks/react-query';
import useSimulateSetApprovalForAll from 'hooks/web3/transactions/use-simulate-set-approval-for-all';
import useHasApproval from 'hooks/web3/use-has-approval';
import useIsUnsupportedNetwork from 'hooks/web3/use-is-unsupported-network';
import { useRefetchOnBlock } from 'hooks/web3/use-watch-block';
import { ChainId } from 'lib/chains';
import { logger } from 'lib/logger';
import { isBigInt } from 'utils/bigint';
import { isStringType } from 'utils/helpers';
import { getChainConfigById } from 'utils/network';
import { isZero } from 'utils/numbers';

import TransactionModalContent from './TransactionModalContent';
import { ChangeNetworkButton } from './auth/ChangeNetworkButton';
import NetworkPrompt from './auth/NetworkPrompt';
import { Web3ActionButton } from './base/Web3ActionButton';
import { BridgeModalWindow } from './modals/BridgeModal';

type PreTransactionGuardCopy = {
  title?: string;
  description?: string;
};

type TransactionModalBaseProps = {
  children: React.ReactNode;
  /**
   * At this time we only support enforcing balance for base chain(s)
   *
   * TODO: rename as enforceBalance and make it usable for all chains
   */
  enforceBaseBalance?: boolean;
  preConnectPrompt?: PreTransactionGuardCopy;
  preSignPrompt:
    | { type: 'normal' }
    | { type: 'custom'; copy: PreTransactionGuardCopy }
    | { type: 'skip' };
  preSwitchNetworkPrompt?: PreTransactionGuardCopy;
  chainId: ChainId;
};

function TransactionModalBase(props: TransactionModalBaseProps) {
  const {
    chainId,
    children,
    enforceBaseBalance,
    preConnectPrompt,
    preSignPrompt,
    preSwitchNetworkPrompt,
  } = props;

  const auth = useAuth();
  const { setTargetChainById } = useTargetChain();

  useEffect(() => {
    setTargetChainById(chainId);
  }, [chainId]);

  const [canBypassBalanceCheck, setCanBypassBalanceCheck] = useBoolean(false);

  const isUnsupportedNetwork = useIsUnsupportedNetwork({
    supportedChainIds: chainId ? [chainId] : undefined,
  });

  const chainConfig = getChainConfigById(chainId);
  const changeNetworkCopy = getChangeNetworkCopy(chainConfig.chain);

  if (!hasPublicKey(auth)) {
    const title = preConnectPrompt?.title || changeNetworkCopy.heading;

    const description =
      preConnectPrompt?.description || changeNetworkCopy.subheading;

    return (
      <Modal.Window size={{ '@initial': 1, '@bp2': 2 }}>
        <NetworkPrompt
          chainId={chainId}
          actions={
            <Button onClick={auth.startLogin} variant="primary" size={2}>
              {chainConfig.copy.connectToChain}
            </Button>
          }
          description={description}
          title={title}
        />
      </Modal.Window>
    );
  }

  if (!hasToken(auth) && preSignPrompt.type !== 'skip') {
    if (preSignPrompt.type === 'custom') {
      return (
        <Modal.Window size={{ '@initial': 1, '@bp2': 2 }}>
          <PreSignPrompt
            title={preSignPrompt.copy.title}
            description={preSignPrompt.copy.description}
          />
        </Modal.Window>
      );
    }

    return (
      <Modal.Window size={{ '@initial': 1, '@bp2': 2 }}>
        <PreSignPrompt />
      </Modal.Window>
    );
  }

  if (isUnsupportedNetwork) {
    const title = preSwitchNetworkPrompt?.title || changeNetworkCopy.heading;

    const description =
      preSwitchNetworkPrompt?.description || changeNetworkCopy.subheading;

    return (
      <Modal.Window size={{ '@initial': 1, '@bp2': 2 }}>
        <NetworkPrompt
          chainId={chainId}
          actions={<ChangeNetworkButton chainId={props.chainId} size={2} />}
          description={description}
          title={title}
        />
      </Modal.Window>
    );
  }

  if (
    enforceBaseBalance &&
    canBypassBalanceCheck === false &&
    (chainConfig.chain === 'base' || chainConfig.chain === 'baseSepolia') &&
    isBigInt(auth.balance) &&
    isZero(auth.balance)
  ) {
    return (
      <BridgeModalWindow
        chainConfig={chainConfig}
        onContinue={() => setCanBypassBalanceCheck(true)}
      />
    );
  }

  return <>{children}</>;
}

type TransactionButtonProps = {
  isDisabled: boolean;
  isLoading: boolean;
  error: TooltipContent;
  label: string;
  write: () => void;
  chainId: ChainId;
};

/** @deprecated use Web3ActionButton directly instead */
function TransactionButton(props: TransactionButtonProps) {
  const { write, isDisabled, isLoading, error, label, chainId } = props;

  const disabledReason = isStringType(error) ? error : null;

  return (
    <Web3ActionButton
      chainId={chainId}
      disabledReason={disabledReason}
      disabled={isDisabled || Boolean(disabledReason)}
      isLoading={isLoading}
      loadingLabel={TRANSACTION_CTA.CONFIRM}
      size={1}
      type="button"
      variant="primary"
      write={write}
    >
      {label}
    </Web3ActionButton>
  );
}

type TransactionButtonWithContractApprovalProps = TransactionButtonProps & {
  chainId: ChainId;
  contractAddress: Address;
  onApprovalSuccess: () => void;
};

function TransactionButtonWithContractApproval(
  props: TransactionButtonWithContractApprovalProps
) {
  const { contractAddress, chainId, label, error, write, onApprovalSuccess } =
    props;

  const auth = useAuth();

  const txStore = useTransactionStore();

  const hasApprovalQuery = useHasApproval(
    {
      chainId,
      contractAddress,
      // TODO: avoid casting by passing connected auth as a prop
      publicKey: hasPublicKey(auth) ? auth.publicKey : ('' as Address),
    },
    { enabled: hasPublicKey(auth) }
  );

  const simulateSetApprovalForAll = useSimulateSetApprovalForAll(
    { contractAddress, chainId },
    { enabled: hasApprovalQuery.isSuccess ? !hasApprovalQuery.data : false }
  );

  useQueryEffects(hasApprovalQuery, {
    onSuccess(isApproved) {
      if (isApproved) {
        onApprovalSuccess();
      }
    },
  });

  useRefetchOnBlock(hasApprovalQuery, {
    enabled: hasPublicKey(auth),
  });

  const writeContractSetApprovalForAll = useWriteContract({
    mutation: {
      onError: (error) => {
        logger.error('web3_action_set_approval_for_all_error', error);
      },
      onSuccess: (txHash) => {
        txStore.startTracking({
          chainId,
          ui: 'none',
          action: 'set-approval-for-all',
          txHash,
        });
      },
    },
  });

  if (!hasApprovalQuery.isSuccess) {
    return (
      <Button variant="primary" disabled>
        {label}
      </Button>
    );
  }

  if (writeContractSetApprovalForAll.isPending) {
    return (
      <Button.Loader
        loadingLabel={TRANSACTION_CTA.CONFIRM}
        size={1}
        isLoading
      />
    );
  }

  if (hasApprovalQuery.data) {
    return (
      <TransactionButton
        write={write}
        chainId={props.chainId}
        isDisabled={props.isDisabled}
        isLoading={props.isLoading}
        error={error}
        label={label}
      />
    );
  }

  if (writeContractSetApprovalForAll.data) {
    return (
      <Button.Loader loadingLabel="Approving contract" size={1} isLoading />
    );
  }

  return (
    <Button
      type="button"
      variant="primary"
      onClick={() => {
        if (simulateSetApprovalForAll.isSuccess) {
          writeContractSetApprovalForAll.writeContract(
            simulateSetApprovalForAll.data.request
          );
        }
      }}
    >
      Approve to continue
    </Button>
  );
}

const Body = styled('div', {
  gap: '$4',
  display: 'flex',
  flexDirection: 'column',
});

const InputWrapper = styled('div', {
  gap: '$3',
  display: 'flex',
  flexDirection: 'column',
});

const TransactionModal = Object.assign(TransactionModalBase, {
  Body,
  Content: TransactionModalContent,
  TransactionButton,
  TransactionButtonWithContractApproval,
  InputWrapper,
});

export default TransactionModal;
