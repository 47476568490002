import { ShareModalWindow } from 'components/ShareModalWindow';
import Modal from 'components/base/Modal';
import useAnalytics from 'contexts/analytics/useAnalytics';

import useModalVisibility from 'hooks/use-modal-visibility';
import { getTokenFilter } from 'utils/inputs';

import { ModalOptions } from 'types/modal';

const MODAL_KEY = 'SHARE_NFT';
type ShareNftModalOptions = ModalOptions<typeof MODAL_KEY>;

export default function ShareNftModal() {
  const modal = useModalVisibility(MODAL_KEY);

  return (
    <Modal.Root open={modal.open} onOpenChange={modal.onOpenChange}>
      <Modal.Portal>
        <Modal.BlurOverlay />
        <Modal.PositionOverlay>
          <Modal.UnmountListener onUnmount={modal.onUnmount} />
          {modal.config && <ShareNftModalWindow {...modal.config} />}
        </Modal.PositionOverlay>
      </Modal.Portal>
    </Modal.Root>
  );
}

function ShareNftModalWindow(props: ShareNftModalOptions) {
  const analytics = useAnalytics();
  const nftFilter = getTokenFilter(props.nft);

  return (
    <ShareModalWindow
      type="nft"
      creator={props.creator}
      onCopyUrl={() => {
        analytics.track({
          name: 'share_url_clicked',
          type: 'nft',
          nft: nftFilter,
        });
      }}
      onShareTwitterClicked={() => {
        analytics.track({
          name: 'share_twitter_clicked',
          type: 'nft',
          nft: nftFilter,
        });
      }}
    />
  );
}
