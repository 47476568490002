import { EllipsisIcon, PercentageIcon } from '@f8n/icons';
import { toPx } from '@f8n/tokens';
import { darkMode, styled } from '@f8n-frontend/stitches';
import * as RadixTabs from '@radix-ui/react-tabs';
import { CSS } from '@stitches/react';
import React, { RefObject } from 'react';
import { match } from 'ts-pattern';
import { useResizeObserver, useWindowSize } from 'usehooks-ts';

import BrandBadge from 'components/BrandBadge';
import { MomentTag } from 'components/MomentTag';
import { NFT_MEDIA_SELECTOR } from 'components/NftMedia';
import Badge from 'components/base/Badge';
import Box from 'components/base/Box';
import Button from 'components/base/Button';
import DropdownMenu from 'components/base/DropdownMenu';
import Heading from 'components/base/Heading';
import Link, { LinkProps } from 'components/base/Link';
import Skeleton from 'components/base/Skeleton';
import Text from 'components/base/Text';
import FollowButton from 'components/follows/FollowButton';
import UserTag from 'components/users/UserTag';
import WorldTag from 'components/worlds/WorldTag';

import { ApiMomentOverviewFragment } from 'gql/api/api-fragments.generated';
import { formatNumber } from 'utils/formatters';
import { isNumberType } from 'utils/helpers';
import { GLOBAL_NAV_HEIGHT } from 'utils/layout';

import { UserLight, UserMicro } from 'types/Account';
import { ContractCategory } from 'types/Collection';
import { CuratedStore } from 'types/CuratedStore';
import { WorldOverview } from 'types/World';

/**
 * The sidebar section component
 */
type SidebarSectionProps = {
  gap?: CSS['gap'];
  heading?: string;
  children: React.ReactNode;
};

function SidebarSection(props: SidebarSectionProps) {
  const { heading, children, gap = '$2' } = props;
  return (
    <SidebarStack css={{ gap, width: '100%' }}>
      <Heading lineHeight="2" color="strong" size="1" weight="regular">
        {heading}
      </Heading>
      {children}
    </SidebarStack>
  );
}

type SidebarHeaderProps = {
  creator: UserLight;
  name: string | null;
} & ({ type: 'COLLECTION'; category: ContractCategory } | { type: 'TOKEN' });

function SidebarHeader(props: SidebarHeaderProps) {
  const { creator, name } = props;
  return (
    <SidebarStack
      css={{ gap: '$2', alignItems: 'flex-start', '@bp2': { gap: '$4' } }}
    >
      {match(props)
        .with({ type: 'COLLECTION', category: 'DROP' }, () => {
          return <BrandBadge product="drop" />;
        })
        .with({ type: 'COLLECTION', category: 'EDITION' }, () => {
          return <BrandBadge product="editionCollection" />;
        })
        .otherwise(() => null)}
      <SidebarStack css={{ gap: '2px' }}>
        {name && (
          <Heading weight="medium" size="5" lineHeight="1">
            {name}
          </Heading>
        )}
        <Text size={3} lineHeight="0" css={{ width: 'max-content' }}>
          <UserTag
            nameVariant="prefer-display-name"
            type="text"
            user={creator}
            weight="regular"
            size={3}
          />
        </Text>
      </SidebarStack>
    </SidebarStack>
  );
}

function SidebarArtist(props: {
  user: UserMicro;
  userFollowersCount: number | null;
}) {
  const { user, userFollowersCount } = props;

  return (
    <UserBox>
      <UserTag
        nameVariant="prefer-display-name"
        size="4"
        type="avatar"
        user={user}
      />
      <UserBoxMeta>
        <Text size="1" lineHeight="0">
          <UserTag
            nameVariant="prefer-display-name"
            color="strong"
            type="text"
            user={user}
            weight="medium"
          />
        </Text>
        {isNumberType(userFollowersCount) ? (
          <Text lineHeight="0" color="dim" size="0">
            {formatNumber(userFollowersCount)} followers
          </Text>
        ) : (
          <Skeleton.Text>
            <Text lineHeight="0" color="dim" size="0">
              followers
            </Text>
          </Skeleton.Text>
        )}
      </UserBoxMeta>
      <FollowButton publicKey={user.publicKey} size="0" />
    </UserBox>
  );
}

/**
 * The sidebar presented by component
 */
function SidebarPresentedBy(props: { world: WorldOverview }) {
  const { world } = props;
  return (
    <UserBox
      css={{
        [`${Link} span`]: {
          fontSize: '$1',
          // TODO: There's no need for the extra text element inside WorldTag
          // The link should behave as all other links
          color: 'inherit',
        },
      }}
    >
      {/* TODO: support MomentTag */}
      <WorldTag type="link" world={world} />
    </UserBox>
  );
}

function SidebarExhibitedIn(props: { moment: ApiMomentOverviewFragment }) {
  const { moment } = props;
  return (
    <UserBox
      css={{
        [`${Link} span`]: {
          fontSize: '$1',
          // TODO: There's no need for the extra text element inside WorldTag
          // The link should behave as all other links
          color: 'inherit',
        },
      }}
    >
      {/* TODO: support MomentTag */}
      <MomentTag type="link" moment={moment} world={moment.world} />
    </UserBox>
  );
}

/**
 * The sidebar actions menu
 */
function SidebarActions(props: {
  menuItems: React.ReactNode;
  isReferralRewardsSupported: boolean;
  onShare: () => void;
}) {
  const { isReferralRewardsSupported, menuItems, onShare } = props;

  return (
    <SidebarActionsRow>
      {isReferralRewardsSupported ? (
        <ShareButton onClick={onShare} size={0} variant="outline">
          Share and earn
          <Badge mono size={0} variant="blue">
            <span>1</span>
            <Box
              css={{
                marginLeft: '-4px',
                display: 'flex',
                alignContent: 'center',
              }}
            >
              <PercentageIcon />
            </Box>
          </Badge>
        </ShareButton>
      ) : (
        <ShareButton onClick={onShare} size={0} variant="outline">
          Share
        </ShareButton>
      )}
      {menuItems && (
        <DropdownMenu.Root>
          <DropdownMenu.Trigger asChild>
            <Button
              aria-label="Menu"
              icon="standalone"
              variant="ghost"
              size={0}
            >
              <EllipsisIcon />
            </Button>
          </DropdownMenu.Trigger>
          <DropdownMenu.Portal>
            <DropdownMenu.Content align="end">{menuItems}</DropdownMenu.Content>
          </DropdownMenu.Portal>
        </DropdownMenu.Root>
      )}
    </SidebarActionsRow>
  );
}

function SidebarStoreSection(props: { store: CuratedStore | null }) {
  return match(props.store)
    .with({ type: 'WORLD' }, ({ world }) => (
      <MintLayout.SidebarSection heading="Presented by">
        <SidebarPresentedBy world={world} />
      </MintLayout.SidebarSection>
    ))
    .with({ type: 'MOMENT' }, ({ moment }) => {
      return (
        <MintLayout.SidebarSection heading="Exhibited in">
          <SidebarExhibitedIn moment={moment} />
        </MintLayout.SidebarSection>
      );
    })
    .otherwise(() => null);
}

const Root = styled('div', {
  display: 'flex',
  position: 'relative',
  flexDirection: 'column',
  backgroundColor: '$white100',

  '@bp2': {
    flexDirection: 'row',
  },
});

const Body = styled('div', {
  display: 'flex',
  alignItems: 'flex-start',
  justifyContent: 'center',
  flexGrow: 1,
});

const MediaContainer = styled('div', {
  position: 'sticky',
  top: GLOBAL_NAV_HEIGHT / 2,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '5%',
  flexGrow: 1,
  height: '50vh',

  [NFT_MEDIA_SELECTOR]: {
    flexGrow: 1,
  },

  '@bp2': {
    height: `calc(100vh - ${toPx(GLOBAL_NAV_HEIGHT)})`,
    paddingLeft: 0,
    paddingRight: '$8',
  },

  '@bp5': {
    [NFT_MEDIA_SELECTOR]: {
      maxHeight: '75vh',
    },
  },

  variants: {
    variant: {
      fullscreen: {
        background: '$white100',
        position: 'fixed',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        zIndex: 1000,
        height: '100vh',

        '@bp2': {
          paddingLeft: 0,
          paddingRight: 0,
        },

        [NFT_MEDIA_SELECTOR]: {
          maxHeight: '90vh',
        },
      },
    },
  },
});

const Sidebar = styled('div', {
  flexGrow: 0,
  flexShrink: 0,

  gap: '$4',
  display: 'flex',
  flexDirection: 'column',

  '@bp2-max': {
    paddingBottom: '$6',
  },

  '@bp2': {
    gap: '$7',
    padding: '$8',
    paddingRight: 0,
    minWidth: 464,
    maxWidth: 464,
    borderLeft: '1px solid $black5',
  },
});

const SidebarStack = styled('div', {
  display: 'flex',
  flexDirection: 'column',
});

const SidebarActionsRow = styled('div', {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
});

const ShareButton = styled(Button, {
  gap: '$2',

  [`${Badge}`]: {
    paddingY: 0,
    paddingX: '$1',
    fontSize: '$0',
    lineHeight: '$2',
    borderRadius: '$1',
    fontWeight: '$semibold',
  },
});

const UserBox = styled('div', {
  gap: '$3',
  padding: '$3',
  display: 'flex',
  borderRadius: '$3',
  alignItems: 'center',
  border: '1px solid $black5',
  justifyContent: 'space-between',

  [darkMode]: {
    borderColor: '$black10',
  },
});

const UserBoxMeta = styled('div', {
  flexDirection: 'column',
  display: 'flex',
  gap: '6px',
  flex: 1,
});

const SubLabel = styled('span', Text);
SubLabel.defaultProps = {
  weight: 'medium',
  size: '2',
};

/**
 * Sidebar tabs
 */
function SidebarTabsList(props: RadixTabs.TabsListProps) {
  const { children, ...rest } = props;
  return (
    <RadixTabs.List asChild {...rest}>
      <Box
        css={{
          gap: '$6',
          display: 'flex',
          marginBottom: '-1px',
        }}
      >
        {children}
      </Box>
    </RadixTabs.List>
  );
}

function SidebarTabsTrigger(props: LinkProps & RadixTabs.TabsTriggerProps) {
  const { value, ...rest } = props;
  return (
    <RadixTabs.Trigger value={value} asChild>
      <Link
        css={{
          paddingBottom: '$3',
          [`&[data-state=active]`]: {
            borderBottom: '1px solid $black100',
            color: '$black100',
            '@hover': {
              '&:hover': {
                color: '$black60',
              },
            },
          },
          [`&[data-state=inactive]`]: {
            color: '$black60',
            '@hover': {
              '&:hover': {
                color: '$black100',
              },
            },
          },
        }}
        lineHeight={2}
        size={1}
        weight="regular"
        {...rest}
      />
    </RadixTabs.Trigger>
  );
}

// TODO: replace with useMediaContainerSizing
export function useMediaContainerSize(ref: RefObject<HTMLElement>) {
  const mediaContainerObserver = useResizeObserver({
    ref,
    box: 'content-box',
  });

  return {
    aspectRatio: calculateAspectRatio(mediaContainerObserver),
    width: mediaContainerObserver.width ?? 0,
    height: mediaContainerObserver.height ?? 0,
  };
}

export function useScreenSize() {
  const windowObserver = useWindowSize();

  return {
    aspectRatio: calculateAspectRatio(windowObserver),
    width: windowObserver.width,
    height: windowObserver.height,
  };
}

function calculateAspectRatio(size: {
  width: number | undefined;
  height: number | undefined;
}) {
  return size.width && size.height ? size.width / size.height : null;
}

const SidebarTabs = {
  Root: RadixTabs.Root,
  List: SidebarTabsList,
  Trigger: SidebarTabsTrigger,
  Content: RadixTabs.Content,
};

export const MintLayout = {
  Body,
  Root,
  MediaContainer,
  Sidebar,
  SidebarActions,
  SidebarArtist,
  SidebarHeader,
  SidebarStack,
  SidebarStoreSection,
  SidebarSection,
  SidebarTabs,
};
