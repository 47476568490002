import { FireIcon } from '@f8n/icons';

import DropdownMenu from 'components/base/DropdownMenu';

export function BurnNftMenuItem(props: { onClick: () => void }) {
  return (
    <DropdownMenu.Item type="button" onClick={props.onClick} variant="danger">
      <FireIcon />
      Burn
    </DropdownMenu.Item>
  );
}
