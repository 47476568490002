import { EllipsisIcon } from '@f8n/icons';
import { styled } from '@f8n-frontend/stitches';
import { parseJSON } from 'date-fns';
import { LayoutGroup, motion } from 'framer-motion';
import NextLink from 'next/link';
import { P, match } from 'ts-pattern';
import { Address } from 'viem';

import CurrencyAvatar from 'components/CurrencyAvatar';
import { SaleSchedule } from 'components/SaleSchedule';
import Box from 'components/base/Box';
import Button from 'components/base/Button';
import DropdownMenu from 'components/base/DropdownMenu';
import Field from 'components/base/Field';
import Flex from 'components/base/Flex';
import Input from 'components/base/InputV3Base';
import Text from 'components/base/Text';
import UserAvatar from 'components/base/UserAvatar';
import { Checkout } from 'components/checkout-widget/Checkout';
import { BurnNftMenuItem } from 'components/menu-items/BurnNftMenuItem';
import { TransferNftMenuItem } from 'components/menu-items/TransferNftMenuItem';
import ProfileHoverCard from 'components/profiles/ProfileHoverCard';
import { OfferProgressCircle } from 'components/transactions/offer/OfferProgressCircle';
import UserTag from 'components/users/UserTag';

import { ApiBidFragment } from 'gql/api/api-fragments.generated';
import useCountdown from 'hooks/use-countdown';
import { useAuctionPlacedBids } from 'hooks/web3/use-auction-placed-bids';
import { ChainId } from 'lib/chains';
import { ZERO_ADDRESS } from 'lib/constants';
import { isFinal5Mins } from 'utils/countdown';
import { isUpcoming } from 'utils/dates/dates';
import { formatETHWithSuffix, formatETHWithoutSuffix } from 'utils/formatters';
import { isNonZero } from 'utils/numbers';
import { getPath } from 'utils/router';

import { Currency } from 'types/Currency';
import { ActiveOffer } from 'types/Nft';

import { useMarketWidget } from './MarketWidgetContext';
import { MarketWidgetLiveAuctionBidsTable } from './MarketWidgetLiveAuctionBidTable';
import { MarketWidgetLiveAuctionStatus } from './MarketWidgetLiveAuctionStatus';
import { MarketWidgetPlaceBidCta } from './MarketWidgetPlaceBidCta';
import { MarketWidgetSaleStats } from './MarketWidgetSaleStats';
import { MARKET_WIDGET_CTA, MARKET_WIDGET_LABEL } from './copy';
import { MarketWidgetNft, NftLiveAuction, NftLiveAuctionStatus } from './types';

export function MarketWidget(props: {
  currentUserPublicKey: Address | null;
  nft: MarketWidgetNft;
  liveAuction: NftLiveAuction | null;
  isOwner: boolean;
}) {
  const { onBurn, onBuyNow, onMakeOffer, onSell, onTransfer, currency } =
    useMarketWidget();

  return (
    match(props)
      // Live auction
      .with(
        {
          liveAuction: {
            auctionId: P.number,
          },
        },
        ({ liveAuction }) => {
          return (
            <MarketWidgetLiveAuction
              liveAuction={liveAuction}
              currentUserPublicKey={props.currentUserPublicKey}
            />
          );
        }
      )
      .with(
        { nft: { activeAuction: P.not(null), activeBuyNow: P.not(null) } },
        ({ nft, isOwner }) => {
          const { activeAuction, activeBuyNow, activeOffer, saleStartsAt } =
            nft;

          if (isUpcoming(saleStartsAt)) {
            return (
              <Checkout.Root>
                <Checkout.Stack css={{ gap: '$3' }}>
                  <MarketWidgetSaleStats.BuyOptions
                    buyNowPrice={activeBuyNow.amount}
                    reservePrice={activeAuction.reservePrice}
                    currency={currency}
                  />

                  {isOwner ? (
                    <MarketWidgetOwnerActions
                      activeOffer={activeOffer}
                      saleStartsAt={saleStartsAt}
                      onSell={onSell}
                    />
                  ) : (
                    <Checkout.Stack css={{ gap: '$3' }}>
                      <SaleSchedule.Public
                        startDate={parseJSON(saleStartsAt)}
                        prefix="sale-starts"
                      />
                      <Button onClick={onMakeOffer} variant="subtle">
                        {MARKET_WIDGET_CTA.OFFER}
                      </Button>
                    </Checkout.Stack>
                  )}
                </Checkout.Stack>
              </Checkout.Root>
            );
          }

          return (
            <Checkout.Root>
              <Checkout.Stack css={{ gap: '$3' }}>
                <MarketWidgetSaleStats.BuyOptions
                  buyNowPrice={activeBuyNow.amount}
                  reservePrice={activeAuction.reservePrice}
                  currency={currency}
                />
                <Checkout.Stack css={{ gap: '$2' }}>
                  {isOwner ? (
                    <>
                      <Button
                        onClick={onSell}
                        variant={activeOffer ? 'subtle' : 'primary'}
                      >
                        {MARKET_WIDGET_CTA.EDIT_LISTING}
                      </Button>
                    </>
                  ) : (
                    <>
                      <Box css={{ display: 'flex', gap: '$2' }}>
                        <MarketWidgetPlaceBidCta isCurrentUserWinning={false} />
                        <Button
                          onClick={onBuyNow}
                          variant="outline"
                          width="full"
                        >
                          {MARKET_WIDGET_CTA.BUY}
                        </Button>
                      </Box>
                      <Button onClick={onMakeOffer} variant="subtle">
                        {MARKET_WIDGET_CTA.OFFER}
                      </Button>
                    </>
                  )}
                </Checkout.Stack>
              </Checkout.Stack>
              {activeOffer && (
                <ActiveOfferRow offer={activeOffer} isOwner={isOwner} />
              )}
            </Checkout.Root>
          );
        }
      )
      /**
       * NFT has a reserve price
       */
      .with({ nft: { activeAuction: P.not(null) } }, ({ nft, isOwner }) => {
        const { activeAuction, activeOffer, saleStartsAt } = nft;

        if (isUpcoming(saleStartsAt)) {
          return (
            <Checkout.Root>
              <Checkout.Stack css={{ gap: '$3' }}>
                <MarketWidgetSaleStats.ReservePrice
                  amount={activeAuction.reservePrice}
                  currency={currency}
                />

                {isOwner ? (
                  <MarketWidgetOwnerActions
                    activeOffer={activeOffer}
                    saleStartsAt={saleStartsAt}
                    onSell={onSell}
                  />
                ) : (
                  <Checkout.Stack css={{ gap: '$3' }}>
                    <SaleSchedule.Public
                      startDate={parseJSON(saleStartsAt)}
                      prefix="sale-starts"
                    />
                    <Button onClick={onMakeOffer} variant="subtle">
                      {MARKET_WIDGET_CTA.OFFER}
                    </Button>
                  </Checkout.Stack>
                )}
              </Checkout.Stack>
            </Checkout.Root>
          );
        }

        return (
          <Checkout.Root>
            <Checkout.Stack css={{ gap: '$3' }}>
              <MarketWidgetSaleStats.ReservePrice
                amount={activeAuction.reservePrice}
                currency={currency}
              />
              <Checkout.Stack css={{ gap: '$2' }}>
                {isOwner ? (
                  <>
                    <Button
                      onClick={onSell}
                      variant={activeOffer ? 'subtle' : 'primary'}
                    >
                      {MARKET_WIDGET_CTA.EDIT_LISTING}
                    </Button>
                  </>
                ) : (
                  <>
                    <MarketWidgetPlaceBidCta isCurrentUserWinning={false} />
                    <Button onClick={onMakeOffer} variant="subtle">
                      {MARKET_WIDGET_CTA.OFFER}
                    </Button>
                  </>
                )}
              </Checkout.Stack>
            </Checkout.Stack>
            {activeOffer && (
              <ActiveOfferRow offer={activeOffer} isOwner={isOwner} />
            )}
          </Checkout.Root>
        );
      })
      /**
       * NFT is available to Buy Now
       */
      .with({ nft: { activeBuyNow: P.not(null) } }, ({ nft, isOwner }) => {
        const { activeBuyNow, activeOffer, saleStartsAt } = nft;

        if (isUpcoming(saleStartsAt)) {
          return (
            <Checkout.Root>
              <Checkout.Stack css={{ gap: '$3' }}>
                <MarketWidgetSaleStats.BuyNowPrice
                  amount={activeBuyNow.amount}
                  hasReserve={false}
                />

                {isOwner ? (
                  <MarketWidgetOwnerActions
                    activeOffer={activeOffer}
                    saleStartsAt={saleStartsAt}
                    onSell={onSell}
                  />
                ) : (
                  <Checkout.Stack css={{ gap: '$3' }}>
                    <SaleSchedule.Public
                      startDate={parseJSON(saleStartsAt)}
                      prefix="sale-starts"
                    />
                    <Button onClick={onMakeOffer} variant="subtle">
                      {MARKET_WIDGET_CTA.OFFER}
                    </Button>
                  </Checkout.Stack>
                )}
              </Checkout.Stack>
            </Checkout.Root>
          );
        }

        return (
          <Checkout.Root>
            <Checkout.Stack css={{ gap: '$3' }}>
              <MarketWidgetSaleStats.BuyNowPrice
                amount={activeBuyNow.amount}
                hasReserve={false}
              />
              <Checkout.Stack css={{ gap: '$2' }}>
                {isOwner ? (
                  <>
                    <Button
                      onClick={onSell}
                      variant={activeOffer ? 'subtle' : 'primary'}
                    >
                      {MARKET_WIDGET_CTA.EDIT_LISTING}
                    </Button>
                  </>
                ) : (
                  <>
                    <Button onClick={onBuyNow} variant="primary">
                      {MARKET_WIDGET_CTA.BUY}
                    </Button>
                    <Button onClick={onMakeOffer} variant="subtle">
                      {MARKET_WIDGET_CTA.OFFER}
                    </Button>
                  </>
                )}
              </Checkout.Stack>
            </Checkout.Stack>
            {activeOffer && (
              <ActiveOfferRow offer={activeOffer} isOwner={isOwner} />
            )}
          </Checkout.Root>
        );
      })
      .with({ isOwner: true }, ({ nft }) => {
        const { activeOffer } = nft;

        return (
          <Checkout.Root>
            <Checkout.Stack css={{ gap: '$4' }}>
              <Checkout.Stack css={{ flexDirection: 'row', gap: '$2' }}>
                <Button
                  onClick={onSell}
                  variant={activeOffer ? 'subtle' : 'primary'}
                  width="full"
                >
                  {MARKET_WIDGET_CTA.SELL}
                </Button>
                <DropdownMenu.Root>
                  <DropdownMenu.Trigger asChild>
                    <Button
                      aria-label="Menu"
                      icon="standalone"
                      variant="subtle"
                      size={1}
                      css={{ flexShrink: 0 }}
                    >
                      <EllipsisIcon />
                    </Button>
                  </DropdownMenu.Trigger>
                  <DropdownMenu.Portal>
                    <DropdownMenu.Content align="end">
                      <TransferNftMenuItem onClick={onTransfer} />
                      {onBurn && <BurnNftMenuItem onClick={onBurn} />}
                    </DropdownMenu.Content>
                  </DropdownMenu.Portal>
                </DropdownMenu.Root>
              </Checkout.Stack>
              {match(nft)
                .with({ activeOffer: P.not(P.nullish) }, ({ activeOffer }) => {
                  return <ActiveOfferRow offer={activeOffer} isOwner />;
                })
                .with({ lastSale: P.not(P.nullish) }, ({ lastSale }) => {
                  return (
                    <LastSoldFor amount={lastSale.amount} align="center" />
                  );
                })
                .otherwise(() => {
                  return (
                    <Checkout.CtaSmallprintText>
                      List for sale via auction or set a fixed price.
                    </Checkout.CtaSmallprintText>
                  );
                })}
            </Checkout.Stack>
          </Checkout.Root>
        );
      })
      /**
       * NFT has an active offer
       */
      .with({ nft: { activeOffer: P.not(null) } }, ({ nft }) => {
        const { activeOffer } = nft;
        return (
          <Checkout.Root>
            <Button onClick={onMakeOffer} variant="subtle">
              {MARKET_WIDGET_CTA.OFFER}
            </Button>
            <OfferCountdown align="center" offer={activeOffer} />
          </Checkout.Root>
        );
      })
      /**
       * NFT has been sold in the past
       */
      .with({ nft: { lastSale: P.not(null) } }, ({ nft }) => {
        const { lastSale } = nft;
        return (
          <Checkout.Root>
            <Button onClick={onMakeOffer} variant="subtle">
              {MARKET_WIDGET_CTA.OFFER}
            </Button>
            <LastSoldFor align="center" amount={lastSale.amount} />
          </Checkout.Root>
        );
      })
      .otherwise(() => {
        return (
          <Checkout.Root>
            <Button onClick={onMakeOffer} variant="subtle">
              {MARKET_WIDGET_CTA.OFFER}
            </Button>
          </Checkout.Root>
        );
      })
  );
}

function MarketWidgetOwnerActions(props: {
  activeOffer: ActiveOffer | null;
  saleStartsAt: string;
  onSell: () => void;
}) {
  const { activeOffer, saleStartsAt, onSell } = props;

  return (
    <Checkout.Stack css={{ gap: '$3' }}>
      <SaleSchedule.Public
        startDate={parseJSON(saleStartsAt)}
        prefix="sale-starts"
      />
      <Button onClick={onSell} variant={activeOffer ? 'subtle' : 'primary'}>
        {MARKET_WIDGET_CTA.EDIT_LISTING}
      </Button>
      {activeOffer && <ActiveOfferRow offer={activeOffer} isOwner />}
    </Checkout.Stack>
  );
}

function MarketWidgetLiveAuction(props: {
  currentUserPublicKey: Address | null;
  liveAuction: NftLiveAuction;
}) {
  const { currentUserPublicKey, liveAuction } = props;

  const { bid, onSettle, currency } = useMarketWidget();
  const countdown = useCountdown(liveAuction.endsAtUnix);
  const isAuctionEnding = countdown.secondsRemaining === 0;

  const isCurrentUserHighestBidder =
    liveAuction.highestBidder === currentUserPublicKey;

  const isInputDisabled = isAuctionEnding || isCurrentUserHighestBidder;
  const isInputVisible = !isCurrentUserHighestBidder;

  return (
    <Checkout.Root css={{ paddingBottom: 0, gap: '$7' }}>
      <LayoutGroup>
        {match(liveAuction.status)
          .with('LIVE', () => {
            return (
              <Checkout.Stack css={{ gap: '$5' }} as={motion.div}>
                <MarketWidgetLiveAuctionStatus isEnding={isAuctionEnding} />
                <MarketWidgetSaleStats.LiveAuction
                  countdown={countdown}
                  currentBid={{
                    amount: liveAuction.currentBidAmount,
                    currency,
                  }}
                />
                <Checkout.Stack
                  css={{
                    gap: '$4',
                  }}
                >
                  <Checkout.Stack
                    css={{
                      gap: isInputVisible ? '$2' : 0,
                      transition: '$2 $ease gap',
                    }}
                  >
                    <Box
                      as={motion.div}
                      animate={{
                        height: isInputVisible ? 'auto' : 0,
                      }}
                      transition={{
                        duration: 0.6,
                      }}
                      layout="position"
                      css={{
                        overflow: isInputVisible ? undefined : 'hidden',
                      }}
                    >
                      <Field.Root size={2} disabled={isInputDisabled}>
                        <Input
                          id="bid"
                          placeholder="Your bid"
                          value={bid.amount} // TODO (1155): Ensure value is shown with long currency name
                          onChange={(event) => {
                            bid.setAmount(event.target.value); // TODO (1155): Validate input is an integer?
                          }}
                          disabled={isInputDisabled}
                          suffix={
                            currency ? (
                              <CurrencyInputSuffix currency={currency} />
                            ) : (
                              'ETH'
                            )
                          }
                        />
                      </Field.Root>
                    </Box>
                    <MarketWidgetPlaceBidCta
                      isCurrentUserWinning={
                        liveAuction.highestBidder === currentUserPublicKey
                      }
                    />
                  </Checkout.Stack>
                  {isFinal5Mins(countdown.secondsRemaining) && (
                    <motion.div layout="position">
                      <Checkout.CtaSmallprintText>
                        Any new bids will reset the end time to 5 minutes
                        remaining
                      </Checkout.CtaSmallprintText>
                    </motion.div>
                  )}
                </Checkout.Stack>
              </Checkout.Stack>
            );
          })
          .with('ENDED', () => {
            const winningBid = liveAuction.bids[0];

            const getWinningBid = () => {
              if (!winningBid) return null;

              return (
                <Checkout.Stack css={{ gap: '$2', alignItems: 'center' }}>
                  <ProfileHoverCard publicKey={winningBid.bidder.publicKey}>
                    <NextLink
                      href={getPath.profile.page(
                        winningBid.bidder.username ||
                          winningBid.bidder.publicKey
                      )}
                      passHref
                    >
                      <a>
                        <UserAvatar
                          imageUrl={winningBid.bidder.profileImageUrl}
                          publicKey={winningBid.bidder.publicKey}
                          size={5}
                        />
                      </a>
                    </NextLink>
                  </ProfileHoverCard>
                  <AuctionWonText>
                    {MARKET_WIDGET_CTA.AUCTION_WON_BY}
                    <span>&nbsp;</span>
                    <UserTag
                      nameVariant="prefer-display-name"
                      size={1}
                      type="text"
                      user={winningBid.bidder}
                      weight="medium"
                      color="strong"
                    />
                  </AuctionWonText>
                  <Text size={5} weight="medium">
                    {formatETHWithoutSuffix(winningBid.amount)}
                    <span>&nbsp;</span>
                    <Text size={3} css={{ display: 'inline' }}>
                      ETH
                    </Text>
                  </Text>
                </Checkout.Stack>
              );
            };

            return (
              <Checkout.Stack css={{ gap: '$5' }}>
                {getWinningBid()}

                <Button
                  onClick={() => {
                    onSettle({ auctionId: liveAuction.auctionId });
                  }}
                  variant="primary"
                >
                  {MARKET_WIDGET_CTA.SETTLE_AUCTION}
                </Button>
              </Checkout.Stack>
            );
          })
          .exhaustive()}
        <MarketWidgetLiveAuctionBidsTable
          auctionStatus={liveAuction.status}
          currentUserPublicKey={currentUserPublicKey}
          isCurrentUserWinning={
            liveAuction.highestBidder === currentUserPublicKey
          }
          liveAuction={liveAuction}
        />
      </LayoutGroup>
    </Checkout.Root>
  );
}

type FallbackLiveAuctionProps = {
  auctionId: number;
  endsAtUnix: number;
  currentBidAmount: number;
  minimumBidAmount: number;
  highestBidder: Address | null;
};

type FallbackMarketWidgetProps = {
  chainId: ChainId;
  currentUserPublicKey: Address | null;
} & (
  | ({
      auctionStatus: 'OPEN' | NftLiveAuctionStatus;
    } & FallbackLiveAuctionProps)
  | { auctionStatus: 'FINALIZED' }
);

export function FallbackMarketWidget(props: FallbackMarketWidgetProps) {
  const { onMakeOffer, currency } = useMarketWidget();

  const placedBidsArgs = match(props)
    .with({ auctionStatus: 'FINALIZED' }, () => ({
      chainId: props.chainId,
      auctionId: BigInt(0),
      currentBidAmount: 0,
      minimumBidAmount: 0,
    }))
    .otherwise((props) => ({
      chainId: props.chainId,
      auctionId: BigInt(props.auctionId),
      currentBidAmount: props.currentBidAmount,
      minimumBidAmount: props.minimumBidAmount,
    }));

  const auctionPlacedBidsQuery = useAuctionPlacedBids(placedBidsArgs, {
    enabled: isNonZero(placedBidsArgs.auctionId),
  });

  if (props.auctionStatus === 'FINALIZED') {
    return (
      <Checkout.Root>
        <Button onClick={onMakeOffer} variant="subtle">
          {MARKET_WIDGET_CTA.OFFER}
        </Button>
      </Checkout.Root>
    );
  }

  if (props.auctionStatus === 'OPEN') {
    return (
      <Checkout.Root>
        <Checkout.Stack css={{ gap: '$3' }}>
          <MarketWidgetSaleStats.ReservePrice
            amount={props.minimumBidAmount}
            currency={currency}
          />
          <Checkout.Stack css={{ gap: '$2' }}>
            <MarketWidgetPlaceBidCta
              isCurrentUserWinning={
                props.highestBidder === props.currentUserPublicKey
              }
            />
            <Button onClick={onMakeOffer} variant="subtle">
              {MARKET_WIDGET_CTA.OFFER}
            </Button>
          </Checkout.Stack>
        </Checkout.Stack>
      </Checkout.Root>
    );
  }

  const getBids = (): ApiBidFragment[] => {
    if (auctionPlacedBidsQuery.isSuccess) {
      return auctionPlacedBidsQuery.data;
    } else {
      return [];
    }
  };

  const bids = getBids();

  const getFirstBid = (): ApiBidFragment => {
    const firstBid = bids.at(-1);

    if (firstBid) {
      return firstBid;
    } else {
      return {
        amount: 0,
        bidder: {
          publicKey: ZERO_ADDRESS,
          profileImageUrl: null,
          username: null,
          name: null,
        },
      };
    }
  };

  return (
    <MarketWidgetLiveAuction
      currentUserPublicKey={props.currentUserPublicKey}
      liveAuction={{
        ...props,

        auctionId: props.auctionId,
        currentBidAmount: props.currentBidAmount,
        endsAtUnix: props.endsAtUnix,
        status: props.auctionStatus,
        firstBid: getFirstBid(),
        bidCount: bids.length,
        bids,
      }}
    />
  );
}

const CurrencyInputSuffixContainer = styled(Flex, {
  alignItems: 'center',
  gap: 6,
  fontSize: '$2',
});

/** Currency to be displayed at the end of an `Input`. */
function CurrencyInputSuffix(props: { currency: Currency }) {
  return (
    <CurrencyInputSuffixContainer>
      {props.currency.name}
      <CurrencyAvatar currency={props.currency} />
    </CurrencyInputSuffixContainer>
  );
}

const AuctionWonText = styled(Text, {
  display: 'flex',
});

AuctionWonText.defaultProps = {
  size: 1,
  weight: 'regular',
  color: 'dim',
};

type Align = 'center' | 'left';

function LastSoldFor(props: { align: Align; amount: number }) {
  const { align, amount } = props;

  return (
    <Text css={{ textAlign: align }} color="dim">
      {MARKET_WIDGET_LABEL.LAST_SOLD}{' '}
      <Text as="span" weight="medium" color="strong">
        {formatETHWithSuffix(amount)}
      </Text>
    </Text>
  );
}

function OfferCountdown(props: { align: Align; offer: ActiveOffer }) {
  const { align, offer } = props;

  return (
    <Box
      css={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: align === 'center' ? 'center' : undefined,
      }}
    >
      <Box css={{ marginRight: 6 }}>
        <OfferProgressCircle
          expiresAt={offer.expiryDate}
          size={14}
          strokeWidth={2}
        />
      </Box>
      <Text color="dim">
        {MARKET_WIDGET_LABEL.ACTIVE_OFFER}{' '}
        <Text as="span" weight="medium" color="strong">
          {formatETHWithSuffix(offer.amount)}
        </Text>
      </Text>
    </Box>
  );
}

function ActiveOfferRow(props: { offer: ActiveOffer; isOwner: boolean }) {
  const { onAcceptOffer } = useMarketWidget();

  return (
    <OfferRow>
      <OfferCountdown align="left" offer={props.offer} />
      {props.isOwner && (
        <Button onClick={onAcceptOffer} variant="primary" size={0}>
          {MARKET_WIDGET_CTA.ACCEPT_OFFER}
        </Button>
      )}
    </OfferRow>
  );
}

const OfferRow = styled('div', {
  display: 'flex',
  justifyContent: 'space-between',
  gap: '$2',
});
