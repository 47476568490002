import { ChevronDownIcon } from '@f8n/icons';
import * as Collapsible from '@radix-ui/react-collapsible';
import { VariantProps } from '@stitches/react';
import React, { useState } from 'react';
import { match, P } from 'ts-pattern';

import {
  CollapsibleTriggerText,
  IconContainer,
} from 'components/CollapsibleTriggerText';
import Box from 'components/base/Box';
import Divider from 'components/base/Divider';
import Flex from 'components/base/Flex';
import Heading from 'components/base/Heading';
import Skeleton from 'components/base/Skeleton';
import Text from 'components/base/Text';
import { hasPublicKey } from 'contexts/auth/helpers';
import useAuth from 'contexts/auth/useAuth';

import {
  FeesSummary,
  GetFeesVariables,
  sumFeeAmountInEth,
  useGetFees,
} from 'hooks/web3/transactions/use-get-fees';
import useMarketBalances from 'hooks/web3/use-market-balances';
import { ENV } from 'utils/env';
import { formatBigIntEthWithSuffix } from 'utils/formatters';
import { basisPointsToPercent, isNonZero } from 'utils/numbers';
import { getBalanceBreakdown } from 'utils/transactions';
import { formatEther } from 'utils/units';

import Fees, { feeLabelMap } from './FeesTable';

interface AmountBreakdownProps {
  formattedFees: FeesSummary;
}

function CollapsibleBreakdown(props: {
  header: React.ReactNode;
  content: React.ReactNode;
}) {
  const [open, setOpen] = useState(false);

  return (
    <Collapsible.Root open={open} onOpenChange={setOpen}>
      <Collapsible.Trigger asChild>
        <CollapsibleTriggerText
          css={{
            display: 'flex',
            alignItems: 'center',
            paddingY: '$2',
            paddingLeft: '$1',
            paddingRight: '$1',
            gap: '$1',
            fontSize: '$1',
          }}
          type="button"
          variant="base"
        >
          {props.header}
          <IconContainer isOpen={open}>
            <ChevronDownIcon />
          </IconContainer>
        </CollapsibleTriggerText>
      </Collapsible.Trigger>
      <Collapsible.Content>
        {props.content && (
          <Box
            css={{
              backgroundColor: '$black5',
              borderRadius: '$2',
              padding: '$5',
              marginTop: '$2',
            }}
          >
            {props.content}
          </Box>
        )}
      </Collapsible.Content>
    </Collapsible.Root>
  );
}

function AmountBreakdownBase(props: AmountBreakdownProps) {
  const { formattedFees } = props;

  const totalAmountValue = sumFeeAmountInEth(formattedFees);

  const getHeader = () => {
    if (formattedFees.currentUserFee) {
      return (
        <Fees.LineItem
          label="You’ll receive"
          value={formatBigIntEthWithSuffix(
            formattedFees.currentUserFee.amountInEth
          )}
        />
      );
    } else {
      return (
        <Fees.LineItem
          label="Total"
          value={formatBigIntEthWithSuffix(totalAmountValue)}
        />
      );
    }
  };

  const getFees = () => {
    return formattedFees.fees.map((fee) => {
      return (
        <>
          <Fees.LineItemWithReceiver
            label={feeLabelMap[fee.type]}
            percentage={basisPointsToPercent(fee.percentInBasisPoints)}
            amountInEth={formatEther(fee.amountInEth)}
            publicKey={fee.address}
          />
          <Divider
            css={{
              backgroundColor: '$black5',
              marginY: '$4',
            }}
          />
        </>
      );
    });
  };

  return (
    <CollapsibleBreakdown
      header={getHeader()}
      content={
        <>
          {getFees()}

          <Fees.LineItem
            size={1}
            label={feeLabelMap[formattedFees.protocolFee.type]}
            percentage={basisPointsToPercent(
              formattedFees.protocolFee.percentInBasisPoints
            )}
            value={formatBigIntEthWithSuffix(
              formattedFees.protocolFee.amountInEth
            )}
          />
        </>
      }
    />
  );
}

type AmountBreakdownSkeletonProps = {
  label: React.ReactNode;
  size?: VariantProps<typeof Text>['size'];
};

function AmountBreakdownSkeleton(props: AmountBreakdownSkeletonProps) {
  const { size = 2, label } = props;
  return (
    <Flex
      css={{
        justifyContent: 'space-between',
        paddingLeft: '$1',
        paddingY: '$2',
        width: '100%',
      }}
    >
      <Heading size={size} color="dim" weight="regular">
        {label}
      </Heading>
      <Skeleton.Block css={{ width: 80, height: 21 }} />
    </Flex>
  );
}

function AmountBreakDownAvailable() {
  const auth = useAuth();

  const marketBalancesQuery = useMarketBalances({
    chainId: hasPublicKey(auth) ? auth.chainId : ENV.PRIMARY_CHAIN_ID,
  });

  return (
    <>
      {match(marketBalancesQuery)
        .with({ data: P.not(P.nullish) }, ({ data }) => {
          const balanceBreakdown = getBalanceBreakdown({
            marketplaceBalance: data.availableFethBalance,
            ethBalance: data.ethBalance,
          });

          const getContent = () => {
            return (
              <>
                {isNonZero(data.availableFethBalance) && (
                  <>
                    <Fees.LineItem
                      label="Marketplace balance"
                      value={formatBigIntEthWithSuffix(
                        data.availableFethBalance
                      )}
                      size={1}
                    />
                    <Divider
                      css={{
                        backgroundColor: '$black5',
                        marginY: '$4',
                      }}
                    />
                  </>
                )}

                <Fees.LineItem
                  label="Wallet balance"
                  value={formatBigIntEthWithSuffix(data.ethBalance)}
                  size={1}
                />
              </>
            );
          };

          return (
            <CollapsibleBreakdown
              header={
                <Fees.LineItem
                  label="Available balance"
                  value={formatBigIntEthWithSuffix(
                    balanceBreakdown.totalBalance.value
                  )}
                />
              }
              content={getContent()}
            />
          );
        })
        /**
         * Render a skeleton state when the balance has not loaded
         * this avoids showing 0 for the balances which can alarm
         * users into thinking their ETH has dissapeared
         */
        .otherwise(() => (
          <AmountBreakdownSkeleton label="Available balance" />
        ))}
    </>
  );
}

type AmountBreakDownFeesProps = GetFeesVariables;

function AmountBreakDownFeesConnected(props: AmountBreakDownFeesProps) {
  const {
    worldId,
    contractAddress,
    tokenId,
    price,
    takeRateInBasisPoints,
    currentUserPublicKey,
    chainId,
  } = props;

  const getFeesQuery = useGetFees({
    takeRateInBasisPoints,
    worldId,
    contractAddress,
    tokenId,
    price,
    currentUserPublicKey,
    chainId,
  });

  if (!getFeesQuery.data) {
    return <AmountBreakdownSkeleton label="You’ll receive" />;
  }

  return <AmountBreakdownBase formattedFees={getFeesQuery.data} />;
}

function FeesZero() {
  return (
    <AmountBreakdownBase
      formattedFees={{
        fees: [],
        protocolFee: {
          amountInEth: BigInt(0),
          percentInBasisPoints: 0,
          type: 'FOUNDATION',
          address: '0x',
        },
        currentUserFee: {
          amountInEth: BigInt(0),
          percentInBasisPoints: 0,
          type: 'OWNER',
          address: '0x',
        },
      }}
    />
  );
}

const AmountBreakDown = Object.assign(AmountBreakdownBase, {
  Skeleton: AmountBreakdownSkeleton,
  Available: AmountBreakDownAvailable,
  /** @deprecated because it does not work for Highlight */
  FeesConnected: AmountBreakDownFeesConnected,
  FeesZero,
});

export default AmountBreakDown;
