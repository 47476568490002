import React from 'react';

import InputField, { InputFieldProps } from 'components/base/InputField';

const InpuptEth = React.forwardRef<HTMLInputElement, InputFieldProps>(
  function InputEth(props, ref) {
    return (
      <InputField
        {...props}
        mono
        onWheel={(event) => event.currentTarget.blur()}
        placeholder="0.00"
        size={2}
        suffix="ETH"
        type="text"
        ref={ref}
      />
    );
  }
);

export default InpuptEth;
