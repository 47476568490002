import { getFeesV3 } from '@f8n/f8n-contracts/dist/abis/FNDMiddlewareHelpers.generated';
import type { Address } from 'viem';
import { useReadContract } from 'wagmi';
import { z } from 'zod';

import { FNDMiddleware } from 'lib/abis/FNDMiddleware';
import { getContractAddress } from 'lib/addresses';
import { ChainId } from 'lib/chains';
import { ZERO_ADDRESS } from 'lib/constants';
import { getAddress } from 'utils/address';
import { isNonZero } from 'utils/numbers';
import { parseEther } from 'utils/units';

import { castToMutableArray } from 'types/utils';

export type GetFeesVariables = {
  currentUserPublicKey: string | null;
  contractAddress: Address;
  tokenId: number;
  price: number;
  worldId: number | null;
  takeRateInBasisPoints: number | null;
  chainId: ChainId;
};

const priceSchema = z.coerce.number();

export function useGetFees(variables: GetFeesVariables) {
  const parsedPrice = priceSchema.safeParse(variables.price);

  return useReadContract({
    abi: FNDMiddleware,
    functionName: 'getFeesV3',
    address: getContractAddress({
      chainId: variables.chainId,
      contractName: 'middleware',
    }),
    chainId: variables.chainId,
    args: parsedPrice.success
      ? [
          getAddress(variables.contractAddress),
          BigInt(variables.tokenId),
          parseEther(parsedPrice.data.toString()),
          /**
           * ignore the referrer address, as it
           * is logic we are not actively supporting
           */
          ZERO_ADDRESS,
          BigInt(variables.worldId || 0),
          variables.takeRateInBasisPoints || 0,
        ]
      : undefined,
    query: {
      select: (data) => {
        const parsedOutput = getFeesV3.parseOutput(data);

        return mapFeesToRecipients({
          currentUserPublicKey: variables.currentUserPublicKey
            ? getAddress(variables.currentUserPublicKey)
            : null,
          world: parsedOutput.world,
          protocol: parsedOutput.protocol,
          owner: parsedOutput.owner,
          creatorRevenueSplit: castToMutableArray(parsedOutput.creatorRevSplit),
        });
      },
    },
  });
}

type FeeLineItem = {
  percentInBasisPoints: bigint;
  amountInWei: bigint;
  recipient: Address;
};

export type RevenueSplitFee = {
  relativePercentInBasisPoints: bigint;
  absolutePercentInBasisPoints: bigint;
  amountInWei: bigint;
  recipient: Address;
};

export type FeeType =
  | 'FOUNDATION'
  | 'CREATOR'
  | 'OWNER'
  | 'SPLIT_RECIPIENT'
  | 'CURATOR';

type FormattedFee = {
  address: Address;
  percentInBasisPoints: number;
  type: FeeType;
  amountInEth: bigint;
};

export type FeesSummary = {
  fees: FormattedFee[];
  protocolFee: FormattedFee;
  currentUserFee: FormattedFee | null;
};

type MapFeesToRecipientsOptions = {
  currentUserPublicKey: Address | null;
  world: FeeLineItem;
  protocol: FeeLineItem;
  owner: FeeLineItem;
  creatorRevenueSplit: RevenueSplitFee[];
};

export function mapFeesToRecipients(
  options: MapFeesToRecipientsOptions
): FeesSummary {
  const { creatorRevenueSplit, currentUserPublicKey } = options;

  const validFees = [
    mapFeeToRecipientType({
      fee: options.world,
      type: 'CURATOR',
    }),
    mapFeeToRecipientType({
      fee: options.owner,
      type: 'OWNER',
    }),
    ...getCreatorAndSplitFees(creatorRevenueSplit),
  ].filter(isValidFee);

  const currentUserFee = getCurrentUserFee({
    fees: validFees,
    currentUserPublicKey,
  });

  const feesWithoutCurrentUser = validFees.filter(
    (fee) => fee.address !== currentUserPublicKey
  );

  return {
    /**
     * keep the current user fee separate from the rest of the fees
     */
    currentUserFee: currentUserFee || null,
    /**
     * keep the protocol fee separate from the rest of the fees
     */
    protocolFee: mapFeeToRecipientType({
      fee: options.protocol,
      type: 'FOUNDATION',
    }),
    /**
     * the rest of the fees; includes creator, owner, curator and splits
     */
    fees: feesWithoutCurrentUser,
  };
}
/**
 * filter fees that are valid because they are not
 * to the zero address, have a non-zero amountInEth
 * and have a non-zero percentInBasisPoints
 */
const isValidFee = (fee: FormattedFee) => {
  return (
    isNonZero(fee.amountInEth) &&
    fee.address !== ZERO_ADDRESS &&
    fee.percentInBasisPoints > 0
  );
};

/**
 * in the scenario where the current user could be:
 *
 * - both the creator and curator
 * - both the owner and the curator
 * - …other similar combinations
 *
 * we need to make sure we total their values and only count them once
 */
const getCurrentUserFee = (options: {
  fees: FormattedFee[];
  currentUserPublicKey: Address | null;
}) => {
  return options.fees
    .filter((fee) => fee.address === options.currentUserPublicKey)
    .reduce((acc, curr) => ({
      ...acc,
      amountInEth: acc.amountInEth + curr.amountInEth,
      percentInBasisPoints:
        acc.percentInBasisPoints + curr.percentInBasisPoints,
    }));
};

const getCreatorAndSplitFees = (fees: RevenueSplitFee[]) => {
  return fees.map((split, index) => {
    return mapFeeToRecipientType({
      fee: {
        amountInWei: split.amountInWei,
        /**
         * absolute percent is the percent of the overall total, rather
         * than relative to the creator and split recipients total
         */
        percentInBasisPoints: split.absolutePercentInBasisPoints,
        recipient: split.recipient,
      },
      /**
       * the first split is the creator, the rest are split recipients
       */
      type: index === 0 ? 'CREATOR' : 'SPLIT_RECIPIENT',
    });
  });
};

const mapFeeToRecipientType = (options: {
  fee: FeeLineItem;
  type: FeeType;
}): FormattedFee => {
  return {
    address: options.fee.recipient,
    percentInBasisPoints: Number(options.fee.percentInBasisPoints),
    amountInEth: options.fee.amountInWei,
    type: options.type,
  };
};

/**
 * this is test helper that iterates through all of the fees
 * and sums  their percentInBasisPoints values
 */
export const sumFeePercentInBasisPoints = (options: FeesSummary) => {
  const total = options.fees.reduce((acc, curr) => {
    return acc + BigInt(curr.percentInBasisPoints);
  }, BigInt(0));

  if (options.currentUserFee) {
    return (
      total +
      BigInt(options.currentUserFee.percentInBasisPoints) +
      BigInt(options.protocolFee.percentInBasisPoints)
    );
  } else {
    return total + BigInt(options.protocolFee.percentInBasisPoints);
  }
};

export const sumFeeAmountInEth = (options: FeesSummary) => {
  const total = options.fees.reduce((acc, curr) => {
    return acc + curr.amountInEth;
  }, BigInt(0));

  if (options.currentUserFee) {
    return (
      total +
      options.currentUserFee.amountInEth +
      options.protocolFee.amountInEth
    );
  } else {
    return total + options.protocolFee.amountInEth;
  }
};
