import { TrackingCopyMap } from 'state/stores/transactions';

import { TxStatus } from 'types/Transactions';

export const txStatusMessageMap: Record<TxStatus, string> = {
  ERROR: 'Transaction failed',
  INDEXING: 'Syncing on Foundation',
  PENDING: 'Waiting for confirmation',
  REVERTED: 'Transaction reverted',
  SUCCESS: 'Transaction successful',
} as const;

export const TRANSACTION_CTA = {
  INITIAL: 'Save onchain',
  PENDING: 'Confirming onchain',
  CONFIRM: 'Confirm in wallet',
} as const;

export const sellTrackingCopy = {
  list: (): TrackingCopyMap => ({
    title: {
      PENDING: 'Listing NFT…',
      SUCCESS: 'NFT listed',
    },
    description: {
      PENDING: 'The NFT is being listed. You can close this if you like.',
      SUCCESS: 'Your NFT has been listed on our marketplace.',
    },
  }),
  unlist: (): TrackingCopyMap => ({
    title: {
      PENDING: 'Unlisting NFT…',
      SUCCESS: 'NFT unlisted',
    },
    description: {
      PENDING: 'You can close this if you like.',
      SUCCESS: 'You have unlisted your NFT.',
    },
  }),
  updateListing: (): TrackingCopyMap => ({
    title: {
      PENDING: 'Updating listing…',
      SUCCESS: 'Listing updated',
    },
    description: {
      PENDING: 'The NFT is being listed. You can close this if you like.',
      SUCCESS: 'Your listing has been updated on our marketplace.',
    },
  }),
};
