import { useQuery, UseQueryOptions } from '@tanstack/react-query';

import {
  ApiWorldByIdVariables,
  useWorldById as useWorldByIdBaseHook,
} from 'gql/api/queries/world-by-id.generated';

import { FetcherReturnType } from 'types/utils';

export async function getWorldById(variables: ApiWorldByIdVariables) {
  const fetchWorldById = useWorldByIdBaseHook.fetcher(variables);
  const data = await fetchWorldById();

  if (!data.world) {
    throw new Error('World not found', {
      cause: {
        queryVariables: variables,
      },
    });
  }

  return data.world;
}

export type WorldById = FetcherReturnType<typeof getWorldById>;

export default function useWorldById(
  variables: ApiWorldByIdVariables,
  options: Pick<
    UseQueryOptions<WorldById, Error>,
    'initialData' | 'enabled' | 'refetchOnWindowFocus' | 'placeholderData'
  > = {}
) {
  return useQuery({
    ...options,
    queryKey: useWorldById.getKey(variables),
    queryFn: () => getWorldById(variables),
  });
}

useWorldById.getKey = (variables: ApiWorldByIdVariables) => [
  'WorldById',
  variables,
];
