import { styled } from '@f8n-frontend/stitches';
import { VariantProps } from '@stitches/react';
import { useState, Dispatch, SetStateAction } from 'react';
import { useMeasure } from 'react-use';

import UserTag from 'components/users/UserTag';

import { UserLight } from 'types/Account';

import Box from './Box';
import Link from './Link';
import Markdown from './Markdown';
import MarkdownText from './MarkdownText';
import Modal from './Modal';
import Text from './Text';

const DEFAULT_LINE_CLAMP = 3;

interface MarkdownWithReadMoreModalProps {
  children: string;
  align: 'left' | 'center';
  title: string | null;
  user?: UserLight;
  lineClamp?: number;
  size?: VariantProps<typeof Root>['size'];
}

export default function MarkdownWithReadMoreModal(
  props: MarkdownWithReadMoreModalProps
) {
  const {
    children,
    title,
    user,
    lineClamp = DEFAULT_LINE_CLAMP,
    align,
    size = 'regular',
  } = props;
  const [isModalOpen, setModalOpen] = useState(false);

  const [lineMeasureRef, lineDimensions] = useMeasure<HTMLDivElement>();
  const [markdownRef, markdownDimensions] = useMeasure<HTMLDivElement>();

  const maxVisibleHeight = lineDimensions.height * lineClamp;
  const hasReadMore = markdownDimensions.height > maxVisibleHeight;

  return (
    <Root size={size}>
      {hasReadMore && (
        <MoreTextModal
          isModalOpen={isModalOpen}
          setModalOpen={setModalOpen}
          text={children}
          title={title}
          user={user}
        />
      )}

      <div
        style={{
          maxHeight: maxVisibleHeight,
          textAlign: align,
          overflow: 'hidden',
        }}
      >
        <StyledMarkdown ref={markdownRef}>
          <Markdown>{children}</Markdown>
          {/* this element is designed to give us the character height
           * which is then multiplied by the desired lineClamp to get
           * the maxHeight for the parent section
           */}
          <LineHeightMeasure ref={lineMeasureRef}>-</LineHeightMeasure>
        </StyledMarkdown>
      </div>
      {hasReadMore && (
        <LinkWrapper style={{ justifyContent: align }}>
          <Link
            variant="strong"
            weight="semibold"
            size={2}
            onClick={() => {
              setModalOpen((isOpen) => !isOpen);
            }}
            css={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            Read more
          </Link>
        </LinkWrapper>
      )}
    </Root>
  );
}

const LineHeightMeasure = styled('div', {
  textIndent: '-9999rem',
  position: 'absolute',
  visibility: 'hidden',
  top: 0,
  left: 0,
});

const StyledMarkdown = styled(Text, {
  lineHeight: '$3',
  wordBreak: 'break-word',
  position: 'relative',
  color: '$black70',
  a: {
    color: 'inherit',
  },
  '& p': {
    marginBottom: '1.5em',
  },
  '& p:empty': {
    display: 'none',
  },
  '& p:last-of-type': {
    marginBottom: 0,
  },
});

const LinkWrapper = styled('div', {
  display: 'flex',
});

const Root = styled('div', {
  variants: {
    size: {
      small: {
        [`${StyledMarkdown}`]: {
          fontSize: '$0',
        },
        [`${LinkWrapper}`]: {
          marginTop: '$1',
        },
        [`${Link}`]: {
          gap: '$1',
          fontSize: '$0',
        },
      },
      regular: {
        [`${StyledMarkdown}`]: {
          fontSize: '$1',
          '@bp1': {
            fontSize: '$2',
          },
        },
        [`${LinkWrapper}`]: {
          marginTop: '$2',
        },
        [`${Link}`]: {
          fontSize: '$1',
          '@bp1': {
            fontSize: '$2',
          },
          gap: '$2',
        },
      },
      large: {
        [`${StyledMarkdown}`]: {
          fontSize: '$3',
          lineHeight: '$1',
          color: '$black100',
          '@bp1': {
            fontSize: '$5',
          },
        },
        [`${LinkWrapper}`]: {
          marginTop: '$6',
        },
        [`${Link}`]: {
          fontSize: '$1',
          '@bp1': {
            fontSize: '$2',
          },
          gap: '$2',
        },
      },
    },
  },
  defaultVariants: {
    size: 'regular',
  },
});

interface MoreTextModalProps {
  text: string;
  isModalOpen: boolean;
  setModalOpen: Dispatch<SetStateAction<boolean>>;
  title: string | null;
  user?: UserLight;
}

function MoreTextModal(props: MoreTextModalProps) {
  const { text, isModalOpen, title, user, setModalOpen } = props;
  return (
    <Modal.Root open={isModalOpen} onOpenChange={setModalOpen}>
      <Modal.Content>
        {user && (
          <Box css={{ marginBottom: '$4' }}>
            <UserTag
              nameVariant="prefer-username"
              user={user}
              size={1}
              type="avatar-text"
            />
          </Box>
        )}
        {title && (
          <Box css={{ marginBottom: '$4' }}>
            <Modal.BodyTitle align="left" title={title} />
          </Box>
        )}
        <MarkdownText>{text}</MarkdownText>
      </Modal.Content>
    </Modal.Root>
  );
}
